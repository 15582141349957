import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ChartMetricDisplayTypes, ChartYAxisMetricValues, GetChartQuery} from "../../API";
import {FetchedMetrics} from "./ChartController";
import moment from "moment";
import DatapointManager from "../../DatapointManager";

am4core.useTheme(am4themes_animated);

interface IProps {
    config: GetChartQuery['getChart']
    datasets: FetchedMetrics
    periodSeconds: number
}

interface IState {
    datasets: Array<{
        datapointId: string
        ts: number
    }>
}

class LineChart extends React.Component<IProps, IState> {
    private chart: am4charts.XYChart | undefined;
    private chartId: string = "chartdiv" + String(Math.round(Math.random() * 10));

    private series: {
        [id: string]: am4charts.LineSeries
    } = {};

    private seriesLoaded: {
        [id: string]: boolean
    } = {};

    /**
     * Set the initial LineChart object
     * @param props
     */
    constructor(props: IProps){
        super(props);
        // Ensure the chartId is unique
        if(this.props.config) {
            this.chartId = "chartdiv-" + this.props.config.id;
        }
        // Setup State
        this.state = {
            datasets: []
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {

        // When props update, we have been pushed new data
        // We need to process these datapoints, updating any new datapoints, as defined by the ts property
        // Detect change to periodSeconds, clear and resetup fetch workers
        if(prevProps.periodSeconds !== this.props.periodSeconds){
            this.showIndicator();
            for(let s in this.seriesLoaded){
                this.seriesLoaded[s] = false;
            }
        }

        if(this.props !== prevProps && prevProps.periodSeconds === this.props.periodSeconds){

            // Check if any datapoints have been updated, and reformat the dataset as needed
            let binaryCounter = 0;
            for(let id in this.props.datasets){

                if(this.props.datasets.hasOwnProperty(id)){

                    let metric = this.props.datasets[id];
                    let datapoints = metric.datapoints;
                    let itemsToRemove = 0;

                    // Filter the response to only new elements since the last update if
                    // the data has already been loaded
                    if(this.series.hasOwnProperty(id) && this.series[id].data && this.series[id].data.length > 0 && this.seriesLoaded[id] === true) {
                        let lastMetricItemDate = this.series[id].data[this.series[id].data.length - 1].date;
                        // Remove all ours datapoints before this date item
                        datapoints = datapoints.filter((dp) => {
                            return moment.unix(dp.unixTimestamp).isAfter(lastMetricItemDate);
                        });
                        itemsToRemove = datapoints.length;
                    }

                    // See if this is a binary operation
                    let isBinary: boolean = false;
                    if(this.props.config && this.props.config.metrics) {
                        for (let metric of this.props.config.metrics) {
                            if(`${metric.datapointId}_${metric.period}_${metric.statistic}` === id){
                                if(metric.displayType && metric.displayType === ChartMetricDisplayTypes.binary){
                                    binaryCounter++;
                                    isBinary = true;
                                    break;
                                }
                            }
                        }
                    }

                    // Format into AMCharts object
                    let data = [];
                    for (let d of datapoints) {
                        // Manipulate binary data if required
                        let value = d.statisticValue;
                        if(isBinary){
                            if(value === 1) value = value - (0.05 * (binaryCounter - 1));
                        }
                        // Push data into map
                        data.push({date: moment.unix(d.unixTimestamp).toDate(), value: value});
                    }

                    // See if we need to clear AMCharts data (period refresh)
                    if(this.seriesLoaded[id] === false){
                        // Do a full graph series data refresh
                        this.series[id].data = data;
                    }else {
                        // Add the data dynamically to the graph
                        if (data.length > 0) {
                            this.series[id].addData(data, itemsToRemove);
                        }
                    }

                    this.seriesLoaded[id] = true;

                    setTimeout(() => {
                        for(let s in this.seriesLoaded){
                            if(this.seriesLoaded[s] === false) return;
                        }
                        this.hideIndicator();
                    }, 100)
                }
            }
        }
    }

    async componentDidMount() {
        let chart = am4core.create(this.chartId, am4charts.XYChart);
        chart.cursor = new am4charts.XYCursor();
        chart.legend = new am4charts.Legend();
        chart.paddingRight = 20;

        // X axis for the date (showing timeseries data)
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 40; // Label min
        dateAxis.periodChangeDateFormats.setKey("hour", "[bold] ddt []");

        // Config for the Chart
        let config: GetChartQuery['getChart'] = this.props.config;
        // Go through the metrics and check if a rightAxis has been requested, if so, make it
        let requiredRightYAxis = false;
        if(config && config.metrics) {
            for (let metric of config.metrics) {
                if(metric.yAxis && metric.yAxis === ChartYAxisMetricValues.right){
                    requiredRightYAxis = true;
                    break;
                }
            }
        }

        // By default setup a yAxis left
        let yAxisRight = null;
        let yAxisLeft = null;

        // Setup the yAxis
        yAxisLeft = chart.yAxes.push(new am4charts.ValueAxis());
        yAxisLeft.renderer.opposite = false;
        if(config && config.yAxis && config.yAxis.left){
            // Set if available
            yAxisLeft.title.text = config.yAxis.left.label || '';
            if(config.yAxis.left.binary && config.yAxis.left.binary.enabled){
                yAxisLeft.min = 0;
                yAxisLeft.max = 1;
                yAxisLeft.renderer.minGridDistance = 10000; // Show only ON and OFF
                yAxisLeft.strictMinMax = true;

                yAxisLeft.adapter.add("getTooltipText", function(text, target, key) {
                    if(text === '0') return "OFF";
                    return "ON";
                });

                yAxisLeft.renderer.labels.template.adapter.add("text", (text, label) =>{
                    if(text === '0') return "OFF";
                    return "ON";
                })

            }else{
                if(config.yAxis.left.min) yAxisLeft.min = config.yAxis.left.min;
                if(config.yAxis.left.max) yAxisLeft.max = config.yAxis.left.max;
            }
        }

        // Setup a yAxis right if defined
        if(requiredRightYAxis) {
            yAxisRight = chart.yAxes.push(new am4charts.ValueAxis());
            yAxisRight.renderer.opposite = true;
            yAxisRight.strokeWidth = 0;
            if (config && config.yAxis && config.yAxis.right) {
                // Set if available
                yAxisRight.title.text = config.yAxis.right.label || '';
                if(config.yAxis.right.binary && config.yAxis.right.binary.enabled){
                    yAxisRight.min = 0; // TODO: Remove this need to duplicate
                    yAxisRight.max = 1;
                    yAxisRight.renderer.minGridDistance = 10000; // Show only ON and OFF
                    yAxisRight.strictMinMax = true;

                    yAxisRight.adapter.add("getTooltipText", function(text, target, key) {
                        if(text === '0') return "OFF";
                        return "ON";
                    });

                    yAxisRight.renderer.labels.template.adapter.add("text", (text, label) =>{
                        if(text === '0') return "OFF";
                        return "ON";
                    })
                }else {
                    if (config.yAxis.right.min) yAxisRight.min = config.yAxis.right.min;
                    if (config.yAxis.right.max) yAxisRight.max = config.yAxis.right.max;
                }
            }
        }


        // Setup a series per metric information
        if(config && config.metrics) {
            for (let metric of config.metrics) {

                // Support stepped line configuration
                let series: am4charts.StepLineSeries | am4charts.LineSeries;
                if(metric.displayType && [ChartMetricDisplayTypes.binary, ChartMetricDisplayTypes.step].includes(metric.displayType)){
                    series = chart.series.push(new am4charts.StepLineSeries());
                }else{
                    series = chart.series.push(new am4charts.LineSeries());
                }

                // Define the new series
                series.dataFields.valueY = "value";
                series.dataFields.dateX = "date";
                series.connect = false;
                series.autoGapCount = 2; // TODO: Check baseInterval issue, does this effect gaps?

                // Compute the label to display
                if(metric.label) {
                    series.tooltipText = `${metric.label}: {valueY.value}`;
                    series.name = metric.label;
                }else{
                    let dp = await DatapointManager.getDatapointById(metric.datapointId);
                    if(dp){
                        series.tooltipText = `${dp.name}: {valueY.value}`;
                        series.name = dp.name;
                    }else{
                        series.tooltipText = "{valueY.value}";
                        series.name = "Unknown";
                    }
                }

                // We may override the above if this is a binary metric
                if(metric.displayType === ChartMetricDisplayTypes.binary) {
                    if(series.tooltip) {
                        series.tooltip.label.adapter.add("textOutput", (text, target) => {
                            if (target.tooltipDataItem.values.valueY.value === 0) return `${series.name}: OFF`;
                            return `${series.name}: ON`;
                        });
                    }
                }

                // Compute the color
                if(metric.color) {
                    series.stroke = am4core.color(metric.color);
                }


                // yAxis position
                if(metric.yAxis !== null && metric.yAxis === ChartYAxisMetricValues.right && yAxisRight !== null){
                    series.yAxis = yAxisRight;
                }else{
                    series.yAxis = yAxisLeft;
                }

                // Add the series for data adding
                let id: string = `${metric.datapointId}_${metric.period}_${metric.statistic}`;
                this.series[id] = series;
                this.seriesLoaded[id] = false;
            }
        }

        this.chart = chart;


        // Chart loading
        this.showIndicator();
    }

    private indicator: am4core.Container | null = null;
    private indicatorInterval: any = undefined;

     showIndicator() {

        let hourglass: am4core.Image;

        if (this.indicator === null && this.chart && this.chart.tooltipContainer) {
            this.indicator = this.chart.tooltipContainer.createChild(am4core.Container);
            this.indicator.background.fill = am4core.color("#fff");
            this.indicator.background.fillOpacity = 0.8;
            this.indicator.width = am4core.percent(100);
            this.indicator.height = am4core.percent(100);

            let indicatorLabel = this.indicator.createChild(am4core.Label);
            indicatorLabel.text = "Loading your data...";
            indicatorLabel.align = "center";
            indicatorLabel.valign = "middle";
            indicatorLabel.fontSize = 20;
            indicatorLabel.dy = 50;

            hourglass = this.indicator.createChild(am4core.Image);
            hourglass.href = "/images/hourglass.svg";
            hourglass.align = "center";
            hourglass.valign = "middle";
            hourglass.horizontalCenter = "middle";
            hourglass.verticalCenter = "middle";
            hourglass.scale = 0.7;
        }

        if(this.indicator !== null) {
            this.indicator.hide(0);
            this.indicator.show();
        }

        clearInterval(this.indicatorInterval);
        this.indicatorInterval = setInterval(() => {
            if(hourglass) {
                hourglass.animate([{
                    from: 0,
                    to: 360,
                    property: "rotation"
                }], 1000);
            }
        }, 3000);
    }

    hideIndicator() {   // TODO: We need to known when to show the graph to the user
        if(this.indicator){
            this.indicator.hide();
            clearInterval(this.indicatorInterval);
        }
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        if(!this.props.config) return;
        return (
            <Container style={{marginBottom: "30px"}}>
                <Typography variant="h5" component="h5">{this.props.config.title}</Typography>
                <div id={this.chartId} style={{ width: "100%", height: "500px" }} />
            </Container>
        );
    }

}

export default LineChart;
