import React from "react";
import {Fab, IconButton, Menu, MenuItem, Tooltip, withStyles} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CloneDeep from 'clone-deep';


import FacetProperty from "./FacetProperty"

const styles = theme => ({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
    formFlex: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
    },
    formFlexContainer: {
        display: "flex",
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    deleteFacetButton: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
});


class DatapointFacetManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addMenuOpen: false
        }
        this.addMenuAnchor = React.createRef();
    }

    onFacetDelete(key) {
        this.props.onFacetDelete(key);
    }

    createFacet(facetKey, facetValue, facetInformation, currentFacetValue, classes) {
        let label = facetKey[0].toUpperCase() + facetKey.substr(1);
        return (
            <div className={classes.formFlexContainer}>
                <div className={classes.formFlex}>
                    {facetInformation[facetKey] &&
                    <FacetProperty key={facetKey} label={label}
                                   type={facetInformation[facetKey].type}
                                   values={facetInformation[facetKey].values}
                                   value={currentFacetValue}
                                   inputAttr={facetInformation[facetKey].inputAttr}
                                   onChange={(value) => this.onFacetChange(facetKey, value)}
                                   edit={this.props.edit}
                    />}
                </div>
                <div className={classes.formFlex}>
                    {this.props.edit &&
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete" className={classes.deleteFacetButton}
                                    onClick={() => this.onFacetDelete(facetKey)}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                    }
                </div>

            </div>)
    }

    render() {
        const classes = this.props.classes;
        let f = CloneDeep(this.props.facets);

        let facetInformation = {
            "units": {
                type: 'select',
                values: ['°C', '°F', 's', 'h', 'Hz', 'm', 'PSI', 'Bar', 'MPH', 'Km/h',
                    'A', 'V', 'W', 'kW', 'kWh', 'Wh', 'kVA', 'kVAh', 'kVAr', 'kVArh'],
                help: "Unit to display with the value."},
            "precision": {
                type: 'numeric',
                inputAttr: {min: 0, max: 10, step: 1},
                help: "Number of digits displayed overall."
            },
            "scale": {
                type: 'numeric',
                inputAttr: {min: 0, max: 10, step: 1},
                help: "Number of digits after the decimal place."
            },
            "trueText": {type: 'text', help: "Default text to display when a Boolean is TRUE."},
            "falseText": {type: 'text', help: "Default text to display when a Boolean is TRUE."},
            "min": {type: 'numeric', inputAttr: {step: 1}, help: "Minimum value for the setpoint."},
            "max": {type: 'numeric', inputAttr: {step: 1}, help: "Maximum value for the setpoint."},
            "step": {
                type: 'numeric',
                inputAttr: {step: 0.1},
                help: "Adjustment increment of which the setpoint can be changed by."
            },
            "enumMapping": {type: 'enum', help: "Enum Configuration for a ENUM datapoint."},
        };

        // Create facets based on value
        let composedF = Object.keys(f).map((k) => {
            let currentFacetValue = f[k] || null;
            return this.createFacet(k, f[k], facetInformation, currentFacetValue, classes)
        });

        // Populate list for adding new facets
        let unselectedFacetNames = Object.keys(facetInformation).filter((v) => {
            return !f.hasOwnProperty(v);
        });

        // Render
        return (
            <div className={classes.root}>
                {composedF}

                {this.props.edit && unselectedFacetNames.length > 0 &&
                <>
                    <br/><br/><br/><br/>
                    <Tooltip title="Add Facet" aria-label="add facet">
                        <Fab color="secondary" className={classes.absolute} size="small" ref={this.addMenuAnchor}
                             onClick={() => this.addMenuToggle(true)}>
                            <AddIcon/>
                        </Fab>
                    </Tooltip>
                    <Menu
                        anchorEl={this.addMenuAnchor.current}
                        keepMounted
                        open={this.state.addMenuOpen}
                        onClose={() => this.addMenuToggle(false)}
                    >
                        {unselectedFacetNames.map((v) => <MenuItem
                            onClick={() => this.addNewFacet(v)}>{v[0].toUpperCase() + v.substr(1)}</MenuItem>)}
                    </Menu>
                </>
                }
            </div>
        )
    }

    addNewFacet(value) {
        this.addMenuToggle(false);
        this.props.onFacetChange(value, null);
    }

    onFacetChange(key, value) {
        this.props.onFacetChange(key, value);
    }

    addMenuToggle(state) {
        this.setState({...this.state, addMenuOpen: state})
    }

}

export default withStyles(styles)(DatapointFacetManager);
