// Libraries
import AWSConfig from '@/aws-exports.js';
import AWS from 'aws-sdk';
import {Auth} from 'aws-amplify';

// Internal Variables
let bucket = AWSConfig['aws_user_files_s3_bucket'] || '';
let defaultRegion = AWSConfig['aws_user_files_s3_bucket_region'] || '';


async function getS3Client(optRegion){
    let region = optRegion || defaultRegion;
    let config = {credentials: await Auth.currentCredentials(), region: region};
    return new AWS.S3(config);
}

/**
 * Copy an object to the configured s3 bucket.
 * The assumed role must have permissions to do this.
 */
export async function copyObject(sourceKey, destinationKey) {

    // Prepare the configuration
    let s3 = await getS3Client();
    let params = {
        Bucket: bucket,
        Key: destinationKey,
        CopySource: "/" + bucket + "/" + sourceKey
    };

    // Attempt Copy
    try{
        await s3.copyObject(params).promise();
        return {
            bucket,
            region: defaultRegion,
            key: destinationKey
        }
    }catch(error){
        throw new Error(error);
    }
}

/**
 * Upload an object to the configured s3 bucket.
 * The assumed role must have permissions to do this.
 */
export async function putObject(key, body) {

    // Prepare the configuration
    let s3 = await getS3Client();
    let params = {Bucket: bucket, Key: key, Body: body};

    // Attempt Upload
    try{
        await s3.upload(params).promise();
        return {
            bucket,
            region: defaultRegion,
            key: key
        }
    }catch(error){
        throw new Error(error);
    }

};

/**
 * Gets the contents of the requested object.
 * @param s3ObjectProperties {key, bucket, defaultRegion}
 * @returns {Promise<void>}
 */
export async function getObjectContents(s3ObjectProperties){
    // Prepare the configuration
    const c = s3ObjectProperties;
    let s3 = await getS3Client(c.region);
    let params = {
        Bucket: c.bucket,
        Key: c.key,
        ResponseCacheControl: 'no-store'
    };

    // Get the object and return it to the requester
    try{
        let o = await s3.getObject(params).promise()
        return o.Body.toString('utf-8');
    }catch(e){
        throw new Error(e);
    }
}

/**
 * Get a signed URL for later use
 * @param key
 * @param expiry
 * @returns {Promise<void>}
 */
export async function getGetObjectSignedUrl(key, expiry){
    let s3 = await getS3Client();
    let params = {Bucket: bucket, Key: key, Expires: expiry};

    try{
        let o = await s3.getSignedUrl('getObject', params);
        return o;
    }catch(e){
        throw new Error(e);
    }
}
