(function () {

    var serialize = function (obj) {
        let replacer = (key, value) => {
            // if we get a function give us the code for that function
            if (typeof value === 'function') {
                return value.toString();
            }
            return value;
        };

        // get a stringified version of our object
        // and indent the keys at 2 spaces
        return JSON.stringify(obj, replacer);
    };

    var unserialize = function (obj) {
        let reviver = (key, value) => {
            if (typeof value === 'string' && value.indexOf('function ') === 0) {
                let functionTemplate = `(${value})`;
                return eval(functionTemplate);
            }
            return value;
        };

        return JSON.parse(obj, reviver);
    };


    var objectToExports = {
        serialize: serialize,
        unserialize: unserialize
    };

    // Node.js
    if (typeof module === 'object' && module.exports) {
        module.exports = objectToExports;
    }
    // included directly via <script> tag
    else {
        window.serialize = objectToExports;
    }
}());