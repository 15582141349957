// tslint:disable
// this is an auto generated file. This will be overwritten

export const createView = `mutation CreateView($input: CreateViewInput!) {
  createView(input: $input) {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    configuration {
      bucket
      key
      region
    }
  }
}
`;
export const updateView = `mutation UpdateView($input: UpdateViewInput!) {
  updateView(input: $input) {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    configuration {
      bucket
      key
      region
    }
  }
}
`;
export const deleteView = `mutation DeleteView($input: DeleteViewInput!) {
  deleteView(input: $input) {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    configuration {
      bucket
      key
      region
    }
  }
}
`;
export const createTenant = `mutation CreateTenant($input: CreateTenantInput!) {
  createTenant(input: $input) {
    id
    name
    slug
    legacySiteId
    customPwaIcons
  }
}
`;
export const updateTenant = `mutation UpdateTenant($input: UpdateTenantInput!) {
  updateTenant(input: $input) {
    id
    name
    slug
    legacySiteId
    customPwaIcons
  }
}
`;
export const deleteTenant = `mutation DeleteTenant($input: DeleteTenantInput!) {
  deleteTenant(input: $input) {
    id
    name
    slug
    legacySiteId
    customPwaIcons
  }
}
`;
export const createDatapoint = `mutation CreateDatapoint($input: CreateDatapointInput!) {
  createDatapoint(input: $input) {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    description
    type
    legacyRegisterId
    isSetpoint
    isReadpoint
    readValue
    setValue
    setMode
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    readTimestamp
    facets {
      units
      divisor
      precision
      scale
      enumMapping {
        value
        display
      }
      trueText
      falseText
      min
      max
      step
    }
  }
}
`;
export const updateDatapoint = `mutation UpdateDatapoint($input: UpdateDatapointInput!) {
  updateDatapoint(input: $input) {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    description
    type
    legacyRegisterId
    isSetpoint
    isReadpoint
    readValue
    setValue
    setMode
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    readTimestamp
    facets {
      units
      divisor
      precision
      scale
      enumMapping {
        value
        display
      }
      trueText
      falseText
      min
      max
      step
    }
  }
}
`;
export const deleteDatapoint = `mutation DeleteDatapoint($input: DeleteDatapointInput!) {
  deleteDatapoint(input: $input) {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    description
    type
    legacyRegisterId
    isSetpoint
    isReadpoint
    readValue
    setValue
    setMode
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    readTimestamp
    facets {
      units
      divisor
      precision
      scale
      enumMapping {
        value
        display
      }
      trueText
      falseText
      min
      max
      step
    }
  }
}
`;
export const createSchedule = `mutation CreateSchedule($input: CreateScheduleInput!) {
  createSchedule(input: $input) {
    tenantId
    id
    name
    description
    datapoints {
      items {
        tenantId
        id
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
        name
        description
        type
        legacyRegisterId
        isSetpoint
        isReadpoint
        readValue
        setValue
        setMode
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        readTimestamp
        facets {
          units
          divisor
          precision
          scale
          enumMapping {
            value
            display
          }
          trueText
          falseText
          min
          max
          step
        }
      }
      nextToken
    }
    profiles {
      items {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      nextToken
    }
    activeProfile {
      tenantId
      id
      name
      type
      schedule {
        tenantId
        id
        name
        description
        datapoints {
          items {
            tenantId
            id
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
            name
            description
            type
            legacyRegisterId
            isSetpoint
            isReadpoint
            readValue
            setValue
            setMode
            schedule {
              tenantId
              id
              name
              description
            }
            readTimestamp
            facets {
              units
              divisor
              precision
              scale
              trueText
              falseText
              min
              max
              step
            }
          }
          nextToken
        }
        profiles {
          items {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          nextToken
        }
        activeProfile {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      timeslots {
        key
        entries {
          datapointId
          value
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const updateSchedule = `mutation UpdateSchedule($input: UpdateScheduleInput!) {
  updateSchedule(input: $input) {
    tenantId
    id
    name
    description
    datapoints {
      items {
        tenantId
        id
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
        name
        description
        type
        legacyRegisterId
        isSetpoint
        isReadpoint
        readValue
        setValue
        setMode
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        readTimestamp
        facets {
          units
          divisor
          precision
          scale
          enumMapping {
            value
            display
          }
          trueText
          falseText
          min
          max
          step
        }
      }
      nextToken
    }
    profiles {
      items {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      nextToken
    }
    activeProfile {
      tenantId
      id
      name
      type
      schedule {
        tenantId
        id
        name
        description
        datapoints {
          items {
            tenantId
            id
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
            name
            description
            type
            legacyRegisterId
            isSetpoint
            isReadpoint
            readValue
            setValue
            setMode
            schedule {
              tenantId
              id
              name
              description
            }
            readTimestamp
            facets {
              units
              divisor
              precision
              scale
              trueText
              falseText
              min
              max
              step
            }
          }
          nextToken
        }
        profiles {
          items {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          nextToken
        }
        activeProfile {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      timeslots {
        key
        entries {
          datapointId
          value
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const deleteSchedule = `mutation DeleteSchedule($input: DeleteScheduleInput!) {
  deleteSchedule(input: $input) {
    tenantId
    id
    name
    description
    datapoints {
      items {
        tenantId
        id
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
        name
        description
        type
        legacyRegisterId
        isSetpoint
        isReadpoint
        readValue
        setValue
        setMode
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        readTimestamp
        facets {
          units
          divisor
          precision
          scale
          enumMapping {
            value
            display
          }
          trueText
          falseText
          min
          max
          step
        }
      }
      nextToken
    }
    profiles {
      items {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      nextToken
    }
    activeProfile {
      tenantId
      id
      name
      type
      schedule {
        tenantId
        id
        name
        description
        datapoints {
          items {
            tenantId
            id
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
            name
            description
            type
            legacyRegisterId
            isSetpoint
            isReadpoint
            readValue
            setValue
            setMode
            schedule {
              tenantId
              id
              name
              description
            }
            readTimestamp
            facets {
              units
              divisor
              precision
              scale
              trueText
              falseText
              min
              max
              step
            }
          }
          nextToken
        }
        profiles {
          items {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          nextToken
        }
        activeProfile {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      timeslots {
        key
        entries {
          datapointId
          value
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const createDeployment = `mutation CreateDeployment($input: CreateDeploymentInput!) {
  createDeployment(input: $input) {
    tenantId
    id
    type
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    timeRequested
  }
}
`;
export const updateDeployment = `mutation UpdateDeployment($input: UpdateDeploymentInput!) {
  updateDeployment(input: $input) {
    tenantId
    id
    type
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    timeRequested
  }
}
`;
export const deleteDeployment = `mutation DeleteDeployment($input: DeleteDeploymentInput!) {
  deleteDeployment(input: $input) {
    tenantId
    id
    type
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    timeRequested
  }
}
`;
export const createScheduleProfile = `mutation CreateScheduleProfile($input: CreateScheduleProfileInput!) {
  createScheduleProfile(input: $input) {
    tenantId
    id
    name
    type
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    timeslots {
      key
      entries {
        datapointId
        value
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const updateScheduleProfile = `mutation UpdateScheduleProfile($input: UpdateScheduleProfileInput!) {
  updateScheduleProfile(input: $input) {
    tenantId
    id
    name
    type
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    timeslots {
      key
      entries {
        datapointId
        value
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const deleteScheduleProfile = `mutation DeleteScheduleProfile($input: DeleteScheduleProfileInput!) {
  deleteScheduleProfile(input: $input) {
    tenantId
    id
    name
    type
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    timeslots {
      key
      entries {
        datapointId
        value
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const createChart = `mutation CreateChart($input: CreateChartInput!) {
  createChart(input: $input) {
    tenantId
    id
    title
    view
    metrics {
      datapointId
      statistic
      period
      yAxis
      color
      label
      displayType
    }
    yAxis {
      left {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
      right {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const updateChart = `mutation UpdateChart($input: UpdateChartInput!) {
  updateChart(input: $input) {
    tenantId
    id
    title
    view
    metrics {
      datapointId
      statistic
      period
      yAxis
      color
      label
      displayType
    }
    yAxis {
      left {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
      right {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const deleteChart = `mutation DeleteChart($input: DeleteChartInput!) {
  deleteChart(input: $input) {
    tenantId
    id
    title
    view
    metrics {
      datapointId
      statistic
      period
      yAxis
      color
      label
      displayType
    }
    yAxis {
      left {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
      right {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
