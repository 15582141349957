// Library Imports
import grapesjs from 'grapesjs';
import Amplify, {API, Storage, graphqlOperation} from 'aws-amplify';

// Local Imports
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import TenantManager from '@/TenantManager';
import {putObject, getObjectContents} from '@/AWSHelpers/s3';

// Configuration for this Storage module
const storageName = "appsync";
const idAttrSeperator = "#";

// Create the plugin
export default grapesjs.plugins.add(`grapesjs-${storageName}`, (editor, opts = {}) => {

    // Define
    const sm = editor.StorageManager;
    const onError = err => sm.onError(storageName, err.code || err);

    // Add the new storage manager
    editor.StorageManager.add(storageName, {
        /**
         * Load the data
         * @param  {Array} keys Array containing values to load, eg, ['gjs-components', 'gjs-style', ...]
         * @param  {Function} clb Callback function to call when the load is ended
         * @param  {Function} clbErr Callback function to call in case of errors
         */
        load(keys, clb, clbErr) {

            // Split keys by | to get the UUID for this object
            let key = keys[0].split(idAttrSeperator, 1);
            let viewId = key[0];


            (async function () {
                const getView = await API.graphql(
                    graphqlOperation(queries.getView, {tenantId: TenantManager.getTenantId(), id: viewId})
                );

                // Check the request has valid keys
                if(getView.data.getView.configuration){

                    // Build the response from the requested keys
                    let data = await getObjectContents(getView.data.getView.configuration);
                    let jsonObject = JSON.parse(data);
                    clb(jsonObject);
                    return;
                }else{
                    clb(Object.assign(...keys.map(k => ({ [k]: "" }))))
                    return;
                }

                clbErr("Configuration error");
            })();
        },

        /**
         * Store the data
         * @param  {Object} data Data object to store
         * @param  {Function} clb Callback function to call when the load is ended
         * @param  {Function} clbErr Callback function to call in case of errors
         */
        store(data, clb, clbErr) {

            // Split keys by | to get the UUID for this object
            let key = Object.keys(data)[0]
            let viewId = key.split(idAttrSeperator, 1)[0]

            let viewDetails = {
                tenantId: TenantManager.getTenantId(),
                id: viewId,
            };

            // Encode the BLOB for storage
            const blob = JSON.stringify({
                html: data[viewId + idAttrSeperator + "html"] || ' ',
                assets: data[viewId + idAttrSeperator + "assets"] || ' ',
                css: data[viewId + idAttrSeperator + "css"] || ' ',
                components: data[viewId + idAttrSeperator + "components"] || ' ',
                styles: data[viewId + idAttrSeperator + "styles"] || ' ',
            });

            // Attempt the upload and save
            (async function () {
                try {
                    // Store in S3 // TODO: Have an external handler for this type of stuff
                    let path  = "tenant/" + TenantManager.getTenantId() + "/view/" + viewId + "/configuration.json";
                    let fileDetails = await putObject(path, blob);

                    // Add details to the viewDetails
                    viewDetails['configuration'] = fileDetails;

                    const updatedView = await API.graphql(
                        graphqlOperation(mutations.updateView, {input: viewDetails})
                    );

                    // Success
                    clb();
                    return;
                }catch(e){
                    clbErr(e);
                }
            })();
        }
    });


});
