import React from 'react';

class Profile extends React.Component {

    render() {
        return (<div>Profile Done</div>)
    }

};

export default Profile;