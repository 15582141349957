import React from "react";
import {AppBar, Box, Tab, Tabs, Tooltip, Typography, withStyles} from "@material-ui/core";
import TimeslotEditor from "./TimeslotEditor";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import uuidv4 from "uuid/v4"
import Moment from "moment"
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paddedText: {
        marginTop: "20px",
        padding: "20px",
        textAlign: "center"
    },
});

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

// this.props.updateScheduleTimeslots
// this.props.setpoints
// this.props.timeslots
class WeekEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            days: [
                [
                    {
                        reactId: uuidv4(),
                        updated: false,
                        startTime: this.makeTime("00:00"),
                        endTime: "00:00 (Monday)",
                        startTimeLocked: true,
                        entries: this.composeTimeslotEntries(true)
                    },
                ],
                [],
                [],
                [],
                [],
                [],
                []
            ]
        };
        this.weekMapStrs = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    }

    componentDidMount() {
        // Preload timeslots
        this.loadPropTimeslots();
        this.updateParentSchedule();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Update parent schedule
        this.updateParentSchedule();
    }

    loadPropTimeslots() {
        if (this.props.timeslots.length > 0) {
            let days = [[], [], [], [], [], [], []];

            // Go through each timeslot, break apart key, and add to a day
            // if the setpoint is still present in this.props.setpoint
            // eslint-disable-next-line array-callback-return
            this.props.timeslots.map(timeslot => {

                let key = timeslot.key;
                let entries = timeslot.entries || [];
                let dayIndex = parseInt(key.split("|")[0]) - 1;
                let time = key.split("|")[1];
                let forceSet = (dayIndex === 0 && time === "0000");

                days[dayIndex].push({
                    reactId: uuidv4(),
                    updated: false,
                    startTime: this.makeTime(time[0] + time[1] + ":" + time[2] + time[3]),
                    endTime: "",
                    startTimeLocked: forceSet,
                    entries: []
                });


                // eslint-disable-next-line array-callback-return
                this.props.setpoints.map(d => {
                    let entry = entries.find(e => e.datapointId === d.id);

                    let value = (entry === undefined) ? null : entry.value;
                    if (forceSet && value === null) value = d.getDefaultSetpointValue();

                    let singleForce = (this.props.singleDatapointMode && this.props.singleDatapointId === d.id);

                    days[dayIndex][days[dayIndex].length - 1]['entries'].push(
                        {
                            datapoint: d,
                            value: value,
                            isSet: (entry !== undefined) || forceSet,
                            isForceSet: forceSet || (singleForce && (entry !== undefined))
                        }
                    )
                });
            });

            this.recomputeSchedule(days)
            // this.setState({...this.state, days: days});
        }
    }

    updateParentSchedule() {
        let days = this.state.days;

        let exportTimeslots = [];

        // Go through every timeslot, if it makes a change, add it to the timeslots array
        for (let i = 0; i <= 6; i++) {
            for (let j = 0; j <= days[i].length - 1; j++) {
                // Set vars
                let ts = days[i][j];
                let exTs = {key: i + 1 + "|" + Moment(ts.startTime).format("HHmm"), entries: []};
                // Look at each entry within the timeslot
                // eslint-disable-next-line array-callback-return
                ts.entries.map(e => {
                    if (e.isSet) {
                        exTs.entries.push({datapointId: e.datapoint.id, value: e.value});
                    }
                });

                if (exTs.entries.length > 0) {
                    exportTimeslots.push(exTs);
                }
            }

        }

        this.props.updateScheduleTimeslots(exportTimeslots)
    }

    composeTimeslotEntries(forceSet) {
        let entries = this.props.setpoints.map((d) => {
            return {
                datapoint: d,
                value: d.getDefaultSetpointValue(),
                isSet: forceSet,
                isForceSet: forceSet
            };
        });

        // If we are in singleDatapointMode, forceset it
        if (this.props.singleDatapointMode) {
            let entry = entries.find(e => e.datapoint.id === this.props.singleDatapointId);
            if (entry !== undefined) {
                entry.isSet = true;
                entry.isForceSet = true;
            }
        }

        return entries;
    }

    makeTime(time) {
        return new Date("2018-01-01T" + time + ":00.000Z");
    }

    composeTimeslotEditor(ts, d, i) {

        // TODO: This needs more thought
        // Hide this entire timeslot if the datapoint isn't set within it
        if (this.props.singleDatapointMode && ts.entries.find(e => (e.datapoint.id === this.props.singleDatapointId && e.isSet === true)) === undefined) {
            return;
        }

        return (<TimeslotEditor key={ts.reactId}
                                dayLabel={d}
                                updated={ts.updated}
                                startTime={ts.startTime}
                                endTime={ts.endTime}
                                entries={ts.entries}
                                updateStartTime={(time) => this.updateStartTime(i, ts, time)}
                                startTimeLocked={ts.startTimeLocked}
                                deleteTimeslot={() => this.deleteTimeslot(i, ts)}
                                updateDatapointSet={(value, entry) => this.updateTsDatapointSet(i, ts, entry, value)}
                                updateDatapointValue={(value, entry) => this.updateTsDatapointValue(i, ts, entry, value)}
                                updateUpdated={(value) => this.updateTsUpdated(i, ts, value)}
                                onTimePickerClose={() => this.onTsPickerClose(i, ts)}
                                singleDatapointMode={this.props.singleDatapointMode}
                                singleDatapointId={this.props.singleDatapointId}
        />)
    }

    render() {
        const classes = this.props.classes;
        const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

        let timeslots = days.map((d, i) => (
            this.state.days[i].map(ts => this.composeTimeslotEditor(ts, d, i))
        ));

        return (
            <div>
                <Paper square>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.selectedTab}
                            onChange={this.handleSelectedTabChange.bind(this)}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="week schedule editor"
                        >
                            {days.map((d, i) => (
                                <Tab key={d} label={d}  {...this.a11yProps(i)} />
                            ))}

                        </Tabs>
                    </AppBar>
                    {days.map((d, i) => (
                        <TabPanel value={this.state.selectedTab} index={i} key={i}>
                            <Typography variant="h4">
                                {this.weekMapStrs[i]}
                            </Typography>
                            {/*Add the timeslots for this day*/}
                            {timeslots[i]}
                            {timeslots[i].length === 0 &&
                            <Typography variant="body1" className={classes.paddedText}>
                                There are no scheduled events for today. Add one using the + button below.
                            </Typography>
                            }

                        </TabPanel>
                    ))}
                </Paper>
                <Tooltip title="Add Timeslot">
                    <Fab aria-label="Add" className={classes.fab} color="secondary" onClick={() => this.addTimeslot()}>
                        <AddIcon/>
                    </Fab>
                </Tooltip>
            </div>
        )
    }

    updateTsDatapointSet(dayIndex, timeslot, valueEntry, value) {
        let days = this.state.days;
        let day = days[dayIndex];

        let ts = day.find((t) => t === timeslot);          // Get timeslot
        let entry = ts.entries.find((e) => e === valueEntry) // Get entry

        // Set the value
        entry.isSet = value;
        ts.updated = true;

        this.recomputeSchedule(days)
    }

    updateTsDatapointValue(dayIndex, timeslot, valueEntry, value) {
        let days = this.state.days;
        let day = days[dayIndex];

        let ts = day.find((t) => t === timeslot);          // Get timeslot
        let entry = ts.entries.find((e) => e === valueEntry) // Get entry

        // Set the value
        entry.value = value;
        entry.isSet = true;
        ts.updated = true;

        this.recomputeSchedule(days)
    }

    recomputeSchedule(days) {
        // Order the timeslots for each day
        for (let i = 0; i <= 6; i++) {
            days[i].sort((a, b) => a.startTime - b.startTime);
        }

        // Update the endTime for each timeslot
        let lastStartTime = "00:00 (Monday)";     // We force 00:00 Monday to keep things simple for now
        for (let i = 6; i >= 0; i--) {
            let timeslots = days[i]; // Get all timeslots for the day
            for (let j = timeslots.length - 1; j >= 0; j--) {
                days[i][j].endTime = lastStartTime + "";
                lastStartTime = Moment(days[i][j].startTime).format("HH:mm") + " (" + this.weekMapStrs[i] + ")"
            }
        }

        // Update inherited values for each timeslot mapping
        let lastTimeslot = days[0][0];
        for (let i = 0; i <= 6; i++) {
            let timeslots = days[i];
            for (let j = 0; j <= timeslots.length - 1; j++) {
                if (i === 0 && j === 0) continue; // Skip mandatory timeslot
                let timeslot = timeslots[j];
                // Go through all entries, fetching value from previous timeslot
                // eslint-disable-next-line array-callback-return
                timeslot.entries.map(entry => {
                    if (!entry.isSet) {
                        let e = lastTimeslot.entries.find((e) => e.datapoint === entry.datapoint);
                        entry.value = e.value;
                    }
                });
                // Set my timeslot
                lastTimeslot = timeslot;
            }
        }
        // if !isSet, set the value from the last timeslot entry


        this.setState({...this.state, days: days});
    }


    addTimeslot() {
        let entries = this.composeTimeslotEntries(false);
        let dayIndex = this.state.selectedTab;

        let days = this.state.days;
        let day = days[dayIndex];

        // Get last time, pop and hour on it
        let calculatedStartTime = null;
        if (day.length > 0) {
            let originalStartTime = Moment(day[day.length - 1].startTime);
            let modifiedStartTime = Moment(day[day.length - 1].startTime);
            modifiedStartTime.add(1, "hour");   // Ideally we want an hour from the previous one

            // Check if this has gone over
            if (originalStartTime.dayOfYear() !== modifiedStartTime.dayOfYear()) {
                // Gone over day, add 1 minute to the originalStartTime instead
                modifiedStartTime.subtract(1, "hour").add(1, "minute")
            }

            if (originalStartTime.dayOfYear() !== modifiedStartTime.dayOfYear()) {
                // We are on 23:59, bail out for now
                return;
            }


            calculatedStartTime = modifiedStartTime.toDate();
        } else {
            calculatedStartTime = this.makeTime("07:00")
        }


        day.push({
            reactId: uuidv4(),
            updated: true,
            startTime: calculatedStartTime,
            endTime: "to be computed",
            startTimeLocked: false,
            entries: entries
        });

        this.recomputeSchedule(days);
    }

    deleteTimeslot(dayIndex, timeslot) {
        let days = this.state.days;
        let day = days[dayIndex];

        // If we are in single datapoint mode, only remove the entry, not the entire timeslot
        if (this.props.singleDatapointMode) {
            let entry = timeslot.entries.find(e => e.datapoint.id === this.props.singleDatapointId);
            if (entry !== undefined) {
                entry.isSet = false;
                entry.isForceSet = false;
            }
        } else {
            // Find and slice the object
            let position = day.indexOf(timeslot);
            if (position > -1) {
                day.splice(position, 1);
            }
            days[dayIndex] = day;
        }

        // Put day back
        this.recomputeSchedule(days);
    }


    updateStartTime(dayIndex, ts, time) {
        let days = this.state.days;
        let day = days[dayIndex];

        // Find the object
        let o = day.find((d) => d === ts);
        o.startTime = time;
        o.updated = true;

        days[dayIndex] = day;

        // Put day back
        this.recomputeSchedule(days);

    }

    a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }


    handleSelectedTabChange(e, newValue) {
        this.setState({...this.state, selectedTab: newValue});
    }

    updateTsUpdated(dayIndex, ts, value) {
        let days = this.state.days;
        let day = days[dayIndex];

        // Find the object
        let o = day.find((d) => d === ts);
        if (o) {
            o.updated = value;
        }

        days[dayIndex] = day;
        this.setState({...this.state, days: days});
    }

    onTsPickerClose(dayIndex, ts) {
        // Ensure user can't pick the same time
        let days = this.state.days;
        let day = days[dayIndex];
        let doRecompute = false;

        // Find the object
        let o = day.find((d) => d === ts);

        // See if there are any other ts with the same time
        while (day.filter((d) => Moment(d.startTime).isSame(o.startTime)).length > 1) {
            o.startTime = Moment(o.startTime).add('1', 'minute').toDate();
            o.updated = true;
            doRecompute = true;
        }

        days[dayIndex] = day;

        // Put day back
        if (doRecompute) {
            this.recomputeSchedule(days);
        }
    }
}

export default withStyles(styles)(WeekEditor);
