import grapesjs from 'grapesjs';
import loadComponents from './components';
import loadBlocks from './blocks';

//
// Change this constant to something unique
//
export const blockRef = 'OpenSetpointSchedule';

export default grapesjs.plugins.add(`gjs-component-${blockRef}`, (editor, opts = {}) => {
    const c = opts;

    let defaults = {
        blockRef: blockRef,
        blockLabel: 'Open Schedule',
        blockCategory: 'Setpoint Controls',
        blockIcons: 'fa fa-external-link',
        defaultDisplayText: "Open Schedule",
        scheduleList: []
    };

    // Load defaults
    for (let name in defaults) {
        if (!(name in c))
            c[name] = defaults[name];
    }

    // Add components
    loadComponents(editor, c);

    // Add components
    loadBlocks(editor, c);

});
