import React from 'react';
import {Typography, withStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import ChartController from "./Charts/ChartController";
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../graphql/queries";
import TenantManager from "../TenantManager";
import {GetChartQuery} from "../API";
import PeriodSelector from "./Charts/PeriodSelector";

interface IProps {
    classes?: any
}

interface IState {
    charts: Array<GetChartQuery['getChart']>;
    chartsFetched: boolean
    selectedPeriodStr: string
    selectedPeriodVal: number
}

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        paddingTop: "20px",
        marginBottom: "20px",
    },
    paddedText: {
        padding: "20px",
        textAlign: "center" as const
    },
});


class Graphs extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);

        this.state = {
            charts: [],
            chartsFetched: false,
            selectedPeriodStr: '3h',
            selectedPeriodVal: 3600 * 3
        };

        this.fetchCharts();
    }

    async fetchCharts(){
        const allCharts = await API.graphql(graphqlOperation(queries.listCharts, {
            tenantId: TenantManager.getTenantId(),
            limit: 100
        }));

        this.setState({
            ...this.state,
            charts: allCharts.data.listCharts.items,
            chartsFetched: true
        })
    }

    setPeriod(periodStr: string, periodVal: number){
        this.setState({...this.state, selectedPeriodStr: periodStr, selectedPeriodVal: periodVal});
        return undefined;
    }

    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.root}>
                <Container>
                    <Typography variant="h3" className={classes.title}>
                        Graphs
                        {this.state.charts.length > 0 &&
                            <PeriodSelector selected={this.state.selectedPeriodStr} setCb={this.setPeriod.bind(this)}/>
                        }
                    </Typography>

                {this.state.charts.map((chart: GetChartQuery['getChart'], key: number) => (
                    <Container key={key}>
                        {chart &&
                            <ChartController key={chart.id} config={chart} periodSeconds={this.state.selectedPeriodVal} />
                        }
                    </Container>
                ))}

                    {this.state.charts.length === 0 && this.state.chartsFetched &&
                    <Typography className={classes.paddedText}>
                        There are currently no graphs.
                        {/*{this.state.userIsAdmin && <span>Add one using the + button below.</span>}*/}
                    </Typography>
                    }
                </Container>
            </div>)
    }

};

export default  withStyles(styles)(Graphs);
