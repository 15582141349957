export default function(editor, opt = {}) {
    const c = opt;
    const bm = editor.BlockManager;
    const pfx = c.blockRef;

    bm.add(c.blockRef, {
        label: c.label,
        category: c.labelCategory,
        attributes: {class: c.labelIcon},
        content: `
        <div class="${pfx}" data-gjs-type="${c.blockRef}" data-gjs-name="${c.label}" truebg="#379e18" falsebg="#eb3434" displaylabels="true">
            <div data-gjs-selectable="false" data-gjs-hoverable="false" class="${pfx}-OffLabel"></div>
            <div data-gjs-selectable="false" data-gjs-hoverable="false">
                <i data-gjs-selectable="false" data-gjs-hoverable="false" class="fa fa-toggle-on fa-2x active ${c.blockRef}-OnToggle"  style="display:none;"></i>
                <i data-gjs-selectable="false" data-gjs-hoverable="false" class="fa fa-toggle-on fa-2x fa-rotate-180 inactive ${c.blockRef}-OffToggle" style="color: #379e18"></i>
            </div>
            <div data-gjs-selectable="false" data-gjs-hoverable="false" class="${pfx}-OnLabel"></div>
        </div>
        <style>
            .${pfx} {
                cursor: pointer;
                display: flex;
                font-size: 18px;
                font-family: Arial, Helvetica, sans-serif;
            }
            .${pfx}-OffLabel, .${pfx}-OnLabel {
                margin: 3px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
            }
        </style>
      `
    });

}
