export default async function(editor, opt = {}) {
    const c = opt;
    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const blockRef = c.blockRef;

    domc.addType(blockRef, {

        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                blockRef: blockRef,
                droppable: false,
                traits: [{
                    label: 'Datapoint',
                    name: 'datapointid',
                    type: 'select',
                    changeProp: 0,
                    options: c.datapointList
                },
                {
                    label: 'Display Labels',
                    name: 'displaylabels',
                    type: 'checkbox',
                    changeProp: 0,
                },
                {
                    label: 'True BG',
                    name: 'truebg',
                    type: 'color',
                    changeProp: 0,
                },
                {
                    label: 'True Text',
                    name: 'truetextcolor',
                    type: 'color',
                    changeProp: 0,
                },
                {
                    label: 'False BG',
                    name: 'falsebg',
                    type: 'color',
                    changeProp: 0,
                },
                {
                    label: 'False Text',
                    name: 'falsetextcolor',
                    type: 'color',
                    changeProp: 0,
                }
                ],
                script: function() {
                    //let valueEl = this.querySelector('[data-js=booleandatapoint]');
                    let currentButtonState = false;
                    let lastDatapoint = null;

                    let onToggle = this.querySelector('.{[ blockRef ]}-OnToggle');
                    let offToggle = this.querySelector('.{[ blockRef ]}-OffToggle');

                    let onLabel = this.querySelector('.{[ blockRef ]}-OnLabel');
                    let offLabel = this.querySelector('.{[ blockRef ]}-OffLabel');

                    let render = (useCurrentButtonState, forceUpdate) => {
                        if(lastDatapoint === null) return;
                        currentButtonState = (useCurrentButtonState) ? currentButtonState : lastDatapoint.getSetTypedRawValue();

                        // Skip updates if value hasn't changed
                        // if(!useCurrentButtonState && !forceUpdate && currentButtonState === lastDatapoint.getSetTypedRawValue()) return;

                        // Update the state of the button
                        if(currentButtonState){
                            onToggle.style.display = "block";
                            offToggle.style.display = "none";
                        }else{
                            onToggle.style.display = "none";
                            offToggle.style.display = "block";
                        }

                        if(currentButtonState){
                            onToggle.style.color = this.getAttribute("truebg") || null;
                            // this.style.color = this.getAttribute("falsetextcolor") || null;
                        }else{
                            offToggle.style.color = this.getAttribute("falsebg") || null;
                            // this.style.backgroundColor =
                            // this.style.color = this.getAttribute("truetextcolor") || null;
                        }

                        if(this.getAttribute("displaylabels") === "true"){
                            // Set the labels
                            onLabel.innerHTML = lastDatapoint.getBooleanStateLabel("true");
                            onLabel.style.color = this.getAttribute("truetextcolor") || null;
                            offLabel.innerHTML = lastDatapoint.getBooleanStateLabel("false");
                            offLabel.style.color = this.getAttribute("falsetextcolor") || null;
                        }else{
                            // Clear labels
                            onLabel.innerHTML = '';
                            offLabel.innerHTML = '';
                        }

                    };

                    // Listen for modified changes from the parent IFrame
                    let handleExternalChange = (event) => {
                        if(event.data.hasOwnProperty('messageType') && event.data.messageType === 'DATAPOINT_UPDATE') {
                            // eslint-disable-next-line no-undef
                            let data = serialize.unserialize(event.data.data);
                            if(data.id === this.getAttribute("datapointid")) {
                                lastDatapoint = data;
                                render(false, lastDatapoint === null);
                            }
                        }
                    };

                    let handleClick = (event) => {
                      if(this.getAttribute("datapointid")){
                          // Send a message to change the state to the manager
                          let datapointId = this.getAttribute("datapointId");
                          currentButtonState = !currentButtonState;
                          render(true);

                          // Prepare payload
                          let data = {
                              messageType: 'DATAPOINT_SET',
                              // eslint-disable-next-line no-undef
                              data: serialize.serialize(
                                  {
                                      id: datapointId,
                                      value: currentButtonState ? "true" : "false"
                                  }
                              )
                          };

                          // Send a message to the parent iframe for processing
                          window.parent.postMessage(data, "*");
                      }
                    };

                    // Bind
                    window.addEventListener("message", handleExternalChange, false);
                    this.addEventListener("click", handleClick, false);

                }
            },
        }, {
            isComponent(el) {
                if(el.getAttribute &&
                    el.getAttribute('data-gjs-type') === blockRef) {
                    return {
                        type: blockRef
                    };
                }
            },
        }),


        view: defaultView.extend({
            init() {

            },
            onRender() {
                let dmode = '';

                // If we are inside a Diagram Container, use absolute positioning
                let parent = this.model.parent();
                if(parent && parent.hasOwnProperty('attributes')){
                    if(parent.attributes.name === "Diagram Container"){
                        dmode = 'absolute';
                    }
                }

                // console.log(dmode)
                this.model.set('dmode', dmode);
                // TODO: Reset attributes of component if coming from absolute -> ''.
            },
        }),
    });
}
