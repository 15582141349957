export default function(editor, opt = {}) {
    const c = opt;
    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const blockRef = c.blockRef;

    domc.addType(blockRef, {

        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                // dmode: 'absolute',
                droppable: false,
                traits: [{
                    label: 'Datapoint',
                    name: 'datapointid',
                    type: 'select',
                    changeProp: 0,
                    options: c.datapointList
                },
                {
                    label: 'Value Type',
                    name: 'valuetype',
                    type: 'select',
                    changeProp: 0,
                    options: [
                        {value: 'read', name: 'Read Value'},
                        {value: 'set', name: 'Set Value'}
                    ]
                },
                {
                    label: 'Display Units',
                    name: 'displayunits',
                    type: 'checkbox',
                    changeProp: 0,
                },


                ],



                script: function() {

                    let isAttrTruthy = (name) => {
                        return (this.getAttribute(name) === '' || this.getAttribute(name));
                    };

                    // Listen for modified changes from the parent IFrame
                    let handleExternalChange = (event) => {
                        if(event.data.hasOwnProperty('messageType') && event.data.messageType === 'DATAPOINT_UPDATE') {
                            // eslint-disable-next-line no-undef
                            let data = serialize.unserialize(event.data.data);
                            if(data.id === this.getAttribute("datapointid")){
                                let ignoreFacets = isAttrTruthy('displayunits') ? [] : ['units'];

                                if(this.getAttribute('valuetype') === 'read'){
                                    this.innerHTML = data.getReadDisplayValue(ignoreFacets);
                                }else{
                                    this.innerHTML = data.getSetDisplayValue(ignoreFacets);
                                }
                            }
                        }
                    };

                    // Bind
                    window.addEventListener("message", handleExternalChange, false);

                }
            },
        }, {
            isComponent(el) {
                if(el.getAttribute &&
                    el.getAttribute('data-gjs-type') === blockRef) {
                    return {
                        type: blockRef
                    };
                }
            },
        }),


        view: defaultView.extend({
            init() {
                this.listenTo(this.model, 'change:dataPointId', this.updateScript);

            },
            onRender() {
                let dmode = '';

                // If we are inside a Diagram Container, use absolute positioning
                let parent = this.model.parent();
                if(parent && parent.hasOwnProperty('attributes')){
                    if(parent.attributes.name === "Diagram Container"){
                        dmode = 'absolute';
                    }
                }

                // console.log(dmode)
                this.model.set('dmode', dmode);
                // TODO: Reset attributes of component if coming from absolute -> ''.
            },
        }),
    });
}
