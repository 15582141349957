export default function(editor, opt = {}) {
    const c = opt;
    const bm = editor.BlockManager;
    const pfx = c.blockRef;

    // Add the block
    bm.add(opt.blockRef, {
        label: c.blockLabel,
        category: c.blockCategory,
        attributes: {class: c.blockIcons},
        content: `
            <button class="${c.cls.buttonCls} ${c.cls.buttonDisabledCls}" data-gjs-type="${c.blockRef}" data-gjs-name="${c.blockLabel}">
                Apply Changes
            </button>
            <style>
            .${c.cls.buttonCls} {
                background-color: #4CAF50;
                border: none;
                color: white;
                padding: 10px 15px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 18px;
                margin: 4px 2px;
                cursor: pointer;
            }    
            .${c.cls.buttonDisabledCls} {
                opacity: 0.8;
                cursor: default;
            }
            </style>
        `
    });

}
