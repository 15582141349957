import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Paper,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    textFieldEnum: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // minWidth: 200,
    },
    enumEditorDelete: {
        marginTop: theme.spacing(1.5),
    },
    enumButton: {
        position: "absolute",
        top: theme.spacing(0),
        right: theme.spacing(0),
    },
    enumDisplayRoot: {
        position: "relative",
        padding: theme.spacing(3, 2),
        width: 217,
    },
    addEnumButton: {
        top: theme.spacing(1)
    }
});


class FacetProperty extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            enumEditorOpen: false
        }
    }

    onChange(e) {
        this.props.onChange(e.target.value);
    }

    onEnumChange(orginalEnum, e) {
        let currentEnumMap = this.props.value;

        // Get element within array
        let a = currentEnumMap.find((t) => {
            return t === orginalEnum
        })

        // Set appropraite value
        if (e.target.name === "value") {
            a.value = e.target.value;
        } else {
            a.display = e.target.value;
        }

        this.props.onChange(currentEnumMap);
    }

    onAddEnum() {
        let currentEnumMap = this.props.value;
        currentEnumMap.push({value: "", display: ""});
        this.props.onChange(currentEnumMap);
    }

    onDeleteEnum(orginalEnum) {
        let currentEnumMap = this.props.value;
        let a = currentEnumMap.find((t) => {
            return t === orginalEnum
        });
        let index = currentEnumMap.indexOf(a);
        currentEnumMap.splice(index, 1);
        this.props.onChange(currentEnumMap);
    }

    setEnumEditorState(state) {
        this.setState({...this.state, enumEditorOpen: state});
    }

    render() {
        const classes = this.props.classes;
        const values = this.props.values;
        const type = this.props.type;
        let value = this.props.value;
        let label = this.props.label;
        let disableEditor = !this.props.edit;

        let enumMapList, enumEditor;
        if (type === "enum") {

            enumMapList = value.map(v => {
                return (
                    <div>{v.value} = {v.display}</div>
                )
            });


            enumEditor = (
                <Dialog open={this.state.enumEditorOpen} onClose={() => this.setEnumEditorState(false)}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Enumeration Mappings</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Set the mappings below, where <i>value</i> is the expected system value
                            and <i>display</i> is the content users
                            are shown.
                        </DialogContentText>

                        {value.map(v => {
                            return (<Box width="100%">
                                <TextField
                                    label="Value"
                                    type="text"
                                    name="value"
                                    value={v.value}
                                    className={classes.textFieldEnum}
                                    onChange={this.onEnumChange.bind(this, v)}
                                />
                                <TextField
                                    label="Display"
                                    type="text"
                                    name="display"
                                    value={v.display}
                                    className={classes.textFieldEnum}
                                    onChange={this.onEnumChange.bind(this, v)}
                                />

                                <IconButton aria-label="delete" className={classes.enumEditorDelete}
                                            onClick={this.onDeleteEnum.bind(this, v)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Box>)
                        })}


                        <Button variant="contained" color="primary" className={classes.addEnumButton}
                                onClick={this.onAddEnum.bind(this)}>
                            Add Enum Mapping
                        </Button>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setEnumEditorState(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )

        }

        return (
            <FormControl className={classes.formControl}>
                {/*<InputLabel>Value</InputLabel>*/}
                {type === 'select' &&
                <TextField
                    select
                    value={value}
                    onChange={this.onChange.bind(this)}
                    label={label}
                    className={classes.textField}
                    disabled={disableEditor}
                >
                    {values.map((v) => <MenuItem value={v}>{v}</MenuItem>)}
                </TextField>
                }
                {type === 'numeric' &&
                <TextField
                    value={value} onChange={this.onChange.bind(this)}
                    type="number"
                    inputProps={this.props.inputAttr}
                    label={label}
                    className={classes.textField}
                    disabled={disableEditor}
                />
                }
                {type === 'text' &&
                <TextField
                    value={value} onChange={this.onChange.bind(this)}
                    type="text"
                    label={label}
                    className={classes.textField}
                    disabled={disableEditor}
                />
                }
                {type === 'enum' &&
                // List through available enums
                <div>
                    <Paper className={classes.enumDisplayRoot}>
                        {!disableEditor &&
                        <IconButton className={classes.enumButton} aria-label="edit"
                                    onClick={() => this.setEnumEditorState(true)}>
                            <EditIcon/>
                        </IconButton>
                        }
                        <Typography variant="subtitle2">
                            <b>Enum Mapping</b>
                        </Typography>
                        <Typography variant="body1">
                            {enumMapList}
                        </Typography>
                    </Paper>
                    {enumEditor}
                </div>
                }

            </FormControl>

        )
    }

}

export default withStyles(styles)(FacetProperty);