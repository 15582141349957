// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:fc58381f-f714-49ac-862e-4dff3ec973f4",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_oHaWclkOs",
    "aws_user_pools_web_client_id": "290mo7766s0rteq7u7o8dekfdt",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://d2xcidyurbbkvhg6dyh5aqgm6y.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "electairconnectui-tenantresources-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
