import CloneDeep from 'clone-deep';
import DatapointManager from '@/DatapointManager';
import IsEqual from 'lodash.isequal';
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "./graphql/mutations";

/**
 * Handles the storage of changes from UI based +/- actions.
 */
class DatapointChangeManager {

    cleanDatapoints = [];
    modifiedDatapoints = [];
    dirtyChangesIgnoredCb = null;

    /**
     * Loads the datapoints from the DatapointManager into clean and modified states.
     */
    async load() {
        DatapointManager.addChangeListener(this.listenForDatapointChanges)
        let datapoints = await DatapointManager.getAllDatapoints();
        datapoints.map(d => this.add(d));
    }

    setDirtyChangesIgnoredCb(cb) {
        this.dirtyChangesIgnoredCb = cb;
    }

    listenForDatapointChanges = async (datapoint) => {
        // A datapoint change could be from anything, we want to import it into
        // our storage, but restore the setValue and setMode properties
        let refreshedDatapoint = datapoint;
        let modifiedDatapoint = this.modifiedDatapoints.find(d => d.id === refreshedDatapoint.id);
        let cleanDatapoint = this.cleanDatapoints.find(d => d.id === refreshedDatapoint.id);

        if(!modifiedDatapoint || !cleanDatapoint){
            this.add(refreshedDatapoint);
            return;
        }

        // A: Update read values
        // B: Notify a user if someone else has changed a setValue or setMode
        // C: Don't override setValue or setMode

        // We have a datapoint
        // See if setValue || setMode is dirty
        if(!this._isDatapointDirty(cleanDatapoint)) {
            // This indicates something hasn't changed between the clean and dirty,
            // we can do a straight replacement
            this.add(refreshedDatapoint)
        }else{
            // We have a dirty datapoint, just replace the readValue of both
            modifiedDatapoint.readValue = refreshedDatapoint.readValue;
            cleanDatapoint.readValue = refreshedDatapoint.readValue;

            // Do we need to notify the user that a setpoint has been externally changed?
            // In this case, we would have a dirty DP and refreshed =/= clean
            if(refreshedDatapoint.setValue !== cleanDatapoint.setValue ||
                refreshedDatapoint.setMode !== cleanDatapoint.setMode){

                // Show dirty changes to the user, update the clean version
                if (this.dirtyChangesIgnoredCb !== null) {
                    this.dirtyChangesIgnoredCb()
                }

                // Update the cleanDatapoint so we don't notify the user again
                this._add_clean(refreshedDatapoint);

            }
        }
    };

    /**
     * Add the datapoint to the manager, the modifiedDatapoint reference is
     * returned.
     * @param datapoint
     */
    add(datapoint) {

        this._add_clean(datapoint)
        let modified = this._add_modified(datapoint);

        return modified;
    }

    _add_clean(datapoint){
        this.cleanDatapoints = this.cleanDatapoints.filter(d => datapoint.id !== d.id);
        let clean = CloneDeep(datapoint, true);
        this.cleanDatapoints.push(clean);
        return clean;
    }

    _add_modified(datapoint){
        this.modifiedDatapoints = this.modifiedDatapoints.filter(d => datapoint.id !== d.id);
        let modified = CloneDeep(datapoint, true);
        this.modifiedDatapoints.push(modified);
        return modified;
    }

    /**
     * Allows the user to get the modifiedDatapoint and modify it however they need by
     * reference.
     * @param datapointId
     * @returns {null}
     */
    get(datapointId) {
        let search = this.modifiedDatapoints.find(d => d.id === datapointId);
        return (search === undefined) ? null : search;
    }

    /**
     * Returns all modified datapoints, used for broadcasting to children IFrames
     * @returns {[]}
     */
    async getAllModified() {
        if (this.modifiedDatapoints.length === 0) await this.load();
        return this.modifiedDatapoints;
    }

    /**
     * Compares the modified and clean lists, returning true if any datapoints are not clean
     */
    isDirty() {
        for (let i = 0; i < this.cleanDatapoints.length; i++) {
            if (this._isDatapointDirty(this.cleanDatapoints[i])) {
                return true;
            }
        }
        return false;
    }

    _isDatapointDirty(cleanDatapoint) {
        let modifiedDatapoint = this.modifiedDatapoints.find(d => cleanDatapoint.id === d.id);

        return (modifiedDatapoint.setValue !== cleanDatapoint.setValue ||
            modifiedDatapoint.setMode !== cleanDatapoint.setMode ||
            modifiedDatapoint.readValue !== cleanDatapoint.readValue)
    }

    /**
     * Saves all modified values to the datastore (AppSync)
     * @returns {Promise<void>}
     */
    async save(successCb, failureCb) {
        let dirtyDatapoints = [];

        // Find only the dirty items
        for (let i = 0; i < this.cleanDatapoints.length; i++) {
            if (this._isDatapointDirty(this.cleanDatapoints[i])) {
                dirtyDatapoints.push(this.modifiedDatapoints.find(d => this.cleanDatapoints[i].id === d.id))
            }
        }

        // Push each datapoint into a promise list
        let updatePromises = dirtyDatapoints.map(datapoint => {
            return API.graphql(graphqlOperation(mutations.updateDatapoint,
                {
                    input: {
                        id: datapoint.id, tenantId: datapoint.tenantId,
                        setValue: datapoint.setValue, setMode: datapoint.setMode
                    }
                }))
        });

        // Attempt to resolve all
        Promise.all(updatePromises).then(() => {
            // Set the dirty to clean by reinserting them
            dirtyDatapoints.map(d => this.add(d));
            successCb()
        }).catch(failureCb);
    }


}


const dcm = new DatapointChangeManager();
export default dcm;
