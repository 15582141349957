import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';


Sentry.init({
    dsn: "https://add239eb21cc45fd9a93c857bb38a282@o441350.ingest.sentry.io/5411407",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

// Considering you have an existing aws-exports.js configuration file
Amplify.configure(awsconfig);


ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
