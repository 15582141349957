import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import {Auth} from 'aws-amplify';
import {Link as RouterLink} from 'react-router-dom';

class TopMenu extends React.Component {

    doLogout(event) {
        this.props.menuChangeState(false, event)
        Auth.signOut();
    }

    doProfilePage(event) {
        this.props.menuChangeState(false, event)
    }

    render() {
        return (
            <Menu
                id="menu-appbar"
                anchorEl={this.props.menuAnchor}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.props.menuOpen}
                onClose={() => {
                    this.props.menuChangeState(false)
                }}
            >
                {/*<MenuItem onClick={this.doProfilePage.bind(this)} component={RouterLink} to="/profile">*/}
                {/*    Profile*/}
                {/*</MenuItem>*/}
                <MenuItem onClick={this.doLogout.bind(this)} component={RouterLink} to="/login">
                    Log Out
                </MenuItem>
            </Menu>
        )
    }


}

export default TopMenu;