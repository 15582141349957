export default function(editor, opt = {}) {

    // Each new type extends the default Trait
    editor.TraitManager.addType('readonlyNumber', {
        events:{
            'keyup': 'onChange',  // trigger parent onChange method on keyup
        },

        /**
         * Returns the input element
         * @return {HTMLElement}
         */
        getInputEl: function() {
            if (!this.inputEl) {
                var input = document.createElement('div');
                input.innerHTML = this.model.get('value') || '--';
                input.setAttribute('title', this.model.attributes.tooltip || '');
                input.setAttribute('style', 'cursor: pointer');
                this.inputEl = input;
            }
            return this.inputEl;
        },

        /**
         * Triggered when the value of the model is changed
         */
        onValueChange: function () {
            // this.target.set('content', this.model.get('value'));
        }
    });

};