import React from "react";
import {
    Box,
    ButtonGroup,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Fade,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    withStyles
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {Link as RouterLink} from "react-router-dom";

const styles = theme => ({
    flexLeft: {
        paddingTop: "10px",
        flexGrow: 1
    },
    flexMiddle: {
        paddingTop: "10px",
        flexGrow: 1,
        textAlign: "center"
    },
    flexRight: {
        paddingTop: "10px",
        flexGrow: 1,
        textAlign: "right"
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    dropMenu: {
        zIndex: 200
    }
});


class ViewToolbar extends React.Component {

    constructor(props) {
        super(props)
        // https://blog.solutotlv.com/react-class-to-hooks/
        this.viewMenuRef = React.createRef();
        this.state = {
            viewSelectorOpen: false
        }
    }

    changeViewSelectorState(open) {
        this.setState({...this.state, viewSelectorOpen: open});
    }

    render() {
        const classes = this.props.classes;
        let options = this.props.views;

        let selectedViewName = (this.props.manager) ? "View Manager" : this.props.selectedView.name;

        const linkAttr = {
            // onClick: () => this.props.drawerToggleState(false),
            button: true,
            component: RouterLink,
        }

        let viewSelector = (options.length > 0 && <>
            <ButtonGroup className={classes.button} variant="contained" ref={this.viewMenuRef}
                         aria-label="split button">
                <Button>{selectedViewName}</Button>
                <Button
                    onClick={() => this.changeViewSelectorState(true)}
                    variant="contained"
                    size="small"
                    aria-owns='menu-list-grow'
                    aria-haspopup="true"
                >
                    <ArrowDropDownIcon/>
                </Button>
            </ButtonGroup>
            <Popper open={this.state.viewSelectorOpen} transition disablePortal anchorEl={this.viewMenuRef.current}
                    className={classes.dropMenu}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper id="menu-list-grow">
                            <ClickAwayListener onClickAway={() => this.changeViewSelectorState(false)}>
                                <MenuList>
                                    {options.map((option, index) => (
                                        <MenuItem key={option.id} {...linkAttr} to={"/views/" + option.id}
                                                  onClick={() => this.changeViewSelectorState(false)}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                    <Divider/>
                                    <MenuItem key="viewmanager" {...linkAttr} to="/views/manager"
                                              onClick={() => this.changeViewSelectorState(false)}>
                                        View Manager
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>);


        let viewEditor = (
            <>
                <Button variant="contained" color="primary" className={classes.button}>
                    <SaveIcon className={classes.leftIcon}/>
                    Save
                </Button>
            </>
        )

        return (
            <Box display="flex">
                <div className={classes.flexLeft}>
                    {viewSelector}
                </div>
                <div className={classes.flexMiddle}>
                    <Fade
                        in={this.props.isLoading}
                        style={{
                            transitionDelay: '800ms',
                        }}
                        unmountOnExit
                    >
                        <CircularProgress/>
                    </Fade>
                </div>
                <div className={classes.flexRight}>
                    {this.props.children}
                </div>
            </Box>
        )
    }

}

export default withStyles(styles)(ViewToolbar);