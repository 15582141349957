import React, {Component} from 'react';
import grapesjs from 'grapesjs';
import gjsBasicBlocks from 'grapesjs-blocks-basic';
import gjsFlexBlock from 'grapesjs-blocks-flexbox';
import GDatapointValue from './modules/GDatapointValue';
import GBooleanDataPoint from './modules/GBooleanDataPoint';
import GBooleanToggle from './modules/GBooleanToggle';
import GDiagramContainer from './modules/GDiagramContainer';
import GSetpointArrowButtonControl from './modules/GSetpointArrowButtonControl';
import GSetpointNumberField from './modules/GSetpointNumberField';
import GSetpointScheduleToggle from './modules/GSetpointScheduleToggle';
import GSetpointEnumSelect from './modules/GSetpointEnumSelect';
import GOpenSetpointSchedule from './modules/GOpenSetpointSchedule';
import GApplyChanges from './modules/GApplyChanges';
import GChangePage from './modules/GChangePage';
import GAppsyncStorage from './storage/GAppsyncStorage';
import GReadonlyNumberTrait from './traits/GReadonlyNumberTrait';
import 'grapesjs/dist/css/grapes.min.css';
import {Button, withStyles, CircularProgress} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import ViewToolbar from "../ui/ViewToolbar";
import Container from "@material-ui/core/Container";
import DatapointManager from "@/DatapointManager";
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../graphql/queries";
import TenantManager from "@/TenantManager";
import ScheduleManager from "@/ScheduleManager";

const styles = theme => ({
    leftGroup: {
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    dropMenu: {
        zIndex: 200
    },
});


class GEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saveButtonLoading: false,
            saveButtonDisabled: true
        };
        this.iframeRef = React.createRef();
    }

    componentWillUnmount() {
        this.editor = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.selectedView !== this.props.selectedView) this.setupEditor()
    }

    componentDidMount() {
        this.setupEditor();
    }

    async setupEditor(){
        let viewId = this.props.selectedView.id;
        this.editor = null;
        this.editor = grapesjs.init({
            // Indicate where to init the editor. You can also pass an HTMLElement
            container: '#gjs',
            // Get the content for the canvas directly from the element
            // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
            fromElement: false,
            autorender: false,
            // Size of the editor
            height: '100vh',
            width: 'auto',
            // Disable the storage manager for the moment
            storageManager: {
                id: viewId + '#',             // Prefix identifier that will be used on parameters
                type: 'appsync',          // Type of the storage
                autosave: true,         // Store data automatically
                autoload: true,         // Autoload stored data on init
                stepsBeforeSave: 10,     // If autosave enabled, indicates how many changes are necessary before store method is triggered
            },
            // Avoid any default panel
            panels: { },
            // domComponents: { storeWrapper: 1 },
            canvas: {
                scripts: [
                    'https://kit.fontawesome.com/8cec15323a.js',
                    '/serialize.js'
                ],
                styles: [
                    'https://use.fontawesome.com/releases/v5.9.0/css/all.css'
                ]
            }
            // blockManager: {},
            // plugins: [
            //     gjsBasicBlocks
            // ],
            // pluginsOpts: {
            //     gjsBasicBlocks: {
            //
            //     }
            // }

        });

        // Storage
        GAppsyncStorage(this.editor, {});


        ///////////////////////
        // Load extra traits //
        ///////////////////////
        GReadonlyNumberTrait(this.editor, {});


        //////////////////////
        // Load the plugins //
        //////////////////////

        // Basic Blocks for Layout
        // https://github.com/artf/grapesjs-blocks-basic
        gjsBasicBlocks(this.editor, {
            'blocks': [
                        'column1', 'column2', 'column3',
                        'column3-7'
                    ],
            'flexGrid': true,
            'category': 'Basic'
        });


        // gjsCkEditor(this.editor);

        // Flex box, for lots of boxes on a single row
        gjsFlexBlock(this.editor, {});

        // Basic Blocks for Content
        gjsBasicBlocks(this.editor, {
            'blocks': [
                'text', 'link', 'image', 'map'
            ],
            'flexGrid': true,
            'category': 'Content'
        });


        // Fetch data for lists and pass into the components
        // Get all datapoints
        let datapoints = await DatapointManager.getAllDatapoints();
        let datapointList = datapoints.map(s => {return {"value": s.id, "name": s.name}});

        let setpoints = await DatapointManager.getSetpoints();
        let anySetpointList = setpoints.map(s => {return {"value": s.id, "name": s.name}});

        let booleanSetpoints = await DatapointManager.getSetpoints('boolean');
        let booleanSetpointList = booleanSetpoints.map(s => {return {"value": s.id, "name": s.name}});


        let numericSetpoints = await DatapointManager.getSetpoints('numeric');
        let numericSetpointList = numericSetpoints.map(s => {return {"value": s.id, "name": s.name}});

        let booleanDatapoints = await DatapointManager.getDatapoints('boolean');
        let booleanDatapointList = booleanDatapoints.map(s => {return {"value": s.id, "name": s.name}});


        // Get all views format as TYPE|ID
        const viewQuery = await API.graphql(graphqlOperation(queries.listViews, {tenantId: TenantManager.getTenantId()}));
        viewQuery.data.listViews.items.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let viewList = viewQuery.data.listViews.items.map(s => {return {"value": "VIEW|" + s.id, "name": "View - " + s.name}})

        // Get all schedules as TYPE|ID
        const allSchedules = await ScheduleManager.getAllSchedules();
        let scheduleList = allSchedules.map(s => {return {"value": "SCHEDULE|" + s.id, "name": s.name}});
        let scheduleDpList = setpoints.map(s => {return {"value": "DATAPOINT|" + s.id, "name": "Setpoint - " + s.name}});
        scheduleList = scheduleList.concat(scheduleDpList);


        GDatapointValue(this.editor, {datapointList: datapointList});
        GBooleanDataPoint(this.editor, {datapointList: booleanDatapointList});
        GSetpointArrowButtonControl(this.editor, {datapointList: numericSetpointList, setpoints: numericSetpoints});
        GSetpointNumberField(this.editor, {datapointList: numericSetpointList, setpoints: numericSetpoints});
        GChangePage(this.editor, {viewList: viewList});
        GBooleanToggle(this.editor, {datapointList: booleanSetpointList});
        GSetpointScheduleToggle(this.editor, {datapointList: anySetpointList});
        GSetpointEnumSelect(this.editor, {datapointList: anySetpointList});
        GOpenSetpointSchedule(this.editor, {scheduleList: scheduleList});
        GDiagramContainer(this.editor, {'flexGrid': true});
        GApplyChanges(this.editor, {});

        // Attach event handler to editor, so we know when the iframe is setup
        this.editor.load(res => {
            this.props.setLoadingComplete();
            this.editor.render();
        });
        this.editor.on('load', (some, argument) => {
            // Enable data update posting we expect 1 IFrame
            this.props.setIframeRef(this.iframeRef.current.getElementsByClassName("gjs-frame")[0].contentWindow);
            // Make blocks the default panel
            this.editor.Panels.getButton('views', 'open-blocks').set('active', true)
        });

        this.editor.on('update', () => {
           if(this.editor.getDirtyCount() > 0 && this.state.saveButtonDisabled){
               this.setState({...this.state, saveButtonDisabled: false})
           }
        });

        // Patch for https://github.com/artf/grapesjs/issues/2160
        window.editor = this.editor;
    }

    doEditorSave(){
        this.setState({...this.state, saveButtonLoading: true, saveButtonDisabled: true})
        this.editor.store(()=>{
            this.setState({...this.state, saveButtonLoading: false, saveButtonDisabled: true})
            this.props.reloadViews();
        });
    }

    render() {
        const classes = this.props.classes;
        return (
            <div>
                <Container>
                    <ViewToolbar views={this.props.views} selectedView={this.props.selectedView} isLoading={this.props.loader}>
                        <Button  variant="contained" className={classes.button} button component={RouterLink}
                                 to={"/views/" + this.props.selectedView.id}>
                            Close
                        </Button>
                        <Button  disabled={this.state.saveButtonDisabled} variant="contained" color="primary" className={classes.button} button onClick={() => this.doEditorSave()}>
                            {!this.state.saveButtonLoading && "Save"}
                            {this.state.saveButtonLoading && <CircularProgress size={24}  color="secondary" />}
                        </Button>
                    </ViewToolbar>
                </Container>
                {}
                <div id="gjs" ref={this.iframeRef}></div>
            </div>
        );
    }
}

export default withStyles(styles)(GEditor);
