import grapesjs from 'grapesjs';

//
// Change this constant to something unique
//
export const blockRef = 'DiagramContainer';

export default grapesjs.plugins.add(`gjs-${blockRef}`, (editor, opts = {}) => {
    const config = {
        // flexGrid: 0,
        // addBasicStyle: true,
        blockRef: blockRef,
        category: 'Basic',
        label: 'Diagram Container',
        labelIcon: 'gjs-fonts gjs-f-b1'
    };

    // Add blocks
    const loadBlocks = require('./blocks');
    loadBlocks.default(editor, config);

});