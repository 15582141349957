import * as React from "react";
import {
    AppBar,
    Button,
    createStyles,
    Dialog,
    IconButton,
    Slide,
    Theme,
    Toolbar,
    Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import WeekEditor from "./WeekEditor";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

const Transition: any = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps extends WithStyles<typeof styles> {
    timeslots: Array<Object>;
    updateScheduleTimeslots: Function;
    setpoints: Array<Object>;
    title: String;
    // Display Options
    onClose: Function;
    onSave: Function;
    open: boolean;
    fullscreen: boolean;
    singleDatapointMode?: boolean;
    singleDatapointId?: String
}

class WeekEditorDialogFull extends React.Component<IProps> {

    constructor(props: Readonly<IProps>) {
        super(props)
    }

    render() {
        const classes = this.props.classes;
        return (
            <Dialog fullScreen open={this.props.open} onClose={this.props.onClose.bind(this)}
                    TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.props.onClose.bind(this)}
                                    aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {this.props.title}
                        </Typography>
                        <Button color="inherit" onClick={this.props.onSave.bind(this)}>
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>
                <WeekEditor
                    timeslots={this.props.timeslots}
                    updateScheduleTimeslots={this.props.updateScheduleTimeslots}
                    setpoints={this.props.setpoints}
                    singleDatapointMode={this.props.singleDatapointMode}
                    singleDatapointId={this.props.singleDatapointId}
                />
            </Dialog>

        )
    }

}

export default withStyles(styles)(WeekEditorDialogFull);