import React from 'react';
import Views from "./Views";

class Dashboard extends React.Component {

    render() {
        return (
            <Views sub="view" match={{params: {}}} cb={this.props.cb} history={this.props.history} fullscreen/>
        )
    }

};

export default Dashboard;