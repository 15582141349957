// tslint:disable
// this is an auto generated file. This will be overwritten

export const onCreateView = `subscription OnCreateView {
  onCreateView {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    configuration {
      bucket
      key
      region
    }
  }
}
`;
export const onUpdateView = `subscription OnUpdateView {
  onUpdateView {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    configuration {
      bucket
      key
      region
    }
  }
}
`;
export const onDeleteView = `subscription OnDeleteView {
  onDeleteView {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    configuration {
      bucket
      key
      region
    }
  }
}
`;
export const onCreateTenant = `subscription OnCreateTenant {
  onCreateTenant {
    id
    name
    slug
    legacySiteId
    customPwaIcons
  }
}
`;
export const onUpdateTenant = `subscription OnUpdateTenant {
  onUpdateTenant {
    id
    name
    slug
    legacySiteId
    customPwaIcons
  }
}
`;
export const onDeleteTenant = `subscription OnDeleteTenant {
  onDeleteTenant {
    id
    name
    slug
    legacySiteId
    customPwaIcons
  }
}
`;
export const onCreateDatapoint = `subscription OnCreateDatapoint {
  onCreateDatapoint {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    description
    type
    legacyRegisterId
    isSetpoint
    isReadpoint
    readValue
    setValue
    setMode
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    readTimestamp
    facets {
      units
      divisor
      precision
      scale
      enumMapping {
        value
        display
      }
      trueText
      falseText
      min
      max
      step
    }
  }
}
`;
export const onUpdateDatapoint = `subscription OnUpdateDatapoint {
  onUpdateDatapoint {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    description
    type
    legacyRegisterId
    isSetpoint
    isReadpoint
    readValue
    setValue
    setMode
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    readTimestamp
    facets {
      units
      divisor
      precision
      scale
      enumMapping {
        value
        display
      }
      trueText
      falseText
      min
      max
      step
    }
  }
}
`;
export const onDeleteDatapoint = `subscription OnDeleteDatapoint {
  onDeleteDatapoint {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    description
    type
    legacyRegisterId
    isSetpoint
    isReadpoint
    readValue
    setValue
    setMode
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    readTimestamp
    facets {
      units
      divisor
      precision
      scale
      enumMapping {
        value
        display
      }
      trueText
      falseText
      min
      max
      step
    }
  }
}
`;
export const onCreateSchedule = `subscription OnCreateSchedule {
  onCreateSchedule {
    tenantId
    id
    name
    description
    datapoints {
      items {
        tenantId
        id
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
        name
        description
        type
        legacyRegisterId
        isSetpoint
        isReadpoint
        readValue
        setValue
        setMode
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        readTimestamp
        facets {
          units
          divisor
          precision
          scale
          enumMapping {
            value
            display
          }
          trueText
          falseText
          min
          max
          step
        }
      }
      nextToken
    }
    profiles {
      items {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      nextToken
    }
    activeProfile {
      tenantId
      id
      name
      type
      schedule {
        tenantId
        id
        name
        description
        datapoints {
          items {
            tenantId
            id
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
            name
            description
            type
            legacyRegisterId
            isSetpoint
            isReadpoint
            readValue
            setValue
            setMode
            schedule {
              tenantId
              id
              name
              description
            }
            readTimestamp
            facets {
              units
              divisor
              precision
              scale
              trueText
              falseText
              min
              max
              step
            }
          }
          nextToken
        }
        profiles {
          items {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          nextToken
        }
        activeProfile {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      timeslots {
        key
        entries {
          datapointId
          value
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const onUpdateSchedule = `subscription OnUpdateSchedule {
  onUpdateSchedule {
    tenantId
    id
    name
    description
    datapoints {
      items {
        tenantId
        id
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
        name
        description
        type
        legacyRegisterId
        isSetpoint
        isReadpoint
        readValue
        setValue
        setMode
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        readTimestamp
        facets {
          units
          divisor
          precision
          scale
          enumMapping {
            value
            display
          }
          trueText
          falseText
          min
          max
          step
        }
      }
      nextToken
    }
    profiles {
      items {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      nextToken
    }
    activeProfile {
      tenantId
      id
      name
      type
      schedule {
        tenantId
        id
        name
        description
        datapoints {
          items {
            tenantId
            id
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
            name
            description
            type
            legacyRegisterId
            isSetpoint
            isReadpoint
            readValue
            setValue
            setMode
            schedule {
              tenantId
              id
              name
              description
            }
            readTimestamp
            facets {
              units
              divisor
              precision
              scale
              trueText
              falseText
              min
              max
              step
            }
          }
          nextToken
        }
        profiles {
          items {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          nextToken
        }
        activeProfile {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      timeslots {
        key
        entries {
          datapointId
          value
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const onDeleteSchedule = `subscription OnDeleteSchedule {
  onDeleteSchedule {
    tenantId
    id
    name
    description
    datapoints {
      items {
        tenantId
        id
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
        name
        description
        type
        legacyRegisterId
        isSetpoint
        isReadpoint
        readValue
        setValue
        setMode
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        readTimestamp
        facets {
          units
          divisor
          precision
          scale
          enumMapping {
            value
            display
          }
          trueText
          falseText
          min
          max
          step
        }
      }
      nextToken
    }
    profiles {
      items {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      nextToken
    }
    activeProfile {
      tenantId
      id
      name
      type
      schedule {
        tenantId
        id
        name
        description
        datapoints {
          items {
            tenantId
            id
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
            name
            description
            type
            legacyRegisterId
            isSetpoint
            isReadpoint
            readValue
            setValue
            setMode
            schedule {
              tenantId
              id
              name
              description
            }
            readTimestamp
            facets {
              units
              divisor
              precision
              scale
              trueText
              falseText
              min
              max
              step
            }
          }
          nextToken
        }
        profiles {
          items {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          nextToken
        }
        activeProfile {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      timeslots {
        key
        entries {
          datapointId
          value
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const onCreateDeployment = `subscription OnCreateDeployment {
  onCreateDeployment {
    tenantId
    id
    type
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    timeRequested
  }
}
`;
export const onUpdateDeployment = `subscription OnUpdateDeployment {
  onUpdateDeployment {
    tenantId
    id
    type
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    timeRequested
  }
}
`;
export const onDeleteDeployment = `subscription OnDeleteDeployment {
  onDeleteDeployment {
    tenantId
    id
    type
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    timeRequested
  }
}
`;
export const onCreateScheduleProfile = `subscription OnCreateScheduleProfile {
  onCreateScheduleProfile {
    tenantId
    id
    name
    type
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    timeslots {
      key
      entries {
        datapointId
        value
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const onUpdateScheduleProfile = `subscription OnUpdateScheduleProfile {
  onUpdateScheduleProfile {
    tenantId
    id
    name
    type
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    timeslots {
      key
      entries {
        datapointId
        value
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const onDeleteScheduleProfile = `subscription OnDeleteScheduleProfile {
  onDeleteScheduleProfile {
    tenantId
    id
    name
    type
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    timeslots {
      key
      entries {
        datapointId
        value
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const onCreateChart = `subscription OnCreateChart {
  onCreateChart {
    tenantId
    id
    title
    view
    metrics {
      datapointId
      statistic
      period
      yAxis
      color
      label
      displayType
    }
    yAxis {
      left {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
      right {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const onUpdateChart = `subscription OnUpdateChart {
  onUpdateChart {
    tenantId
    id
    title
    view
    metrics {
      datapointId
      statistic
      period
      yAxis
      color
      label
      displayType
    }
    yAxis {
      left {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
      right {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const onDeleteChart = `subscription OnDeleteChart {
  onDeleteChart {
    tenantId
    id
    title
    view
    metrics {
      datapointId
      statistic
      period
      yAxis
      color
      label
      displayType
    }
    yAxis {
      left {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
      right {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
