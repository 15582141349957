export default function(editor, opt = {}) {
    const c = opt;
    const bm = editor.BlockManager;
    const pfx = c.blockRef;

    // Add the block
    bm.add(c.blockRef, {
        label: c.label,
        category: c.labelCategory,
        attributes: {class: c.blockIcons},
        content: `
            <div data-gjs-editable="true" data-gjs-removable="true" data-gjs-type="${c.blockRef}"  data-gjs-name="${c.label}" class="${pfx}">
                <div data-gjs-selectable="false" data-gjs-hoverable="false" >
                    <input data-gjs-selectable="false" data-gjs-hoverable="false" class="${pfx}-NumberInput" type="number" />
                </div>
                <div data-gjs-selectable="false" data-gjs-hoverable="false" class="${pfx}-Units"></div>
            </div>
            <style>
            .${pfx} {
                display: flex;
                font-size: 20px;
                font-family: Arial, Helvetica, sans-serif;
            }
            .${pfx}-Units {
              display: flex;
              justify-content: center;
              flex-direction: column;
            }
            .${pfx}-NumberInput {
              display: flex;
              font-size: inherit;
              color: inherit;
              justify-content: center;
              flex-direction: column;
            }
          </style>

            `
    });

}
