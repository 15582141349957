export default function(editor, opt = {}) {
    const c = opt;
    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const blockRef = c.blockRef;

    // Prevent style editing
    const protectedCls = ['.fas', '.fa-caret-square-up', '.fa-caret-square-down']
    editor.on('selector:add', (selector) => {
        protectedCls.indexOf(selector.getFullName()) >= 0 && selector.set('private', 1)
    });

    // Setup the type
    domc.addType(blockRef, {
        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                droppable: false,
                traits: [{
                    label: 'Numeric Setpoint',
                    name: 'setpointid',
                    type: 'select',
                    changeProp: 0,
                    options: c.datapointList
                },
                {
                    label: 'Action',
                    name: 'buttonaction',
                    type: 'select',
                    changeProp: 0,
                    options: [
                        {value: 'incr', name: 'Increment'},
                        {value: 'decr', name: 'Decrement'},
                    ]
                },
                {
                    label: 'Step',
                    name: 'step',
                    type: 'readonlyNumber',
                    tooltip: "Fetched from the Datapoint Facet configuration",
                    value: '1'
                }

                ],
                script: function() {
                    let step = "1"; // Default step

                    // Setup correct button styles
                    if(this.getAttribute("buttonaction") !== 'incr'){
                        this.classList.remove("fa-caret-square-up");
                        this.classList.add("fa-caret-square-down");
                    }

                    // Handle a click event
                    let handleClick = () => {
                        let setpointId = this.getAttribute("setpointid");
                        let buttonAction = this.getAttribute("buttonaction");
                        let v;

                        if (buttonAction === 'incr') {
                            v = 0 + parseFloat(step);
                        } else {
                            v = 0 - parseFloat(step);
                        }

                        // Prepare payload
                        let data = {
                            messageType: "DATAPOINT_CHANGEBY",
                            // eslint-disable-next-line no-undef
                            data: serialize.serialize({
                                id: setpointId,
                                value: v
                            })
                        };

                        // Send a message to the parent iframe for processing
                        window.parent.postMessage(data, "*");
                    };

                    // Get Facets from Datapoint Update messages
                    let handleMessage = (event) => {
                        // if (event.origin !== "http://example.org:8080")
                        //     return;
                        if(event.data.hasOwnProperty('messageType') && event.data.messageType === 'DATAPOINT_UPDATE'){
                            // eslint-disable-next-line no-undef
                            let setpoint = serialize.unserialize(event.data.data); // {messageType, data: datapoint}
                            if(setpoint.id === this.getAttribute("setpointid")){
                                // Set facet
                                if(setpoint.getFacet('step') !== null){
                                    step = setpoint.getFacet('step');
                                }
                            }
                        }
                    };

                    // Bind
                    this.addEventListener("click", handleClick, false);
                    window.addEventListener("message", handleMessage, false);

                }
            },
        }, {
            isComponent(el) {
                if(el.getAttribute &&
                    el.getAttribute('data-gjs-type') === blockRef) {
                    return {
                        type: blockRef
                    };
                }
            },
        }),


        view: defaultView.extend({
            init() {
                this.listenTo(this.model, 'change', (e)=> {
                    // Update button style
                    this.el.classList.remove("fa-caret-square-up");
                    this.el.classList.remove("fa-caret-square-down");

                    if(this.el.getAttribute('buttonaction') === 'incr'){
                        // Add up attr
                        this.el.classList.add("fa-caret-square-up")
                    }else{
                        this.el.classList.add("fa-caret-square-down")
                    }

                    // Check in our setpoints if a step facet has been set
                    let spid = this.el.getAttribute('setpointid') || '';
                    let sp = c.setpoints.find(s => s.id === spid);
                    if(sp !== undefined && sp !== null){
                        let trait = this.model.get('traits').where({name: 'step'})[0];
                        if(trait && sp.getFacet('step') !== null) {
                            trait.set('value', sp.getFacet('step'));
                        }
                    }

                    editor.TraitManager.getTraitsViewer().render();
                });
            },
            onRender() {
                editor.TraitManager.getTraitsViewer().render();
                let dmode = '';

                // If we are inside a Diagram Container, use absolute positioning
                let parent = this.model.parent();
                if(parent && parent.hasOwnProperty('attributes')){
                    if(parent.attributes.name === "Diagram Container"){
                        dmode = 'absolute';
                    }
                }

                this.model.set('dmode', dmode);
                // TODO: Reset attributes of component if coming from absolute -> ''.
            },
        }),
    });
}
