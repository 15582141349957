import React from "react";
import {withStyles} from "@material-ui/core";
import ViewManager from "./ViewManager";
import ViewGrapesHandler from "./ViewGrapesHandler";
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../graphql/queries";
import TenantManager from "@/TenantManager";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});


class Views extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            views: [],
            viewsLoaded: false,
            selectedView: null,
            mode: null
        }
    }

    async reloadViews() {
        let op = await API.graphql(graphqlOperation(queries.listViews, {tenantId: TenantManager.getTenantId()}))
        op.data.listViews.items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let items = op.data.listViews.items;
        this.setState({...this.state, views: items, viewsLoaded: true})

    }

    async componentDidMount() {

        // Get all views for the site
        await this.reloadViews();

        this.refreshComponentState();

    }

    refreshComponentState(){
        // If none are available, send the user to the management page if we aren't already on it
        if(this.state.viewsLoaded && this.state.views.length === 0){
            if(this.props.sub !== 'manager'){
                this.props.history.push("/views/manager");
                return;
            }
        }

        // Apply changes when fullscreen
        if(this.state.selectedView !== null && this.props.fullscreen) {
            return;
        }

        // If we are a view or edit, and we haven't had a view set yet
        if(this.props.sub !== 'manager') {
            // Check if a view has been provided via URL
            const {match: {params}} = this.props;
            let selectedViewId = (params.viewId) ? params.viewId : null;

            // Choose the selected view, or by default choose the first one returned
            if (selectedViewId) {
                let view = this.state.views.find(v => v.id === selectedViewId);
                if (view) {
                    this.setState({...this.state, selectedView: view});
                } else {
                    // Invalid view, redirect to default
                    this.props.history.push("/views");
                    return;
                }
            } else {
                // Load the first one in the list
                this.setState({...this.state, selectedView: this.state.views[0]});
            }
        }

        // Unset views if the user is on management screen
        if(this.props.sub === 'manager'){
            this.setState({...this.state, selectedView: null});
        }

    }

    userRequestChangeView(id) {
        if (this.props.fullscreen) {
            // Do the magic behind the scenes
            let selected = this.state.views.find((e) => {
                return e.id === id;
            });
            if (selected) {
                this.setState({...this.state, selectedView: selected, mode: this.props.sub});
            }
        } else {
            // Redirect the user
            this.props.history.push("/views/" + id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) this.refreshComponentState()
    }

    isReadyForRender(){
        if(this.props.sub === 'view' || this.props.sub === 'edit'){
            return this.state.selectedView !== null // View/Edit require a view to be ready
        }else{
            return this.state.viewsLoaded;  // Manager requires list of views to be loaded
        }
    }

    render() {
        let fullscreen = this.props.fullscreen;

        return (
            <>
                {this.isReadyForRender() &&
                <div>
                    {this.props.sub === 'manager' &&
                    <ViewManager views={this.state.views}
                                 addSnack={this.props.cb.addSnack} history={this.props.history}
                                 reloadViews={this.reloadViews.bind(this)}/>}
                    {(this.props.sub === 'view' || this.props.sub === 'edit') &&
                    <ViewGrapesHandler views={this.state.views} selectedView={this.state.selectedView}
                                       mode={this.props.sub} fullscreen={fullscreen} history={this.props.history}
                                       addSnack={this.props.cb.addSnack} reloadViews={this.reloadViews.bind(this)}
                                       changeViewCb={this.userRequestChangeView.bind(this)}/>}
                </div>
                }
            </>
        )
    }

}

export default withStyles(styles)(Views);
