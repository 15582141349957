import React from "react";
import {
    Button,
    Container,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormHelperText,
    Grid,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import Datapoint from "./Datapoint";
import DatapointManager from "@/DatapointManager";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import TenantManager from "@/TenantManager";

import DatapointEditorField from "./DatapointEditorField";

const styles = theme => ({
    root: {
        marginTop: "20px",
        flexGrow: 1,
    },
    paddedText: {
        padding: "20px",
        textAlign: "center"
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
});


class Datapoints extends React.Component {

    // Setup data management
    constructor(props) {
        super(props);
        this.state = {
            datapoints: [],
            savedDatapoints: [],
            datapointsLoaded: false,
            addDialogName: "",
            addDialogOpen: false,
            userIsAdmin: false
        };

        this.inEditCount = 0;
    }

    async componentDidMount() {
        DatapointManager.addChangeListener(this.handleDatastoreUpdate.bind(this))
        this.setState({
            ...this.state,
            datapoints: await DatapointManager.getAllDatapoints(true),
            datapointsLoaded: true,
            userIsAdmin: await TenantManager.isTenantAdmin()
        });
    }

    async handleDatastoreUpdate() {
        if (this.inEditCount === 0) {
            this.setState({...this.state, datapoints: await DatapointManager.getAllDatapoints()})
        }
    }

    componentWillUnmount() {
        DatapointManager.removeChangeListener(this.handleDatastoreUpdate.bind(this));
    }

    async reloadDatapoints() {
        this.setState({...this.state, datapoints: await DatapointManager.getAllDatapoints(true)});
    }


    handleFacetChange(id, key, value) {
        let dp = this.state.datapoints;
        let a = dp.find((t) => {
            return t.id === id
        });

        if (key === "enumMapping" && value === null) {
            value = [];
        }

        a.facets[key] = value;
        this.setState({...this.state, datapoints: dp})
    }

    handleFacetDelete(id, key) {
        let dp = this.state.datapoints;
        let a = dp.find((t) => {
            return t.id === id
        });
        delete a.facets[key];
        this.setState({...this.state, datapoints: dp})
    }


    handleTopPropChange(id, key, value) {
        let dp = this.state.datapoints;
        let a = dp.find((t) => {
            return t.id === id
        });

        if(key === 'rwMode'){
            a.setRwMode(value);
        }else{
            a[key] = value;
        }

        this.setState({...this.state, datapoints: dp})
    }

    handleDatapointEdit() {
        this.inEditCount++;
    }

    handleDatapointSave(id) {
        this.inEditCount--;
        let editedDatapoint = this.state.datapoints.find((t) => t.id === id);
        DatapointManager.updateDatapoint(editedDatapoint);
    }

    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Container>
                    <Typography variant="h3" className={classes.title}>
                        Datapoints
                    </Typography>
                    <br/>

                    {this.state.datapoints.length > 0 &&
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {this.state.datapoints.map(value => (
                                    <Grid key={value.id} item>
                                        <Datapoint
                                            key={value.id}
                                            datapoint={value}
                                            onFacetChange={(k, v) => this.handleFacetChange(value.id, k, v)}
                                            onFacetDelete={(k) => this.handleFacetDelete(value.id, k)}
                                            onTopPropChange={(k, v) => this.handleTopPropChange(value.id, k, v)}
                                            onDatapointSave={() => this.handleDatapointSave(value.id)}
                                            onDatapointEdit={() => this.handleDatapointEdit()}
                                            reloadDatapoints={this.reloadDatapoints.bind(this)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                    {this.state.datapoints.length === 0 && this.state.datapointsLoaded &&
                    <Typography className={classes.paddedText}>
                        There are currently no datapoints.
                    </Typography>
                    }

                </Container>

                {this.state.userIsAdmin &&
                <Tooltip title="Add Datapoint">
                    <Fab aria-label="Add" className={classes.fab} color="primary"
                         onClick={() => this.setState({...this.state, addDialogOpen: true})}>
                        <AddIcon/>
                    </Fab>
                </Tooltip>
                }

                {/*Add Dialog*/}
                <Dialog open={this.state.addDialogOpen} onClose={() => this.setState({...this.state, addDialogOpen: false})}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Datapoint</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Datapoint Name"
                            type="text"
                            required={true}
                            value={this.state.addDialogName}
                            fullWidth
                            onChange={(e) => this.setState({...this.state, addDialogName: e.target.value})}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({...this.state, addDialogOpen: false})} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.addDialogDoViewCreate.bind(this)} color="primary">
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }

    async addDialogDoViewCreate(){
        await DatapointManager.createNewDatapoint(this.state.addDialogName); // TODO: Make closing this nicer
        await this.reloadDatapoints();
        this.setState({...this.state, addDialogOpen: false})
    }

}

export default withStyles(styles)(Datapoints);
