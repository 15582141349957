import React from "react";
import {Container, Grid, Tooltip, Typography, withStyles} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ScheduleEditor from "./ScheduleEditor";
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../graphql/queries";
import TenantManager from "@/TenantManager";
import ScheduleItem from "./ScheduleItem";
import DatapointManager from "@/DatapointManager";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paddedText: {
        padding: "20px",
        textAlign: "center"
    },
    title: {
        paddingTop: "20px",
        marginBottom: "20px",
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
});


class Schedules extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorActive: false,
            schedules: [],
            schedulesLoaded: false,
            availableSetpoints: [],
            userIsAdmin: false
        }
    }

    async componentDidMount() {
        // Fetch the schedules
        this.reloadSchedules();
        this.setState({...this.state, userIsAdmin: await TenantManager.isTenantAdmin()});
    }

    reloadSchedules() {
        API.graphql(graphqlOperation(queries.listSchedules, {tenantId: TenantManager.getTenantId()}))
            .then(d => {
                d.data.listSchedules.items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                let items = d.data.listSchedules.items;
                this.setState({...this.state, schedules: items, schedulesLoaded: true})
            });
        DatapointManager.getUnscheduledSetpoints().then(d => {
            this.setState({...this.state, availableSetpoints: d})
        })
    }

    setEditorState(state) {
        this.setState({...this.state, editorActive: state})
    }

    render() {
        const classes = this.props.classes;
        let rows = this.state.schedules;

        return (
            <div className={classes.root}>
                <Container>
                    <Typography variant="h3" className={classes.title}>
                        Schedules
                    </Typography>


                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {rows.map(schedule => (
                                    <Grid key={schedule.id} item>
                                        <ScheduleItem
                                            schedule={schedule}
                                            availableSetpoints={this.state.availableSetpoints}
                                            reloadSchedules={this.reloadSchedules.bind(this)}
                                            addSnack={this.props.cb.addSnack}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>


                    {rows.length === 0 && this.state.schedulesLoaded &&
                    <Typography className={classes.paddedText}>
                        There are currently no schedules. {this.state.userIsAdmin && <span>Add one using the + button below.</span>}
                    </Typography>
                    }


                    <ScheduleEditor
                        addSnack={this.props.cb.addSnack}
                        open={this.state.editorActive}
                        onClose={() => this.setEditorState(false)}
                        reloadSchedules={this.reloadSchedules.bind(this)}
                        availableSetpoints={this.state.availableSetpoints}
                    />

                    {this.state.userIsAdmin &&
                        <Tooltip title="Add Schedule">
                            <Fab aria-label="Add" className={classes.fab} color="primary"
                                 onClick={() => this.setEditorState(true)}>
                                <AddIcon/>
                            </Fab>
                        </Tooltip>
                    }

                </Container>
            </div>
        )
    }

}

export default withStyles(styles)(Schedules);