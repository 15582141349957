export default function(editor, opt = {}) {
    const c = opt;
    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const blockRef = c.blockRef;

    domc.addType(blockRef, {

        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                droppable: false,
                traits: [{
                    label: 'Datapoint',
                    name: 'datapointid',
                    type: 'select',
                    changeProp: 0,
                    options: c.datapointList
                },
                {
                    label: 'Value Type',
                    name: 'valuetype',
                    type: 'select',
                    changeProp: 0,
                    options: [
                        {value: 'read', name: 'Read Value'},
                        {value: 'set', name: 'Set Value'}
                    ]
                },
                {
                    label: 'True BG',
                    name: 'trueBg',
                    type: 'color',
                    changeProp: 0,
                },
                {
                    label: 'True Text Color',
                    name: 'trueTextColor',
                    type: 'color',
                    changeProp: 0,
                },
                {
                    label: 'False BG',
                    name: 'falseBg',
                    type: 'color',
                    changeProp: 0,
                },
                {
                    label: 'False Text Color',
                    name: 'falseTextColor',
                    type: 'color',
                    changeProp: 0,
                }
                ],
                script: function() {
                    //let valueEl = this.querySelector('[data-js=booleandatapoint]');

                    // Listen for modified changes from the parent IFrame
                    let handleExternalChange = (event) => {
                        if(event.data.hasOwnProperty('messageType') && event.data.messageType === 'DATAPOINT_UPDATE') {
                            // eslint-disable-next-line no-undef
                            let data = serialize.unserialize(event.data.data);
                            if(data.id === this.getAttribute("datapointid")){
                                // Inner HTML
                                if(this.getAttribute('valuetype') === 'read'){
                                    this.innerHTML = data.getReadDisplayValue();
                                }else{
                                    this.innerHTML = data.getSetDisplayValue();
                                }

                                // Apply styles if required
                                let rawValue = (this.getAttribute('valuetype') === 'read') ? data.getReadTypedRawValue() : data.getSetTypedRawValue();
                                if(rawValue === false){
                                    this.style.backgroundColor = this.getAttribute("falsebg") || null;
                                    this.style.color = this.getAttribute("falsetextcolor") || null;
                                }else{
                                    this.style.backgroundColor = this.getAttribute("truebg") || null;
                                    this.style.color = this.getAttribute("truetextcolor") || null;
                                }
                            }
                        }
                    };

                    // Bind
                    window.addEventListener("message", handleExternalChange, false);

                }
            },
        }, {
            isComponent(el) {
                if(el.getAttribute &&
                    el.getAttribute('data-gjs-type') === blockRef) {
                    return {
                        type: blockRef
                    };
                }
            },
        }),


        view: defaultView.extend({
            init() {

            },
            onRender() {
                let dmode = '';

                // If we are inside a Diagram Container, use absolute positioning
                let parent = this.model.parent();
                if(parent && parent.hasOwnProperty('attributes')){
                    if(parent.attributes.name === "Diagram Container"){
                        dmode = 'absolute';
                    }
                }

                // console.log(dmode)
                this.model.set('dmode', dmode);
                // TODO: Reset attributes of component if coming from absolute -> ''.
            },
        }),
    });
}
