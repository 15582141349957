// tslint:disable
// this is an auto generated file. This will be overwritten

export const getView = `query GetView($tenantId: ID!, $id: ID!) {
  getView(tenantId: $tenantId, id: $id) {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    configuration {
      bucket
      key
      region
    }
  }
}
`;
export const listViews = `query ListViews(
  $tenantId: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelViewFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listViews(
    tenantId: $tenantId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenantId
      id
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
      name
      configuration {
        bucket
        key
        region
      }
    }
    nextToken
  }
}
`;
export const getTenant = `query GetTenant($id: ID!) {
  getTenant(id: $id) {
    id
    name
    slug
    legacySiteId
    customPwaIcons
  }
}
`;
export const listTenants = `query ListTenants(
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
) {
  listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    nextToken
  }
}
`;
export const getDatapoint = `query GetDatapoint($tenantId: ID!, $id: ID!) {
  getDatapoint(tenantId: $tenantId, id: $id) {
    tenantId
    id
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    name
    description
    type
    legacyRegisterId
    isSetpoint
    isReadpoint
    readValue
    setValue
    setMode
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    readTimestamp
    facets {
      units
      divisor
      precision
      scale
      enumMapping {
        value
        display
      }
      trueText
      falseText
      min
      max
      step
    }
  }
}
`;
export const listDatapoints = `query ListDatapoints(
  $tenantId: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelDatapointFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDatapoints(
    tenantId: $tenantId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenantId
      id
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
      name
      description
      type
      legacyRegisterId
      isSetpoint
      isReadpoint
      readValue
      setValue
      setMode
      schedule {
        tenantId
        id
        name
        description
        datapoints {
          items {
            tenantId
            id
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
            name
            description
            type
            legacyRegisterId
            isSetpoint
            isReadpoint
            readValue
            setValue
            setMode
            schedule {
              tenantId
              id
              name
              description
            }
            readTimestamp
            facets {
              units
              divisor
              precision
              scale
              trueText
              falseText
              min
              max
              step
            }
          }
          nextToken
        }
        profiles {
          items {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          nextToken
        }
        activeProfile {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      readTimestamp
      facets {
        units
        divisor
        precision
        scale
        enumMapping {
          value
          display
        }
        trueText
        falseText
        min
        max
        step
      }
    }
    nextToken
  }
}
`;
export const getSchedule = `query GetSchedule($tenantId: ID!, $id: ID!) {
  getSchedule(tenantId: $tenantId, id: $id) {
    tenantId
    id
    name
    description
    datapoints {
      items {
        tenantId
        id
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
        name
        description
        type
        legacyRegisterId
        isSetpoint
        isReadpoint
        readValue
        setValue
        setMode
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        readTimestamp
        facets {
          units
          divisor
          precision
          scale
          enumMapping {
            value
            display
          }
          trueText
          falseText
          min
          max
          step
        }
      }
      nextToken
    }
    profiles {
      items {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      nextToken
    }
    activeProfile {
      tenantId
      id
      name
      type
      schedule {
        tenantId
        id
        name
        description
        datapoints {
          items {
            tenantId
            id
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
            name
            description
            type
            legacyRegisterId
            isSetpoint
            isReadpoint
            readValue
            setValue
            setMode
            schedule {
              tenantId
              id
              name
              description
            }
            readTimestamp
            facets {
              units
              divisor
              precision
              scale
              trueText
              falseText
              min
              max
              step
            }
          }
          nextToken
        }
        profiles {
          items {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          nextToken
        }
        activeProfile {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      timeslots {
        key
        entries {
          datapointId
          value
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const listSchedules = `query ListSchedules(
  $tenantId: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelScheduleFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSchedules(
    tenantId: $tenantId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    nextToken
  }
}
`;
export const getDeployment = `query GetDeployment($tenantId: ID!, $id: ID!) {
  getDeployment(tenantId: $tenantId, id: $id) {
    tenantId
    id
    type
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
    timeRequested
  }
}
`;
export const listDeployments = `query ListDeployments(
  $tenantId: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelDeploymentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDeployments(
    tenantId: $tenantId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenantId
      id
      type
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
      timeRequested
    }
    nextToken
  }
}
`;
export const getScheduleProfile = `query GetScheduleProfile($tenantId: ID!, $id: ID!) {
  getScheduleProfile(tenantId: $tenantId, id: $id) {
    tenantId
    id
    name
    type
    schedule {
      tenantId
      id
      name
      description
      datapoints {
        items {
          tenantId
          id
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
          name
          description
          type
          legacyRegisterId
          isSetpoint
          isReadpoint
          readValue
          setValue
          setMode
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          readTimestamp
          facets {
            units
            divisor
            precision
            scale
            enumMapping {
              value
              display
            }
            trueText
            falseText
            min
            max
            step
          }
        }
        nextToken
      }
      profiles {
        items {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        nextToken
      }
      activeProfile {
        tenantId
        id
        name
        type
        schedule {
          tenantId
          id
          name
          description
          datapoints {
            items {
              tenantId
              id
              name
              description
              type
              legacyRegisterId
              isSetpoint
              isReadpoint
              readValue
              setValue
              setMode
              readTimestamp
            }
            nextToken
          }
          profiles {
            items {
              tenantId
              id
              name
              type
            }
            nextToken
          }
          activeProfile {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        timeslots {
          key
          entries {
            datapointId
            value
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    timeslots {
      key
      entries {
        datapointId
        value
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const listScheduleProfiles = `query ListScheduleProfiles(
  $tenantId: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelScheduleProfileFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listScheduleProfiles(
    tenantId: $tenantId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenantId
      id
      name
      type
      schedule {
        tenantId
        id
        name
        description
        datapoints {
          items {
            tenantId
            id
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
            name
            description
            type
            legacyRegisterId
            isSetpoint
            isReadpoint
            readValue
            setValue
            setMode
            schedule {
              tenantId
              id
              name
              description
            }
            readTimestamp
            facets {
              units
              divisor
              precision
              scale
              trueText
              falseText
              min
              max
              step
            }
          }
          nextToken
        }
        profiles {
          items {
            tenantId
            id
            name
            type
            schedule {
              tenantId
              id
              name
              description
            }
            timeslots {
              key
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          nextToken
        }
        activeProfile {
          tenantId
          id
          name
          type
          schedule {
            tenantId
            id
            name
            description
            datapoints {
              nextToken
            }
            profiles {
              nextToken
            }
            activeProfile {
              tenantId
              id
              name
              type
            }
            tenant {
              id
              name
              slug
              legacySiteId
              customPwaIcons
            }
          }
          timeslots {
            key
            entries {
              datapointId
              value
            }
          }
          tenant {
            id
            name
            slug
            legacySiteId
            customPwaIcons
          }
        }
        tenant {
          id
          name
          slug
          legacySiteId
          customPwaIcons
        }
      }
      timeslots {
        key
        entries {
          datapointId
          value
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    nextToken
  }
}
`;
export const getChart = `query GetChart($tenantId: ID!, $id: ID!) {
  getChart(tenantId: $tenantId, id: $id) {
    tenantId
    id
    title
    view
    metrics {
      datapointId
      statistic
      period
      yAxis
      color
      label
      displayType
    }
    yAxis {
      left {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
      right {
        min
        max
        label
        binary {
          enabled
          labelOn
          labelOff
        }
      }
    }
    tenant {
      id
      name
      slug
      legacySiteId
      customPwaIcons
    }
  }
}
`;
export const listCharts = `query ListCharts(
  $tenantId: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelChartFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCharts(
    tenantId: $tenantId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenantId
      id
      title
      view
      metrics {
        datapointId
        statistic
        period
        yAxis
        color
        label
        displayType
      }
      yAxis {
        left {
          min
          max
          label
          binary {
            enabled
            labelOn
            labelOff
          }
        }
        right {
          min
          max
          label
          binary {
            enabled
            labelOn
            labelOff
          }
        }
      }
      tenant {
        id
        name
        slug
        legacySiteId
        customPwaIcons
      }
    }
    nextToken
  }
}
`;
