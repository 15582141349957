import React from 'react';
import moment from "moment";
import uuidv4 from 'uuid/v4';
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "./graphql/mutations";
import TenantManager from "@/TenantManager";

class DeploymentManager {

    async createDeployment() {
        // Perform a deployment
        try {
            await API.graphql(graphqlOperation(mutations.createDeployment,
                {
                    input: {
                        id: uuidv4(), tenantId: TenantManager.getTenantId(),
                        type: 'setpoints', timeRequested: moment().toISOString()
                    }
                }));
        } catch (e){
            // TODO: Make this snackable
            console.log("Error creating deployment.");
        }
    }

};

let d = new DeploymentManager();
export default d;
