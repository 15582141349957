import TenantManager from "./TenantManager";
import React from 'react';
import CloneDeep from 'clone-deep';
import InputAdornment from '@material-ui/core/InputAdornment';
import {API, graphqlOperation} from 'aws-amplify';
import * as subscriptions from './graphql/subscriptions';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import uuidv4 from 'uuid/v4';
import * as moment from 'moment';

class DatapointManager {

    constructor() {
        this.datapoints = [];
        this.datapointsFetched = false;
        this.subscribedListeners = [];
        this.latestUpdate = moment("01-01-2020", "DD-MM-YYYY");
        this.latestUpdateCb = null;
    }

    clearDatapoints() {
        this.datapoints = [];
        this.datapointsFetched = false;
        this.subscribedListeners = [];
        this.latestUpdate = moment("01-01-2020", "DD-MM-YYYY");
    }

    updateLatestUpdate(d) {
        const lastUpdated = d.getReadTimestamp();
        if(lastUpdated !== null && this.latestUpdate.isBefore(lastUpdated)){
            this.latestUpdate = lastUpdated;
            if(this.latestUpdateCb !== null){
                this.latestUpdateCb();
            }
        }
    }

    setUpdateTimeCallback(appCb){
        this.latestUpdateCb = appCb;
    }

    getLatestReadUpdateString(){
        if(this.latestUpdate) {
            return `${this.latestUpdate.fromNow()} at ${this.latestUpdate.format("dddd, MMMM Do YYYY, h:mm:ss a")}`
        }
        return "Unknown";
    }

    getLatestReadUpdateInSeconds(){
        if(this.latestUpdate) {
            let duration = moment.duration(this.latestUpdate.diff(moment()));
            return Math.abs(duration.asSeconds())
        }
        return null;
    }

    async createNewDatapoint(name) {
        let payload = {
            id: uuidv4(),
            tenantId: TenantManager.getTenantId(),
            isSetpoint: false,
            isReadpoint: true,
            type: 'numeric',
            name: name
        };

        // Prepare for GQL mutation
        try {
            await API.graphql(graphqlOperation(mutations.createDatapoint, {input: payload}));
        } catch (e) {
            console.log("Create Error", e);
        }
    }

    hydrateDatapointWithHelpers(d) {
        if (d.facets) {
            Object.keys(d.facets).map((k) => {
                if (d.facets[k] === null) {
                    delete d.facets[k];
                }
            });
        } else {
            d.facets = {}; // Make empty object
        }

        // Set helper functions
        d.getRwMode = function () {
            if (this.isSetpoint && this.isReadpoint) {
                return "rw"
            } else if (this.isSetpoint && !this.isReadpoint) {
                return "w"
            } else if (!this.isSetpoint && this.isReadpoint) {
                return "r"
            } else {
                return 'none';
            }
        };

        d.getReadTimestamp = function () {
            if(this.readTimestamp) {
                return moment(this.readTimestamp);
            }else{
                return null;
            }
        }

        d.setRwMode = function (value) {
            switch (value) {
                case 'r':
                    this.isSetpoint = false;
                    this.isReadpoint = true;
                    break;
                case 'rw':
                    this.isSetpoint = true;
                    this.isReadpoint = true;
                    break;
                case 'w':
                    this.isSetpoint = true;
                    this.isReadpoint = false;
                    break;
                default:
                    this.isSetpoint = false;
                    this.isReadpoint = false;
                    break;
            }
        };

        d.getType = function () {
          return this.type; // numeric, boolean, enum, string
        };

        d.getReadDisplayValue = function (ignoreFacetsArray) {
            if (!Array.isArray(ignoreFacetsArray)) ignoreFacetsArray = [];
            let value = this.readValue;

            switch (this.type) {
                case 'numeric':
                    return this._formatNumericValue(value, ignoreFacetsArray);
                case 'boolean':
                    return this._formatBooleanValue(value, ignoreFacetsArray);
                case 'enum':
                    return this._formatEnumValue(value, ignoreFacetsArray);
                default:    // Or String
                    return value;
            }

        };

        d.getSetDisplayValue = function (ignoreFacetsArray) {
            if (!Array.isArray(ignoreFacetsArray)) ignoreFacetsArray = [];

            // If scheduled, get the currently scheduled value
            let value = this.getSetValue();

            switch (this.type) {
                case 'numeric':
                    return this._formatNumericValue(value, ignoreFacetsArray);
                case 'boolean':
                    return this._formatBooleanValue(value, ignoreFacetsArray);
                case 'enum':
                    return this._formatEnumValue(value, ignoreFacetsArray);
                default:    // Or String
                    return value;
            }

        };

        d.getSetValue = function () {
            let value = this.setValue;
            if (this.isScheduled()) {
                value = this.getScheduledSetValue();
            }
            return value;
        };

        d.isScheduleAvailable = function () {
            return (this.isSetpoint && this.schedule && this.schedule.activeProfile && this.schedule.activeProfile.timeslots)
        };

        d.isScheduled = function () {
            return (this.isScheduleAvailable() && this.setMode === 'scheduled');
        };

        d.getScheduledSetValue = function () {
            let value = this.setValue;

            if (this.isScheduleAvailable()) {
                // Ensure the timeslots are ordered // TODO FIX SERIALISATION
                let timeslots = this.schedule.activeProfile.timeslots
                timeslots.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));

                // Date will give 01:01, remove the colon so we can cast to int for comparison
                let date = new Date();
                let currentDay = (date.getDay() === 0) ? 7 : date.getDay();
                let options = {hour: '2-digit', minute: '2-digit', timezone: 'Europe/London', hour12: false}
                let t = date.toLocaleString('en-GB', options);
                let currentTime = parseInt(t.slice(0, 2) + t.slice(3, 5));

                // Go through each timeslot, setting the value if its for us and in the past
                timeslots.some((ts => {
                    let key = ts.key;
                    // Split the key
                    let day = parseInt(key.slice(0, 1));
                    let time = parseInt(key.slice(2, 6));

                    // Skip this record if there are no timeslot entries
                    if(!timeslots.entries) return false;

                    // Iterate and save the value for all previous schedule timeslots
                    if(day < currentDay || (day === currentDay && time <= currentTime)){
                        let e = ts.entries.find(entry => entry.datapointId === this.id);
                        if (e !== undefined) value = e.value;
                        return false; // Continue looping
                    }else{
                        // Stop looping as we have past the current time
                        return true;
                    }
                }));
            }

            return value.toString();
        };

        d.getFacet = function (value) {
            if (this.facets) {
                if (this.facets.hasOwnProperty(value)) {
                    return this.facets[value];
                }
            }
            return null;
        };

        d.getDefaultSetpointValue = function () {
            switch (this.type) {
                case 'numeric':
                    if (this.facets && this.facets.hasOwnProperty('min')) {
                        return this.facets.min.toString();
                    } else if (this.facets && this.facets.hasOwnProperty('max')) {
                        return this.facets.max.toString();
                    }
                    return "0";
                case 'boolean':
                    return false;
                case 'string':
                    return "";
                case 'enum':
                    let e = this.getEnumMap()
                    if (e.length > 0) {
                        return e[0].value;
                    }
            }

            return "0";
        };

        d.getEnumMap = function () {
            if (this.facets && this.facets.enumMapping) {
                return this.facets.enumMapping;
            }
            return [];
        };

        d.getNumericEditorInputProps = function () {
            let resp = {};
            if (this.facets) {
                if (this.facets.min) resp['min'] = this.facets.min;
                if (this.facets.max) resp['max'] = this.facets.max;
                if (this.facets.step) resp['step'] = this.facets.step;
                if (this.facets.units) resp['endAdornment'] =
                    <InputAdornment position="end">{this.facets.unit}</InputAdornment>;
            }

            return resp;
        };

        d.changeSetValue = function (value) {

            if(typeof value !== "string") {
                if (this.getFacet('min') !== null && value < this.getFacet('min')) value = this.getFacet('min');
                if (this.getFacet('max') !== null && value > this.getFacet('max')) value = this.getFacet('max');

                // Check if we should apply any precision
                if (this.getFacet('precision')) {
                    let precision = parseInt(this.getFacet('precision'));
                    value = parseFloat(value.toPrecision(precision));
                }

                // Scale (Decimal Places)
                if (this.getFacet('scale')) {
                    let scale = parseInt(this.getFacet('scale'));
                    value = parseFloat(value.toFixed(scale));
                } else {  // Default scale 2 if not defined
                    value = parseFloat(value.toFixed(2));
                }
            }

            this.setValue = value.toString();
        };

        d.changeSetMode = function (value) {
            if (value !== 'manual' && value !== 'scheduled') return;
            this.setMode = value;
        };

        d.getSetMode = function () {
            if (this.setMode === null) return 'manual';
            return this.setMode;
        };

        d.getReadTypedRawValue = function () {
            return this._formatTypedValue(this.readValue);
        };

        d.getSetTypedRawValue = function () {
            return this._formatTypedValue(this.getSetValue());
        };

        d._formatTypedValue = function (value) {
            switch (this.type) {
                case 'numeric':
                    return parseFloat(value);
                case 'boolean':
                    return this.valueIsBooleanTrue(value);
                default:    // Or String or enum
                    return value;
            }
        };

        d._formatNumericValue = function (value, ignoreFacetsArray) {
            if (value === null) return -1;
            value = parseFloat(value);

            // Precision (Total number of digits)
            if (this.getFacet('precision') && ignoreFacetsArray.indexOf('precision') === -1) {
                let precision = parseInt(this.getFacet('precision'));
                value = parseFloat(value.toPrecision(precision));

            }

            // Scale (Decimal Places)
            if (this.getFacet('scale') && ignoreFacetsArray.indexOf('scale') === -1) {
                let scale = parseInt(this.getFacet('scale'));
                value = parseFloat(value.toFixed(scale));
            }

            // Units
            if (this.getFacet('units') && ignoreFacetsArray.indexOf('units') === -1) {
                value = value.toString() + this.getFacet('units');
            }

            return value.toString();
        };

        d.valueIsBooleanTrue = function (value){
            return (value === true || value === "true" || value === 1 || value === "1");
        };

        d.getBooleanStateLabel = function (value, ignoreFacetsArray) {
            if (ignoreFacetsArray === undefined) ignoreFacetsArray = [];
            if (this.valueIsBooleanTrue(value)) {
                if (this.getFacet('trueText') && ignoreFacetsArray.indexOf('trueText') === -1) {
                    return this.getFacet('trueText');
                } else {
                    return 'True';
                }
            } else {
                if (this.getFacet('falseText') && ignoreFacetsArray.indexOf('falseText') === -1) {
                    return this.getFacet('falseText');
                } else {
                    return 'False';
                }
            }
        };

        d._formatBooleanValue = function (value, ignoreFacetsArray) {
            if (value === null) return 'Unknown';
            return this.getBooleanStateLabel(value, ignoreFacetsArray);
        };

        d._formatEnumValue = function (value, ignoreFacetsArray) {
            if (value === null) return 'Unknown';

            // Only facet a enum type needs
            if (this.getFacet('enumMapping') && ignoreFacetsArray.indexOf('enumMapping') === -1) {
                let mapping = this.getEnumMap();

                // Find a match to the current value (String Comparison)
                let match = mapping.find(v => v.value.toString() === value.toString());
                if (match) {
                    return match.display
                }
            }

            // If we have fallen out of our enum map, or the user doesn't want it
            return value;
        };

        // Force update timestamp
        this.updateLatestUpdate(d);

        // Return object with functions
        return d;
    }

    addChangeListener(o) {
        // Check the listener doesn't exist already
        if (this.subscribedListeners.indexOf(o) === -1)
            this.subscribedListeners.push(o);
    }

    removeChangeListener(o) {
        let position = this.subscribedListeners.indexOf(o);
        this.subscribedListeners.slice(position, 1);
    }

    _triggerChange(datapoint) {
        this.subscribedListeners.map(listener => listener(datapoint));
    }

    async subscribeToDatapoints() {
        // Subscribe to modification
        API.graphql(
            graphqlOperation(subscriptions.onUpdateDatapoint, {tenantId: TenantManager.getTenantId()})
        ).subscribe({
            next: (data) => this.handleSubscriptionUpdate(data)
        });
    }

    async getSetpoints(type) {
        const setpoints = await API.graphql(graphqlOperation(queries.listDatapoints, {
            tenantId: TenantManager.getTenantId(),
            filter: {isSetpoint: {eq: true}},
            limit: 100
        }));

        let s = setpoints.data.listDatapoints.items;
        if (type !== null && type !== undefined) {
            s = setpoints.data.listDatapoints.items.filter(dp => dp.type === type);
        }

        s.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        s.map(dp => this.hydrateDatapointWithHelpers(dp));

        return s;
    }

    async getDatapoints(type) {
        const datapoints = await API.graphql(graphqlOperation(queries.listDatapoints, {
            tenantId: TenantManager.getTenantId(),
            limit: 100
        }));

        let s = datapoints.data.listDatapoints.items;
        if (type !== null && type !== undefined) {
            s = datapoints.data.listDatapoints.items.filter(dp => dp.type === type);
        }

        s.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        s.map(dp => this.hydrateDatapointWithHelpers(dp));

        return s;
    }

    async getUnscheduledSetpoints() {
        const setpoints = await API.graphql(graphqlOperation(queries.listDatapoints, {
            tenantId: TenantManager.getTenantId(),
            filter: {isSetpoint: {eq: true}},
            limit: 100
        }));

        // Filter and sort and hydrate
        let unscheduledSetpoints = setpoints.data.listDatapoints.items.filter(dp => dp.schedule === null);
        unscheduledSetpoints.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        unscheduledSetpoints.map(dp => this.hydrateDatapointWithHelpers(dp));

        return unscheduledSetpoints;

    }

    handleSubscriptionUpdate(data) {
        // Ensure id and tenantId are present
        let d = data.value.data;
        if (d.hasOwnProperty("onUpdateDatapoint") &&
            d.onUpdateDatapoint.hasOwnProperty("tenantId") &&
            d.onUpdateDatapoint.hasOwnProperty("id") &&
            d.onUpdateDatapoint.tenantId === TenantManager.getTenantId()) {

            // Find the datapoint
            let o = this.datapoints.find(dp => dp.id === d.onUpdateDatapoint.id);
            if (o) {
                // Update the relevant datapoint
                let keys = Object.keys(d.onUpdateDatapoint);
                keys.map(key => {
                    if (key !== "__typename" && key !== "id" && key !== "tenantId") {
                        if (o[key] !== d.onUpdateDatapoint[key] && key !== 'facets' && d.onUpdateDatapoint[key] !== null) {
                            o[key] = d.onUpdateDatapoint[key];
                        }
                    }
                });

                this._triggerChange(o);
            }
        }
    }

    async getAllDatapoints(force) {

        if (!this.datapointsFetched || force) {
            // Set flag
            this.datapointsFetched = true;
            // Fetch
            const allDatapoints = await API.graphql(graphqlOperation(queries.listDatapoints, {
                tenantId: TenantManager.getTenantId(),
                limit: 100
            }));
            // Sort on name
            allDatapoints.data.listDatapoints.items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            // Hydrate
            this.datapoints = [];
            allDatapoints.data.listDatapoints.items.map(dp => this.datapoints.push(this.hydrateDatapointWithHelpers(dp)));
            // Subscribe
            this.subscribeToDatapoints();
            // this._triggerChange();
        }

        // Return
        return this.datapoints;
    }

    async updateDatapoint(o) {

        // Update the object
        let dp = CloneDeep(o);

        // Cleanup
        Object.keys(dp).map(key => {
            if (typeof dp[key] == "function") {
                delete dp[key]
            }
        });

        delete dp["tenant"];
        delete dp["__typename"];
        delete dp["schedule"];

        // Prepare for GQL mutation
        try {
            await API.graphql(graphqlOperation(mutations.updateDatapoint, {input: dp}));
        } catch (e) {
            console.log("Update Error", e);
        }

    }

    async getDatapointById(id) {
        const dpQuery = await API.graphql(graphqlOperation(queries.getDatapoint, {
            id: id,
            tenantId: TenantManager.getTenantId()
        }));
        return this.hydrateDatapointWithHelpers(dpQuery.data.getDatapoint);
    }


};

let d = new DatapointManager();
export default d;
