import React from "react";
import {IconButton, Snackbar, SnackbarContent, withStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import {amber, green} from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';

import clsx from 'clsx';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    close: {
        padding: theme.spacing(0.5),
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },

});

/**
 * Props
 * message: message to display to the user
 * type: variant
 */
class FeedbackSnack extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: true
        }

        this.variantIcon = {
            success: CheckCircleIcon,
            warning: WarningIcon,
            error: ErrorIcon,
            info: InfoIcon,
        };
    }

    handleClose() {
        this.setState({...this.state, open: false});
    }

    render() {
        const classes = this.props.classes;
        const Icon = this.variantIcon[this.props.variant];
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={this.handleClose.bind(this)}


            >
                <SnackbarContent
                    className={clsx(classes[this.props.variant], classes.margin)}
                    message={
                        <span id="client-snackbar" className={classes.message}>
                      <Icon className={clsx(classes.icon, classes.iconVariant)}/>
                            {this.props.message}
                    </span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            className={classes.icon}
                            onClick={this.handleClose.bind(this)}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                >

                </SnackbarContent>
            </Snackbar>
        )
    }

}

export default withStyles(styles)(FeedbackSnack);