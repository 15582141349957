import grapesjs from 'grapesjs';
import loadComponents from './components';
import loadBlocks from './blocks';

//
// Change this constant to something unique
//
export const blockRef = 'ChangePage';

export default grapesjs.plugins.add(`gjs-component-${blockRef}`, (editor, opts = {}) => {
    const c = opts;

    let defaults = {
        blockRef: blockRef,
        blockLabel: 'Change Page',
        blockCategory: 'Navigation',
        blockIcons: 'fa fa-external-link',
        cls: {  // HTML class names you will use within the block/component
            buttonCls: `${blockRef}-button`
        },
        defaultDisplayText: "Change Page",
        viewList: []
    };

    // Load defaults
    for (let name in defaults) {
        if (!(name in c))
            c[name] = defaults[name];
    }

    // Add components
    loadComponents(editor, c);

    // Add components
    loadBlocks(editor, c);

});
