import React from 'react';
import MenuDrawer from "./MenuDrawer";
import TopMenu from "./TopMenu";
// UI Components
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
});


class Index extends React.Component {

    constructor() {
        super();
        this.state = {
            drawerOpen: false,
            menuOpen: false,
            menuAnchor: null,
        }
    }

    drawerToggleState() {
        if (this.props.isAppReady) {
            this.setState({...this.state, drawerOpen: !this.state.drawerOpen});
        }
    };

    drawerSetState(state){
        if (this.props.isAppReady && (state === true || state === false)) {
            this.setState({...this.state, drawerOpen: state});
        }
    }

    menuChangeState(v, event) {
        let mu = this.state.menuAnchor;
        if (event) {
            mu = event.currentTarget;
        }

        this.setState({...this.state, menuOpen: v, menuAnchor: mu});

    }

    outputOnlineState(){
        if(this.props.onlineState === "ONLINE") {
            return <span style={{color: '#4fb94f', marginLeft: '10px', cursor: "pointer"}} title={"Last updated: " + this.props.onlineTimestamp}>
                    <CheckCircleOutlineIcon fontSize="inherit" style={{verticalAlign: "text-top"}}/>
                </span>;
        }else{
            return <span style={{color: '#f27272', marginLeft: '10px', cursor: "pointer"}} title={"Last updated: " + this.props.onlineTimestamp}>
                    <ErrorOutlineIcon fontSize="inherit" style={{verticalAlign: "text-top"}}/>
                </span>;
        }
    }

    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.root}>
                <header>
                    <div>
                        <AppBar position="static">
                            <Toolbar>
                                <IconButton edge="start" color="inherit" aria-label="menu"
                                            onClick={this.drawerToggleState.bind(this)}>
                                    <MenuIcon/>
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    {this.props.isAppReady && this.props.tenant.name}
                                    {this.props.isAppReady && this.outputOnlineState()}
                                </Typography>
                                <div>
                                    <IconButton aria-haspopup="true" color="inherit" onClick={this.menuChangeState.bind(this, true)}>
                                        <AccountCircle />
                                    </IconButton>
                                    <TopMenu menuOpen={this.state.menuOpen}
                                             menuChangeState={this.menuChangeState.bind(this)}
                                             menuAnchor={this.state.menuAnchor}
                                    />
                                </div>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <MenuDrawer drawerOpen={this.state.drawerOpen}
                                drawerChangeState={this.drawerSetState.bind(this)}
                                anchorEl={this.menuAnchor}
                                tenants={this.props.tenants}
                                setTenant={this.props.cb.setTenant}
                    />
                </header>

                <div>
                    {/*Content to Display*/}
                    {this.props.children}
                </div>

            </div>
        )
    }

};

export default withStyles(styles)(Index);