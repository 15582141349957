// Libraries
import {Auth, API, graphqlOperation} from 'aws-amplify';
import * as queries from './graphql/queries';


/**
 * Singleton class to manage the listing
 * and selection of Tenants available to
 * the user. Use:
 *
 * import TenantManager from '@/TenantManager';
 * let id = TenantManager.getTenantId();
 */
class TenantManager {

    // Currently selected Tenant
    selectedTenantId = "";
    selectedTenantName = "";
    isAdmin = null;

    // Available Tenants for this user
    async listTenants() {
        const allTenants = await API.graphql(graphqlOperation(queries.listTenants));
        // Sort on name
        allTenants.data.listTenants.items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        // Return
        return allTenants.data.listTenants.items;
    }

    async isTenantAdmin(){
        if(this.isAdmin === null) {
            const session = await Auth.currentSession();
            try {
                let groups = session['accessToken']['payload']['cognito:groups'];
                this.isAdmin = (groups.indexOf('Admin') !== -1); // Enables the Admin UI functionality
            } catch (e) {
                console.log("Error looking at groups...");
                this.isAdmin = false;
            }
        }
        return this.isAdmin;
    }

    getTenant() {
        return {
            id: this.selectedTenantId,
            name: this.selectedTenantName
        }
    }

    getTenantId() {
        return this.selectedTenantId;
    }

    getTenantName() {
        return this.selectedTenantName;
    }

    setTenant(tenantId, tenantName) {
        this.selectedTenantId = tenantId;
        this.selectedTenantName = tenantName;
    }


}

// Initialise as a Singleton
let TM = new TenantManager();
export default TM;