import grapesjs from 'grapesjs';
import loadComponents from './components';
import loadBlocks from './blocks';

let blockRef = "ScheduleToggle";

export default grapesjs.plugins.add(`gjs-component-${blockRef}`, (editor, opts = {}) => {
    let c = opts;

    let defaults = {
        // Countdown class prefix
        blockRef: blockRef,
        label: 'Schedule Toggle',
        labelCategory: 'Setpoint Controls',
        labelIcon: 'fa fa-clock-o',
        datapointList: []
    };

    // Load defaults
    for (let name in defaults) {
        if (!(name in c))
            c[name] = defaults[name];
    }

    // Add components
    loadComponents(editor, c);

    // Add components
    loadBlocks(editor, c);

});
