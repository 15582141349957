import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "./graphql/mutations";
import uuidv4 from "uuid/v4";
import DatapointManager from "@/DatapointManager";
import DeploymentManager from "@/DeploymentManager";
import * as queries from "./graphql/queries";
import TenantManager from "./TenantManager";


class ScheduleManager {

    snackCb = null;

    setSnackCb(cb) {
        this.snackCb = cb;
    }

    addSnack(message, level) {
        if (this.snackCb !== null) {
            this.snackCb(message, level);
        }
    }

    async saveProfileTimeslots(profile, timeslots) {

        try {
            await API.graphql(graphqlOperation(mutations.updateScheduleProfile,
                {
                    input: {id: profile.id, tenantId: profile.tenantId, timeslots: timeslots}
                }));

            DatapointManager.getAllDatapoints(true);

            DeploymentManager.createDeployment();

            this.addSnack("Schedule profile saved.", "success");
        } catch (e) {
            this.addSnack("Unable to save schedule profile. Contact support.", "error");
            console.error(e);
        }
    }

    async updateActiveProfileId(schedule, id) {

        try {
            await API.graphql(graphqlOperation(mutations.updateSchedule,
                {
                    input: {id: schedule.id, tenantId: schedule.tenantId, scheduleActiveProfileId: id}
                }));
            DatapointManager.getAllDatapoints(true);
            this.addSnack("Active profile updated.", "success");
        } catch (e) {
            this.addSnack("Unable to update active profile. Contact support.", "error");
            console.error(e);
        }
    }

    async unlinkDatapoint(schedule, datapointId) {
        try {
            await API.graphql(graphqlOperation(mutations.updateDatapoint,
                {
                    input: {id: datapointId, tenantId: schedule.tenantId, datapointScheduleId: null}
                }));
            DatapointManager.getAllDatapoints(true);
            this.addSnack("Setpoint removed.", "success");
        } catch (e) {
            this.addSnack("Unable to remove setpoint. Contact support.", "error");
            console.error(e);
        }
    }

    async linkDatapoint(schedule, datapointId) {

        try {
            await API.graphql(graphqlOperation(mutations.updateDatapoint,
                {
                    input: {id: datapointId, tenantId: schedule.tenantId, datapointScheduleId: schedule.id}
                }));

            DatapointManager.getAllDatapoints(true);
            this.addSnack("Setpoint added.", "success");
        } catch (e) {
            this.addSnack("Unable to add setpoint. Contact support.", "error");
            console.error(e);
        }

    }

    async createProfile(schedule, name) {
        try {
            let timeslots = [{
                key: "1|0000",
                entries: schedule.datapoints.items.map((d) => {
                    return {
                        datapointId: d.id,
                        value: DatapointManager.hydrateDatapointWithHelpers(d).getDefaultSetpointValue(),
                    };
                })
            }];
            await API.graphql(graphqlOperation(mutations.createScheduleProfile,
                {
                    input: {
                        id: uuidv4(), tenantId: schedule.tenantId, name: name.trim(),
                        scheduleProfileScheduleId: schedule.id, timeslots: timeslots,
                        type: 'sevenday'
                    }
                }));

            DatapointManager.getAllDatapoints(true);
            this.addSnack("Schedule profile created.", "success");
        } catch (e) {
            this.addSnack("Unable to create schedule profile. Contact support.", "error");
            console.error(e);
        }
    }

    async deleteProfile(profile) {
        try {
            await API.graphql(graphqlOperation(mutations.deleteScheduleProfile,
                {
                    input: {id: profile.id, tenantId: profile.tenantId}
                }));

            DatapointManager.getAllDatapoints(true);
            this.addSnack("Profile removed.", "success");
        } catch (e) {
            this.addSnack("Unable to remove profile. Contact support.", "error");
            console.error(e);
        }


    }

    async deleteSchedule(schedule) {
        try {
            let operations = [];

            await API.graphql(graphqlOperation(mutations.deleteSchedule,
                {
                    input: {id: schedule.id, tenantId: schedule.tenantId}
                }));

            // Cleanup datapoints + Profiles
            schedule.datapoints.items.map(dp =>
                operations.push(
                    API.graphql(graphqlOperation(mutations.updateDatapoint,
                        {
                            input: {id: dp.id, tenantId: dp.tenantId, datapointScheduleId: null}
                        }))
                )
            );

            // Cleanup hanging profiles
            schedule.profiles.items.map(prof =>
                operations.push(
                    API.graphql(graphqlOperation(mutations.deleteScheduleProfile,
                        {
                            input: {id: prof.id, tenantId: prof.tenantId}
                        }))
                )
            );

            await Promise.all(operations);
            DatapointManager.getAllDatapoints(true);
            this.addSnack("Schedule removed.", "success");
        } catch (e) {
            this.addSnack("Unable to remove schedule. Contact support.", "error");
            console.error(e);
        }


    }

    async getScheduleById(id) {
        const query = await API.graphql(graphqlOperation(queries.getSchedule, {
            id: id,
            tenantId: TenantManager.getTenantId()
        }));
        return query.data.getSchedule;
    }

    async getAllSchedules() {

        // Fetch
        const allSchedules = await API.graphql(graphqlOperation(queries.listSchedules, {
            tenantId: TenantManager.getTenantId(),
            limit: 100
        }));

        // Sort on name
        allSchedules.data.listSchedules.items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

        // Hydrate
        this.datapoints = [];

        // Return
        return allSchedules.data.listSchedules.items;
    }

}

let sm = new ScheduleManager();
export default sm;
