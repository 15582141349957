import React from "react";
import {Typography} from "@material-ui/core";

interface IProps {
    selected: string
    setCb(display: string, seconds: number): undefined
}

interface IState {
}

class PeriodSelector extends React.Component<IProps, IState> {

    private options : {[id: string]: number} = {
        "1h": 3600,
        '3h': 3600 * 3,
        '12h': 3600 * 12,
        '1d': 3600 * 24,
        '3d': 3600 * 24 * 3,
        '1w': 3600 * 24 * 7,
        '2w': 3600 * 24 * 14,
    };

    render(){

        let display = Object.keys(this.options).map((o:string) => {
                if (o === this.props.selected) {
                    return <span key={o}><b>&nbsp;{o}&nbsp;</b></span>
                } else {
                    return <span key={o} style={{cursor: 'pointer'}} onClick={() => {
                        this.props.setCb(o, this.options[o])
                    }}>&nbsp;{o}&nbsp;</span>
            }
        });

        return (
            <div style={{float: "right"}}>
                <Typography variant="h6" >Time Selection: {display}</Typography>
            </div>
        )
    }

}


export default PeriodSelector;
