// Libraries
import React from 'react';
import Amplify from 'aws-amplify';
import {withAuthenticator} from 'aws-amplify-react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
// Page Components
import Template from '@/ui/template';
import Dashboard from '@/ui/Dashboard';
import Graphs from '@/ui/Graphs';
import Views from '@/ui/Views';
import Profile from '@/ui/Profile';
import Datapoints from '@/ui/Datapoints';
import Schedules from '@/ui/Schedules';
// Assets
import '@/App.css';
import 'semantic-ui-css/semantic.min.css'
// Classes
import awsconfig from '@/aws-exports';
import TenantManager from '@/TenantManager';
import FeedbackSnack from "./ui/FeedbackSnack";
import {withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import uuidv4 from "uuid/v4"
import QueryString from "query-string";
import {getGetObjectSignedUrl} from "@/AWSHelpers/s3";
import DatapointChangeManager from '@/DatapointChangeManager';
import DatapointManager from '@/DatapointManager';

// Considering you have an existing aws-exports.js configuration file
Amplify.configure(awsconfig);

// Setup the Tenant
// TenantManager.setTenant("fb8f21dd-f9b1-4e20-a140-859ca7833022", "Hello!");


const styles = theme => ({
    root: {
        padding: "5em",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"

    },
    progress: {
        margin: theme.spacing(2),
    },
    progressText: {
        paddingTop: "1em",
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
});


// Launch the Application
class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tenants: [],
            isAppReady: false,
            isMissingTenant: false,
            tenantLastUpdatedSec: null,
            tenantLastUpdatedStr: 'Unknown',
            tenant: {
                id: "",
                name: "Unknown Tenant",
            },
            cb: {
                setTenant: this.setTenant.bind(this),
                addSnack: this.addSnack.bind(this),
            },
            snacks: []
        };
    }

    updateTimeForTenant(){
        this.setState({
            tenantLastUpdatedSec: DatapointManager.getLatestReadUpdateInSeconds(),
            tenantLastUpdatedStr: DatapointManager.getLatestReadUpdateString(),
        })
    }

    componentDidMount() {
        // Get the tenants
        this.loadDefaultTenant();
        DatapointManager.setUpdateTimeCallback(this.updateTimeForTenant.bind(this))
    }

    removeTenantQueryFromUrl(){
        let qs = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });
        delete qs.tenantId;
        let fmt = QueryString.stringify(qs);
        if(fmt === '?') fmt = '';
        window.history.pushState(null, "", window.location.href.split("?")[0] + fmt);
    }

    async loadDefaultTenant() {
        let tenants = await TenantManager.listTenants();

        // URL SPECIFIED
        // Allow tenant switching via ?tenantId URL search parameter
        let urlTenantId = QueryString.parse(window.location.search, { ignoreQueryPrefix: true }).tenantId;
        if(urlTenantId !== this.state.tenant.id){
            // Find the tenant
            let tenant = tenants.find(t => t.id === urlTenantId);
            if (tenant) {
                this.setTenant(tenant);
                this.setState({...this.state, tenants: tenants, isAppReady: true});
                // Remove this from the query string to prevent confusion
                this.removeTenantQueryFromUrl();
                return;
            }
        }

        // LOCAL STORAGE
        // See if we already have an ID in local storage
        let selectedTenantId = localStorage.getItem("selectedTenantId") || null;
        if (selectedTenantId) {
            let tenant = tenants.find(t => t.id === selectedTenantId);
            if (tenant) {
                this.setTenant(tenant);
                this.setState({...this.state, tenants: tenants, isAppReady: true});
                return;
            }
        }

        // Otherwise find the first tenant to load, if possible
        if (tenants.length > 0) {
            this.setTenant(tenants[0]);
            this.setState({...this.state, tenants: tenants, isAppReady: true});
        } else {
            this.setState({...this.state, tenants: tenants, isAppReady: false, isMissingTenant: true});
        }


    }

    async setTenant(tenant) {
        // Set Tenant and reload data sources
        TenantManager.setTenant(tenant.id, tenant.name);
        this.setState({...this.state, tenant: {id: tenant.id, name: tenant.name}});
        DatapointManager.clearDatapoints();
        DatapointManager.getAllDatapoints(true).then(() => DatapointChangeManager.load());
        localStorage.setItem('selectedTenantId', tenant.id); // Save for page refreshes

        // See if we have custom PWA icons
        let origin = window.location.origin;
        let pwaIcon192 = origin + "/images/icons/icon-192x192.png";
        let pwaIcon512 = origin + "/images/icons/icon-512x512.png";
        if('customPwaIcons' in tenant && tenant['customPwaIcons'] === true){
            pwaIcon192 = await getGetObjectSignedUrl("tenant/" + tenant.id + "/pwa/icon-192x192.png", 86400);
            pwaIcon512 = await getGetObjectSignedUrl("tenant/" + tenant.id + "/pwa/icon-512x512.png", 86400);
        }

        // Force the given icon into the header for Apple Devices
        document.querySelector('#apple-touch-placeholder').setAttribute('href', pwaIcon512);

        // Setup the PWA
        let dynamicManifest = {
            "short_name": tenant.name,
            "name": tenant.name,
            "icons": [
            {
                "src": pwaIcon192,
                "sizes": "192x192",
                "type": "image/png"
            },
            {
                "src": pwaIcon512,
                "sizes": "512x512",
                "type": "image/png"
            }
        ],
            "start_url": origin + "/?tenantId=" + tenant.id,
            "display": "standalone",
            "theme_color": "#0057FE",
            "background_color": "#0057FE"
        };
        const stringManifest = JSON.stringify(dynamicManifest);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
    }

    addSnack(message, variant) {
        let id = uuidv4();
        this.setState({...this.state, snacks: [...this.state.snacks, {id, message, variant}]});
    }

    render() {
        let appProps = {
            cb: this.state.cb,
            tenant: this.state.tenant,
            onlineState: this.state.tenantLastUpdatedSec !== null && this.state.tenantLastUpdatedSec < 900 ? "ONLINE" : "OFFLINE",
            onlineTimestamp: this.state.tenantLastUpdatedStr,
            tenants: this.state.tenants,
            isAppReady: this.state.isAppReady
        };
        const classes = this.props.classes;
        let renderedSnacks = this.state.snacks.map((snack) =>
            <FeedbackSnack key={snack.id} {...snack} />
        );

        return (
            <Router>
                <Template {...appProps}>
                    <>
                        {!this.state.isAppReady &&
                        <div className={classes.root}>
                            <div><CircularProgress className={classes.progress} size={150}/></div>
                            <div><Typography variant="h2" className={classes.progressText}>Electair Connect</Typography>
                            </div>
                        </div>
                        }
                        {this.state.isMissingTenant &&
                        <div className={classes.root}>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                open={true}

                            >
                                <SnackbarContent className={classes.error}
                                                 message="No tenant configuration found. Please contact support to active your account."
                                />
                            </Snackbar>
                        </div>
                        }
                        {this.state.isAppReady &&
                        <Switch key={this.state.tenant.id}>
                            <Route exact path='/login'><Redirect to="/dashboard"/></Route>
                            {/*Add key, so on route change the Dashboard is reset (if a user clicks dashboard but is already on it.*/}
                            <Route exact path='/' render={(props) => <Dashboard {...props} {...appProps} key={new Date().getTime()} />}/>
                            <Route path='/dashboard' render={(props) => <Dashboard {...props} {...appProps} key={new Date().getTime()} />}/>
                            <Route exact path='/views/manager'
                                   render={(props) => <Views {...props} {...appProps} sub="manager"/>}/>
                            <Route path='/views/:viewId/edit'
                                   render={(props) => <Views {...props} {...appProps} sub="edit"/>}/>
                            <Route path='/views/:viewId'
                                   render={(props) => <Views {...props} {...appProps} sub="view"/>}/>
                            <Route exact path='/views'
                                   render={(props) => <Views {...props} {...appProps} sub="view"/>}/>
                            <Route path='/graphs' render={(props) => <Graphs {...props} {...appProps} />}/>
                            <Route path='/profile' render={(props) => <Profile {...props} {...appProps} />}/>
                            <Route path='/datapoints' render={(props) => <Datapoints {...props} {...appProps} />}/>
                            <Route path='/schedules' render={(props) => <Schedules {...props} {...appProps} />}/>
                        </Switch>
                        }
                        {renderedSnacks}
                    </>
                </Template>
            </Router>
        );
    }
}

export default withAuthenticator(withStyles(styles)(App));
