export default function(editor, opt = {}) {
    const c = opt;
    const bm = editor.BlockManager;
    const pfx = c.blockRef;

    // Add the block
    bm.add(opt.blockRef, {
        label: c.blockLabel,
        category: c.blockCategory,
        attributes: {class: c.blockIcons},
        content: `
            <button class="${pfx}" data-gjs-type="${c.blockRef}" data-gjs-name="${c.blockLabel}">
                ${c.defaultDisplayText}
            </button>
            <style>
            .${pfx} {
                background-color: #4736c3;
                border: none;
                color: white;
                padding: 10px 15px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 20px;
                cursor: pointer;
                margin-right: 10px;
                margin-top: 10px;
            }
            </style>
        `
    });

}
