export default function(editor, opt = {}) {
    const c = opt;
    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const blockRef = c.blockRef;

    // Setup the type
    domc.addType(blockRef, {

        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                // dmode: 'absolute',
                setPointId: null,
                minimumVal: '?',
                maximumVal: '?',
                droppable: false,
                traits: [{
                    label: 'Numeric Setpoint',
                    name: 'setpointid',
                    type: 'select',
                    changeProp: 0,
                    options: c.datapointList
                }, {
                    label: 'Min',
                    name: 'min',
                    type: 'readonlyNumber',
                    changeProp: 1,
                    tooltip: 'Fetched from the Datapoint facet configuration.'
                },
                {
                    label: 'Max',
                    name: 'max',
                    type: 'readonlyNumber',
                    changeProp: 1,
                    tooltip: 'Fetched from the Datapoint facet configuration.'
                },
                {
                    label: 'Step',
                    name: 'step',
                    type: 'readonlyNumber',
                    changeProp: 1,
                    tooltip: 'Fetched from the Datapoint facet configuration.'
                },
                {
                    label: 'Display Units',
                    name: 'displayunits',
                    type: 'checkbox',
                    changeProp: 0,
                },
                {
                    label: 'Units',
                    name: 'units',
                    type: 'readonlyNumber',
                    changeProp: 1,
                    tooltip: 'Fetched from the Datapoint facet configuration.'
                }
                ],
                blockRef: c.blockRef,
                script: function() {
                    // Set the HTML searchers
                    let unitSpan = this.querySelector('.{[ blockRef ]}-Units');
                    let inputBox = this.querySelector('.{[ blockRef ]}-NumberInput');

                    // Listen for modified changes from the parent IFrame
                    let handleExternalChange = (event) => {
                        if(event.data.hasOwnProperty('messageType') && event.data.messageType === 'DATAPOINT_UPDATE') {
                            // eslint-disable-next-line no-undef
                            let data = serialize.unserialize(event.data.data);
                            if(data.id === this.getAttribute("setpointid") && inputBox){
                                inputBox.value = data.getSetValue(); // Get value with no facets

                                // Attempt to get min/max facets if they are available
                                if(data.getFacet('min') !== null) inputBox.setAttribute('min', data.getFacet('min'));
                                if(data.getFacet('max') !== null) inputBox.setAttribute('max', data.getFacet('max'));
                                if(data.getFacet('step') !== null) inputBox.setAttribute('step', data.getFacet('step'));
                                if(data.getFacet('units') !== null && this.getAttribute("displayunits") === "true") {
                                    unitSpan.innerHTML = data.getFacet('units');
                                }else{
                                    unitSpan.innerHTML = '';
                                }
                            }
                        }
                    };

                    // Send changes back to React
                    let handleInputChange = (event) => {
                        let setpointId = this.getAttribute("setpointid");
                        let value = inputBox.value;

                        // Prepare payload
                        let data = {
                            messageType: 'DATAPOINT_SET',
                            // eslint-disable-next-line no-undef
                            data: serialize.serialize(
                                {
                                    id: setpointId,
                                    value: value
                                }
                            )
                        };

                        // Send a message to the parent iframe for processing
                        window.parent.postMessage(data, "*");
                    };


                    // Bind for form input changes and parent messages
                    this.addEventListener("input", handleInputChange, false);
                    window.addEventListener("message", handleExternalChange, false);
                }
            },
        }, {
            isComponent(el) {
                if(el.getAttribute &&
                    el.getAttribute('data-gjs-type') === blockRef) {
                    return {
                        type: blockRef
                    };
                }
            },
        }),


        view: defaultView.extend({
            init() {
                this.listenTo(this.model, 'change', (e)=> {
                    // Check in our setpoints if a min/max facet has been set
                    let spid = this.el.getAttribute('setpointid') || '';
                    let sp = c.setpoints.find(s => s.id === spid);
                    if(sp !== undefined){
                        let trait = this.model.get('traits').where({name: 'min'})[0];
                        if(trait && sp.getFacet('min')) trait.set('value', sp.getFacet('min'));

                        trait = this.model.get('traits').where({name: 'max'})[0];
                        if(trait && sp.getFacet('max')) trait.set('value', sp.getFacet('max'));

                        trait = this.model.get('traits').where({name: 'step'})[0];
                        if(trait && sp.getFacet('step')) trait.set('value', sp.getFacet('step'));

                        trait = this.model.get('traits').where({name: 'units'})[0];
                        if(trait && sp.getFacet('units')) trait.set('value', sp.getFacet('units'));
                    }

                    editor.TraitManager.getTraitsViewer().render();
                });
            },
            onRender() {
                editor.TraitManager.getTraitsViewer().render();

                let dmode = '';

                // If we are inside a Diagram Container, use absolute positioning
                let parent = this.model.parent();
                if(parent && parent.hasOwnProperty('attributes')){
                    if(parent.attributes.name === "Diagram Container"){
                        dmode = 'absolute';
                    }
                }

                this.model.set('dmode', dmode);
                // TODO: Reset attributes of component if coming from absolute -> ''.
            },
        }),
    });
}
