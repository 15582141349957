import React from 'react';
import LineChart from "./LineChart";
import queryString from 'query-string'
import moment  from 'moment';
import {ChartMetricStatisticValues, GetChartQuery} from '../../API';
import DatapointManager from '../../DatapointManager';

interface IProps {
    config: GetChartQuery['getChart']
    periodSeconds: number
}

interface IState {
    fetchedData: FetchedMetrics
}

export interface FetchedMetrics {
    [id: string]: {
        datapointId: string
        datapoints: Array<{
            sampleCount: number
            statisticValue: number
            unixTimestamp: number
        }>
        statistic: ChartMetricStatisticValues
        updatedTimestamp: number
    },
}


class ChartController extends React.Component<IProps, IState> {

    private datapointStatisticsFetchList: {
        [id: string] : {
            statistics: Array<ChartMetricStatisticValues>,
            legacyMetricId: string
            period: number
            datapointId: string
        };
    } = {};

    constructor(props: IProps) {
        super(props);
        // this.fetchHistoricData();
        this.state = {fetchedData: {}};
        // Setup workers
        this.setupFetchList().then(() => {
            this.fetchHistoricData();
        });

        setInterval(() => {
            this.fetchHistoricData();
        }, 30000)

    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        // Force refresh all the data if the period selector has been used
        if (prevProps.periodSeconds !== this.props.periodSeconds) {
            // Clear datasets so a refresh doesn't pick up old data
            this.setState({...this.state, fetchedData: {}});
            this.fetchHistoricData();
        }
    }

    async setupFetchList(){

        // make map for each metric datapointId and what metric is required (we may request multiple at once)
        if(this.props.config && this.props.config.metrics) {
            for (let metric of this.props.config.metrics) {

                // TODO: Remove this once the history is stored using UUID (History service change)
                // Here, we need to calaculate the legacy metric_id which is
                // SITE_DEV_REG, DEV_REG is stored with the datapoint
                if (metric.datapointId) {

                    // As the Datapoint Controller for this DP
                    let datapoint = await DatapointManager.getDatapointById(metric.datapointId);
                    if(datapoint) {
                        // Create the Metric ID
                        let metricId: string = datapoint.tenant.legacySiteId + "_" + datapoint.legacyRegisterId;

                        let existingStatistics: Array<ChartMetricStatisticValues> = [];
                        if(this.datapointStatisticsFetchList.hasOwnProperty(metric.datapointId + "_" + metric.period)){
                            existingStatistics = this.datapointStatisticsFetchList[metric.datapointId + "_" + metric.period]['statistics']
                        }

                        // Add to the records
                        this.datapointStatisticsFetchList[metric.datapointId + "_" + metric.period] = {
                            statistics: [...existingStatistics, metric.statistic],
                            legacyMetricId: metricId,
                            period: metric.period,
                            datapointId: metric.datapointId
                        }
                    }
                }
            }
        }
    }

    async fetchDatapointCall(id: string){

        let datapointDetails = this.datapointStatisticsFetchList[id];

        // If we are looking at weekly data, override period to be an hour
        let period:number = datapointDetails.period;
        if(this.props.periodSeconds >= (3600 * 24 * 7)){
            period = 1800; // Get data twice an hour
        }

        let params:object = {
            'metric_id': datapointDetails.legacyMetricId, // SITE_DEVICE_REGISTER
            'period': period,
            'statistics': datapointDetails.statistics.join(','),
            'start_time': moment().subtract(this.props.periodSeconds, "seconds").toISOString(),
            'end_time': moment().toISOString()
        };

        let response = await fetch(`https://api.electairconnect.co.uk/history/v1/statistics?${queryString.stringify(params)}`);
        let body = await response.json();

        // Add dataset to state
        let datapointId = datapointDetails.datapointId;
        let fetchDatapoints = this.state.fetchedData;

        for(let requestedStatistic of datapointDetails.statistics) {

            // Create a new fetch for each type of statistic
            let datapoints = []
            for(let fetchedDatapoint of body['datapoints']){
                if(fetchedDatapoint.hasOwnProperty(requestedStatistic)) {
                    datapoints.push({
                        sampleCount: fetchedDatapoint['sample_count'],
                        statisticValue: fetchedDatapoint[requestedStatistic],
                        unixTimestamp: fetchedDatapoint['unix_timestamp']
                    })
                }
            }

            // Put into the record
            fetchDatapoints[id + "_" + requestedStatistic] = {
                datapointId: datapointId,
                datapoints: datapoints,
                statistic: requestedStatistic,
                updatedTimestamp: moment.now()
            }
        }

        this.setState({ fetchedData: fetchDatapoints});

    }

    async fetchHistoricData(){
        for(let id in this.datapointStatisticsFetchList ){
            this.fetchDatapointCall(id)
        }
    }

    /**
     * Draws the LineChart, passing in datasets prop when the ChartController state changes
     */
    render(){
        // TODO: output all charts, send in relevant fetched data
        return (
            <LineChart config={this.props.config} datasets={this.state.fetchedData} periodSeconds={this.props.periodSeconds}/>
        )
    }

}

export default ChartController;
