export default function(editor, opt = {}) {
    const c = opt;
    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const blockRef = c.blockRef;

    domc.addType(blockRef, {

        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                blockRef: blockRef,
                droppable: false,
                traits: [{
                    label: 'Setpoint',
                    name: 'datapointid',
                    type: 'select',
                    changeProp: 0,
                    options: c.datapointList
                },
                {
                    label: 'Manual Selected BG',
                    name: 'manualbg',
                    type: 'color',
                    changeProp: 0,
                },
                {
                    label: 'Scheduled Selected BG',
                    name: 'scheduledbg',
                    type: 'color',
                    changeProp: 0,
                }
                ],
                script: function() {

                    let currentButtonState = 'manual';
                    let lastDatapoint = null;


                    let manualDiv = this.querySelector('.{[ blockRef ]}-Manual');
                    let scheduledDiv = this.querySelector('.{[ blockRef ]}-Scheduled');

                    let onManualClick = () => {
                        sendUpdate('manual');
                    };

                    let onScheduledClick = () => {
                        sendUpdate('scheduled');
                    };

                    let render = (useCurrentButtonState, forceUpdate) => {
                        if(lastDatapoint === null) return;
                        currentButtonState = lastDatapoint.getSetMode();

                        if(currentButtonState === 'manual'){
                            manualDiv.style.backgroundColor = this.getAttribute("manualbg") || null;
                            scheduledDiv.style.backgroundColor = null;
                        }else{
                            manualDiv.style.backgroundColor = null;
                            scheduledDiv.style.backgroundColor = this.getAttribute("scheduledbg") || null;
                        }
                    };

                    // Listen for modified changes from the parent IFrame
                    let handleExternalChange = (event) => {
                        if(event.data.hasOwnProperty('messageType') && event.data.messageType === 'DATAPOINT_UPDATE') {
                            // eslint-disable-next-line no-undef
                            let data = serialize.unserialize(event.data.data);
                            if(data.id === this.getAttribute("datapointid")) {
                                lastDatapoint = data;
                                render(false, lastDatapoint === null);
                            }
                        }
                    };

                    let sendUpdate = (type) => {
                      if(this.getAttribute("datapointid")){
                          // Send a message to change the state to the manager
                          let datapointId = this.getAttribute("datapointId");
                          currentButtonState = type;

                          // Prepare payload
                          let data = {
                              messageType: 'DATAPOINT_SCHEDULE_MODE_SET',
                              // eslint-disable-next-line no-undef
                              data: serialize.serialize(
                                  {
                                      id: datapointId,
                                      value: currentButtonState
                                  }
                              )
                          };

                          render(true);
                          // Send a message to the parent iframe for processing
                          window.parent.postMessage(data, "*");
                      }
                    };

                    // Bind
                    window.addEventListener("message", handleExternalChange, false);
                    manualDiv.addEventListener("click", onManualClick, false);
                    scheduledDiv.addEventListener("click", onScheduledClick, false);

                }
            },
        }, {
            isComponent(el) {
                if(el.getAttribute &&
                    el.getAttribute('data-gjs-type') === blockRef) {
                    return {
                        type: blockRef
                    };
                }
            },
        }),


        view: defaultView.extend({
            init() {

            },
            onRender() {
                let dmode = '';

                // If we are inside a Diagram Container, use absolute positioning
                let parent = this.model.parent();
                if(parent && parent.hasOwnProperty('attributes')){
                    if(parent.attributes.name === "Diagram Container"){
                        dmode = 'absolute';
                    }
                }

                // console.log(dmode)
                this.model.set('dmode', dmode);
                // TODO: Reset attributes of component if coming from absolute -> ''.
            },
        }),
    });
}
