export default function(editor, opt = {}) {
    const c = opt;
    const bm = editor.BlockManager;
    const pfx = c.blockRef;

    bm.add(c.blockRef, {
        label: c.label,
        category: c.labelCategory,
        attributes: {class: c.labelIcon},
        content: `
        <select class="${pfx}" data-gjs-type="${c.blockRef}">
          <option value="">Options</option>
        </select>
        <style> 
            .${pfx} {
                    font-family: Arial, Helvetica, sans-serif;
                    display: inline-block;
                    cursor: pointer;
                    outline: 0;
                    border: 1px solid #000000;
                    border-radius: 6px;
                    padding: 10px;
                    background: #faf8f8;
                    color: #080808;
                }
            
      </style>
      `
    });

}
