export default function(editor, opt = {}) {
    const c = opt;

    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;

    // Setup the type
    domc.addType(c.blockRef, {

        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                // dmode: 'absolute',
                droppable: false,
                traits: [{
                    label: 'Always Active',
                    name: 'alwaysActive',
                    type: 'checkbox',
                    changeProp: 1,
                }],
                disabledClsName: c.cls.buttonDisabledCls,
                script: function() {

                    let isDirty = false;    // When we load, assume we are not dirty

                    // Check property of always active
                    let isAlwaysActive = () => {
                        let v = this.getAttribute('alwaysactive');
                        if(!v) v = "false";
                        return v === "true";
                    };

                    // If !alwaysActive, reset dirtyflag and disable
                    let setDirty = () => {
                        isDirty = true;
                        // enable button
                        this.classList.remove('{[ disabledClsName ]}')
                    }

                    // Clear the dirty flag and reset the button
                    let clearDirty = () => {
                        if(isAlwaysActive()){
                            isDirty = true;
                        }else {
                            isDirty = false;
                            // disable button
                            this.classList.add('{[ disabledClsName ]}')
                        }
                    }

                    // Listen for changes to setpoints (indicating we need to Apply changes)
                    let handleExternalChange = (event) => {
                        if(event.data.hasOwnProperty('messageType') && event.data.messageType === 'DIRTY_SET') {
                            setDirty();
                        }
                        else if(event.data.hasOwnProperty('messageType') && event.data.messageType === 'DIRTY_CLEAR') {
                            clearDirty();
                        }
                    };


                    // Send changes back to React
                    let handleButtonPress = (event) => {
                        if(isDirty){
                            // Send request to React
                            let data = {messageType: 'APPLY_CHANGES'};
                            window.parent.postMessage(data, "*");
                        }
                        // Clear Dirty Flag
                        clearDirty();
                    };


                    // On load, check if we need to enable the button
                    if(isAlwaysActive()){
                        clearDirty();
                    }

                    // Bind for form input changes
                    this.addEventListener("click", handleButtonPress, false);

                    // Bind for external changes
                    window.addEventListener("message", handleExternalChange, false);

                }
            },
        }, {
            isComponent(el) {
                if(el.getAttribute &&
                    el.getAttribute('data-gjs-type') === c.blockRef) {
                    return {
                        type: c.blockRef
                    };
                }
            },
        }),


        view: defaultView.extend({
            init() {
                this.listenTo(this.model, 'change', (e)=> {
                    this.setCustomHTMLPropAttributes();
                    if(this.model.get('alwaysActive') === true){
                        this.el.classList.remove(c.cls.buttonDisabledCls);
                    }
                });

                // Force on view init()
                this.setCustomHTMLPropAttributes();
            },
            setCustomHTMLPropAttributes: function() {
                this.el.setAttribute('alwaysActive', this.model.get('alwaysActive'))
            },
            onRender() {
                // Ensure HTML properties are present for the consumer JavaScript
                this.setCustomHTMLPropAttributes();

                // Check if the property defined alwaysActive and set class accordingly



                let dmode = '';

                // If we are inside a Diagram Container, use absolute positioning
                let parent = this.model.parent();
                if(parent && parent.hasOwnProperty('attributes')){
                    if(parent.attributes.name === "Diagram Container"){
                        dmode = 'absolute';
                    }
                }

                this.model.set('dmode', dmode);
                // TODO: Reset attributes of component if coming from absolute -> ''.
            },
        }),
    });
}
