import React from "react";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ScheduleIcon from "@material-ui/icons/Schedule";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Link as RouterLink} from "react-router-dom";

import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import WeekEditorDialogFull from "./Schedule/WeekEditorDialogFull";
import DatapointManager from "@/DatapointManager";
import MenuItem from "@material-ui/core/MenuItem";
import ScheduleManager from "@/ScheduleManager";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    card: {
        maxWidth: 345,
        minWidth: 290
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    inlineSelectField: {
        width: 150,
    },
});


class ScheduleItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            editMode: false,
            addDialogSelectedId: "",
            addSetpointDialogOpen: false,
            scheduleEditorOpen: false,
            scheduleEditorProfile: null,
            addProfileDialogName: "",
            addProfileDialogOpen: false,
            selectedActiveProfile: "",
        }

        ScheduleManager.setSnackCb(this.props.addSnack);
        this.scheduleEditorEditedTimeslots = [];
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            selectedActiveProfile: this.props.schedule.activeProfile.id
        })
    }

    async onSaveScheduleButton() {
        if (!this.state.editMode) {
            // Go to edit more
            this.setState({...this.state, editMode: true});
        } else {
            if (this.state.selectedActiveProfile !== this.props.schedule.activeProfile.id) {
                await ScheduleManager.updateActiveProfileId(this.props.schedule, this.state.selectedActiveProfile)
                this.props.reloadSchedules();
            }

            this.setState({...this.state, editMode: false});
        }
    }

    async onLinkSetpoint() {
        await ScheduleManager.linkDatapoint(this.props.schedule, this.state.addDialogSelectedId);
        this.props.reloadSchedules();

        this.setState({...this.state, addSetpointDialogOpen: false, addDialogSelectedId: ""});
    }

    createDatapointItem(dp) {
        return (
            <p key={dp.id}>
                <RouterLink to="/datapoints">{dp.name}</RouterLink>
                {this.state.editMode &&
                <IconButton onClick={async () => {
                    await ScheduleManager.unlinkDatapoint(this.props.schedule, dp.id);
                    this.props.reloadSchedules()
                }}>
                    <Tooltip title={"Remove " + dp.name}>
                        <DeleteIcon fontSize="small"/>
                    </Tooltip>
                </IconButton>}
            </p>
        )
    }

    createProfileItem(prof, schedule) {
        return (
            <p key={prof.id}>
                {prof.name}
                {this.state.editMode && prof.id !== schedule.activeProfile.id &&
                <IconButton onClick={async () => {
                    await ScheduleManager.deleteProfile(prof);
                    this.props.reloadSchedules()
                }}>
                    <Tooltip title={"Remove " + prof.name}>
                        <DeleteIcon fontSize="small"/>
                    </Tooltip>
                </IconButton>}
                <IconButton onClick={() => this.openScheduleEditor(prof)}>
                    <Tooltip title={"Edit " + prof.name}>
                        <ScheduleIcon fontSize="small"/>
                    </Tooltip>
                </IconButton>
            </p>
        )
    }

    getEditorDialog(schedule) {
        let profile = this.state.scheduleEditorProfile;
        let datapoints = schedule.datapoints.items.map(d => DatapointManager.hydrateDatapointWithHelpers(d))

        return (<WeekEditorDialogFull
            timeslots={profile.timeslots}
            updateScheduleTimeslots={(x) => this.scheduleEditorEditedTimeslots = x}
            setpoints={datapoints}
            onClose={() => this.closeScheduleEditor()}
            onSave={() => this.saveScheduleEditor()}
            title={schedule.name + " > " + profile.name}
            open={true}
            fullscreen
        />)
    }

    onAddDialogSelect(e) {
        this.setState({...this.state, addDialogSelectedId: e.target.value})
    }

    openScheduleEditor(profile) {
        this.setState({...this.state, scheduleEditorOpen: true, scheduleEditorProfile: profile});
    }

    closeScheduleEditor() {
        this.setState({...this.state, scheduleEditorOpen: false, scheduleEditorProfile: null});
        this.scheduleEditorEditedTimeslots = [];
    }

    async saveScheduleEditor() {
        // Update the profile (via props)
        await ScheduleManager.saveProfileTimeslots(this.state.scheduleEditorProfile, this.scheduleEditorEditedTimeslots);
        this.props.reloadSchedules();

        this.setState({...this.state, scheduleEditorOpen: false, scheduleEditorProfile: null});
        this.scheduleEditorEditedTimeslots = []
    }

    render() {
        const classes = this.props.classes;
        const schedule = this.props.schedule;

        const datapointLength = (schedule.datapoints.items) ? schedule.datapoints.items.length : 0;

        return (
            <>
                <Card className={classes.card}>
                    <CardHeader
                        action={
                            <>
                                <IconButton aria-label="settings"
                                            onClick={() => this.openScheduleEditor(schedule.activeProfile)}>
                                    <Tooltip title="Modify Active Schedule">
                                        <ScheduleIcon/>
                                    </Tooltip>
                                </IconButton>
                                {this.state.editMode &&
                                <IconButton aria-label="settings" onClick={async () => {
                                    await ScheduleManager.deleteSchedule(this.props.schedule);
                                    this.props.reloadSchedules()
                                }}>
                                    <Tooltip title="Delete Schedule">
                                        <DeleteIcon/>
                                    </Tooltip>
                                </IconButton>
                                }
                                <IconButton aria-label="settings" onClick={() => this.onSaveScheduleButton()}>
                                    {!this.state.editMode && <Tooltip title="Edit Schedule"><EditIcon/></Tooltip>}
                                    {this.state.editMode && <Tooltip title="Save Schedule"><SaveIcon/></Tooltip>}
                                </IconButton>
                            </>
                        }
                        title={schedule.name}
                    />
                    <Divider/>
                    <CardContent>

                        <Table className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">Active Profile</TableCell>
                                    <TableCell>
                                        {!this.state.editMode && schedule.activeProfile.name}
                                        {this.state.editMode &&
                                        <TextField
                                            select
                                            label="Profile"
                                            className={classes.inlineSelectField}
                                            value={this.state.selectedActiveProfile}
                                            onChange={(e) => this.setState({
                                                ...this.state,
                                                selectedActiveProfile: e.target.value
                                            })}
                                        >
                                            {schedule.profiles.items.map(p => (
                                                <MenuItem key={p.id} value={p.id}>
                                                    {p.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        }

                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Controlled Setpoints</TableCell>
                                    <TableCell>{datapointLength}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>


                    </CardContent>
                    <CardActions disableSpacing>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: this.state.expanded,
                            })}
                            onClick={() => this.setState({...this.state, expanded: !this.state.expanded})}
                            aria-expanded={this.state.expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon/>
                        </IconButton>
                    </CardActions>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <Divider/>
                        <CardContent>

                            <Typography variant="button">
                                Setpoints
                                {this.state.editMode &&
                                <IconButton onClick={() => this.setState({...this.state, addSetpointDialogOpen: true})}>
                                    <Tooltip title="Add setpoint">
                                        <AddIcon fontSize="small"/>
                                    </Tooltip>
                                </IconButton>
                                }
                            </Typography>
                            {schedule.datapoints.items.map(dp => this.createDatapointItem(dp))}
                            {schedule.datapoints.items.length === 0 && <p>No setpoints attached.</p>}


                            <Typography variant="button">
                                Profiles
                                {this.state.editMode &&
                                <IconButton onClick={() => this.setState({...this.state, addProfileDialogOpen: true})}>
                                    <Tooltip title="Add profile">
                                        <AddIcon fontSize="small"/>
                                    </Tooltip>
                                </IconButton>
                                }
                            </Typography>
                            {schedule.profiles.items.map(prof => this.createProfileItem(prof, schedule))}
                            {schedule.profiles.items.length === 0 && <p>No profiles present.</p>}

                        </CardContent>
                    </Collapse>
                </Card>


                <Dialog open={this.state.addSetpointDialogOpen}
                        onClose={() => this.setState({...this.state, addSetpointDialogOpen: false})}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Setpoint Selection</DialogTitle>
                    <DialogContent>
                        {this.props.availableSetpoints.length !== 0 &&
                        <>
                            <DialogContentText>
                                Select a setpoint to add this to schedule.
                            </DialogContentText>


                            <TextField
                                select
                                label="Setpoint"
                                className={classes.textField}
                                value={this.state.addDialogSelectedId}
                                onChange={this.onAddDialogSelect.bind(this)}
                            >
                                {this.props.availableSetpoints.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </>
                        }
                        {this.props.availableSetpoints.length === 0 &&
                        <p className={classes.paddedText}>There are no setpoints available. Detach some from other
                            schedules.</p>}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({...this.state, addSetpointDialogOpen: false})}
                                color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.onLinkSetpoint()} color="primary"
                                disabled={this.state.addDialogSelectedId === ""}>
                            Add Setpoint
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.addProfileDialogOpen}
                        onClose={() => this.setState({...this.state, addProfileDialogOpen: false})}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add schedule profile</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Name"
                            className={classes.textField}
                            value={this.state.addProfileDialogName}
                            onChange={(e) => this.setState({...this.state, addProfileDialogName: e.target.value})}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({...this.state, addProfileDialogOpen: false})}
                                color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.createProfile()} color="primary"
                                disabled={!(/^[a-zA-Z]{1}[a-zA-Z0-9 ]+$/.test(this.state.addProfileDialogName))}>
                            Add profile
                        </Button>
                    </DialogActions>
                </Dialog>

                {this.state.scheduleEditorOpen && this.getEditorDialog(schedule)}
            </>

        )
    }

    async createProfile() {
        await ScheduleManager.createProfile(this.props.schedule, this.state.addProfileDialogName);
        this.props.reloadSchedules();
        this.setState({...this.state, addProfileDialogName: "", addProfileDialogOpen: false})
    }

}

export default withStyles(styles)(ScheduleItem);
