export default function(editor, opt = {}) {
    const c = opt;
    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const blockRef = c.blockRef;

    domc.addType(blockRef, {

        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                blockRef: blockRef,
                droppable: false,
                traits: [{
                    label: 'Setpoint',
                    name: 'datapointid',
                    type: 'select',
                    changeProp: 0,
                    options: c.datapointList
                }
                ],
                script: function() {

                    let currentOptions = [];

                    // Listen for modified changes from the parent IFrame
                    let handleExternalChange = (event) => {
                        if(event.data.hasOwnProperty('messageType') && event.data.messageType === 'DATAPOINT_UPDATE') {
                            // eslint-disable-next-line no-undef
                            let data = serialize.unserialize(event.data.data);
                            if(data.id === this.getAttribute("datapointid")) {

                                // Attempt to select the given setValue
                                let e = data.getEnumMap();
                                if(!e || e.length === 0){
                                    this.options.length = 0;
                                    currentOptions = [];
                                }else{
                                    if(JSON.stringify(currentOptions) !== JSON.stringify(e)) {
                                        // Populate the options if they haven't changed
                                        this.options.length = 0;
                                        let numShown = this.options.length;
                                        for (let i = 0; i < e.length; i++) {
                                            this.options[numShown] = new Option(e[i].display, e[i].value);
                                            numShown++;
                                        }
                                        currentOptions = e;
                                    }

                                    // Select the current set value
                                    if(this.value !== data.getSetValue()){
                                        this.value = data.getSetValue();
                                    }

                                }
                            }
                        }
                    };

                    let onSelect = (event) => {
                      if(this.getAttribute("datapointid")){
                          // Send a message to change the state to the manager
                          let datapointId = this.getAttribute("datapointId");
                          let currentSelectState = this.value;

                          // Prepare payload
                          let data = {
                              messageType: 'DATAPOINT_SET',
                              // eslint-disable-next-line no-undef
                              data: serialize.serialize(
                                  {
                                      id: datapointId,
                                      value: currentSelectState
                                  }
                              )
                          };

                          // Send a message to the parent iframe for processing
                          window.parent.postMessage(data, "*");
                      }
                    };

                    // Bind
                    window.addEventListener("message", handleExternalChange, false);
                    this.addEventListener("change", onSelect, false);
                }
            },
        }, {
            isComponent(el) {
                if(el.getAttribute &&
                    el.getAttribute('data-gjs-type') === blockRef) {
                    return {
                        type: blockRef
                    };
                }
            },
        }),


        view: defaultView.extend({
            init() {
            },
            onRender() {
                let dmode = '';

                // If we are inside a Diagram Container, use absolute positioning
                let parent = this.model.parent();
                if(parent && parent.hasOwnProperty('attributes')){
                    if(parent.attributes.name === "Diagram Container"){
                        dmode = 'absolute';
                    }
                }

                // console.log(dmode)
                this.model.set('dmode', dmode);
                // TODO: Reset attributes of component if coming from absolute -> ''.
            },
        }),
    });
}
