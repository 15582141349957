import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import GEditor from "@/grapejs/GEditor"
import {getObjectContents} from '@/AWSHelpers/s3';
import ViewToolbar from "./ViewToolbar";
import {Link as RouterLink} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ViewListIcon from "@material-ui/icons/ViewList";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Container from "@material-ui/core/Container";
import DatapointManager from "@/DatapointManager";
import serialize from '@/Serialize';
import DatapointChangeManager from "@/DatapointChangeManager";
import WeekEditorDialogFull from "./Schedule/WeekEditorDialogFull";
import ScheduleManager from "@/ScheduleManager";
import TenantManager from "@/TenantManager";
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../graphql/mutations";
import moment from "moment";
import uuidv4 from 'uuid/v4';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    iframeContainer: {
        border: "none",
        height: "100vh",        /* Viewport-relative units */
        width: "100%",
    },
    button: {
        margin: theme.spacing(1),
    },
    loadingRoot: {
        marginTop: "20px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paddedText: {
        padding: "20px",
    }
});


class ViewGrapesHandler extends React.Component {

    constructor(props) {
        super(props)

        this.viewIframeRef = React.createRef();
        this.editIframeRef = null;

        this.state = {
            isLoadingIframe: true,
            displayNoConfigError: false,
            scheduleEditorOpen: false,
            scheduleEditorDatapoint: null,
            scheduleEditorSchedule: null,
            userIsAdmin: false,
            hasInjectedData: false
        };
        this.editor = null;
        this.scheduleEditorEditedTimeslots = [];

        ScheduleManager.setSnackCb(this.props.addSnack);
    }

    async componentDidMount() {
        this.updateIframe();
        this.setState({...this.state, userIsAdmin: await TenantManager.isTenantAdmin()});

        setInterval(
            () => this.postDataPoints(),
            500 // TODO Keep this fast in edit mode, maybe be nicer is display mode
        );

        DatapointChangeManager.setDirtyChangesIgnoredCb(() => {
            this.props.addSnack("Cloud updated, your changes will overwrite them.", "info");
        });

        window.addEventListener("message", this.handleFrameTasks, false);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleFrameTasks, false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedView !== this.props.selectedView || this.props.mode !== prevProps.mode) {
            this.updateIframe();
        }

        if (prevProps.mode === 'view' && this.props.mode === 'edit') {
            this.setState({...this.state, isLoadingIframe: true})
        }
    }

    async updateIframe() {
        if (this.props.mode === "view" && this.props.selectedView.name !== "") {
            if (this.props.selectedView.configuration) {
                this.setState({...this.state, isLoadingIframe: true, displayNoConfigError: false});
                let data = await getObjectContents(this.props.selectedView.configuration);
                this.setState({...this.state, isLoadingIframe: false, displayNoConfigError: false});

                if (this.getIframeRef() === null || this.viewIframeRef.current === null) return; // config/iframe render isn't ready yet

                const iframe = this.viewIframeRef.current;
                const document = iframe.contentDocument;

                document.body.innerHTML = '';

                let jsonObject = JSON.parse(data);

                // Add template HTML
                let templateHtml = jsonObject['html'] || '';
                let templateStyle = jsonObject['css'] || '';

                let docHtml = `
                    <html>
                    <head>
                            <script src="https://kit.fontawesome.com/8cec15323a.js"></script>
                              <script>
                              var head = document.getElementsByTagName('head')[0];
                              var r = document.createElement('script')
                              r.setAttribute("type","text/javascript")
                              r.setAttribute("src", parent.document.location.origin + "/serialize.js")
                              head.appendChild(r);
                              </script>
                        <style>
                            ${templateStyle}
                        </style>
                    </head>
                    <body>
                    ${templateHtml}
                    </body>
                    </html>
                
                `
                const blob = new Blob([docHtml], {type: 'text/html'});
                iframe.src = URL.createObjectURL(blob);

                this.postDataPoints();

            } else {
                // User hasn't setup a view yet
                this.setState({...this.state, isLoadingIframe: false, displayNoConfigError: true});
            }
        }
    }

    setLoadingComplete() {
        this.setState({...this.state, isLoadingIframe: false, displayNoConfigError: false})
    }


    /**
     * Process frame messages from the child IFrame, so we can send and receive datapoint
     * updates.
     * @param e
     * @returns {Promise<void>}
     */
    handleFrameTasks = async (e) => {
        if (e.data.hasOwnProperty('messageType')) {
            let payload = null;
            if (e.data.data) payload = serialize.unserialize(e.data.data);
            let editMode = this.props.mode === 'edit';

            // Button presses to change a setpoint by an amount
            if (e.data.messageType === "DATAPOINT_CHANGEBY") {
                // Find the datapoint at this time
                let dcm = DatapointChangeManager.get(payload.id);
                if (dcm === null) return;

                // Update the modified value for the DCM by reference
                dcm.changeSetValue(parseFloat(dcm.getSetValue()) + parseFloat(payload.value));
                dcm.changeSetMode('manual');

                this.postDataPoints();
            }

            // Input fields to set to a specific amount
            if (e.data.messageType === "DATAPOINT_SET") {
                // Find the datapoint at this time
                let dcm = DatapointChangeManager.get(payload.id);
                if (dcm === null) return;

                // Update the modified value for the DCM by reference
                dcm.changeSetMode('manual');
                if (dcm.getType() === 'boolean' || dcm.getType() === 'enum') {
                    dcm.changeSetValue(payload.value);
                } else {
                    dcm.changeSetValue(parseFloat(payload.value));
                }

                this.postDataPoints();
            }

            // Input fields to set to a specific amount
            if (e.data.messageType === "DATAPOINT_SCHEDULE_MODE_SET") {
                // Find the datapoint at this time
                let dcm = DatapointChangeManager.get(payload.id);
                if (dcm === null) return;

                // Update the set mode for the DCM by reference
                dcm.changeSetMode(payload.value);

                this.postDataPoints();
            }

            // Apply changes
            if (e.data.messageType === "APPLY_CHANGES") {
                // DCM contains a modified set of values
                DatapointChangeManager.save(this.saveSuccessCb, this.saveFailureCb);
            }

            // Open Setpoint Specific Schedule
            if (e.data.messageType === "OPEN_SCHEDULER") {
                if (payload.value === null) return;
                if (editMode) {
                    this.props.addSnack("Page will launch scheduler when viewed.", "info");
                } else {
                    this.openScheduleEditor(payload.value);
                }
            }

            // Apply changes
            if (e.data.messageType === "CHANGE_PAGE") {
                if (payload.value === null) return;
                let target = payload.value.split("|")[0];

                if (editMode) {
                    switch (target) {
                        case "SCHEDULES":
                            this.props.addSnack("Page will navigate to Schedules page when viewed.", "info");
                            break;
                        case "GRAPHS":
                            this.props.addSnack("Page will navigate to Graphs page when viewed.", "info");
                            break;
                        case "VIEW":
                            this.props.addSnack("Page will navigate to the specified View when viewed.", "info");
                            break;
                    }
                } else {
                    switch (target) {
                        case "SCHEDULES":
                            this.props.history.push("/schedules");
                            break;
                        case "GRAPHS":
                            this.props.history.push("/graphs");
                            break;
                        case "VIEW":
                            this.props.changeViewCb(payload.value.split("|")[1]);
                            break;
                    }
                }
            }


            // Dirty Broadcast if required
            if (DatapointChangeManager.isDirty()) {
                this.sendIframeMessage({messageType: 'DIRTY_SET'});
            } else {
                this.sendIframeMessage({messageType: 'DIRTY_CLEAR'});
            }
        }
    };

    saveSuccessCb = async () => {
        this.props.addSnack("Changes saved to the cloud! Deploying...", "success");
        this.sendIframeMessage({messageType: 'DIRTY_CLEAR'});

        // Perform a deployment
        try {
            await API.graphql(graphqlOperation(mutations.createDeployment,
                {
                    input: {
                        id: uuidv4(), tenantId: TenantManager.getTenantId(),
                        type: 'setpoints', timeRequested: moment().toISOString()
                    }
                }));
        } catch (e){
            this.props.addSnack("Error creating deployment.", "error");
            this.sendIframeMessage({messageType: 'DIRTY_SET'});
        }


    };

    saveFailureCb = () => {
        this.props.addSnack("An error occurred saving your changes.", "error");
    };

    /**
     * This is called periodically, or by an ASync function when values are receieved
     */
    async postDataPoints() {
        if (this.isIframeReady()) {

            // Get datapoints
            let datapoints = await DatapointChangeManager.getAllModified();
            datapoints.map(d => {
                this.sendIframeMessage({
                    messageType: 'DATAPOINT_UPDATE',
                    data: serialize.serialize(d)
                })
            });

            if(!this.state.hasInjectedData){
                this.setState({...this.state, hasInjectedData: true});
            }

        }
    }


    setIframeRef(r) {
        this.editIframeRef = null;
        this.editIframeRef = {
            current: {
                contentWindow: r
            }
        };
    }

    getIframeRef() {
        if (this.props.mode === 'edit') {
            return this.editIframeRef;
        } else {
            return this.viewIframeRef;
        }
    }

    isIframeReady() {
        if (this.props.mode === 'edit') {
            return (this.editIframeRef && this.editIframeRef.current && this.editIframeRef.current.contentWindow);
        } else {
            return (this.viewIframeRef && this.viewIframeRef.current && this.viewIframeRef.current.contentWindow);
        }
    }

    sendIframeMessage(data) {
        if (this.isIframeReady()) {
            this.getIframeRef().current.contentWindow.postMessage(data, "*");
        }
    }

    // Schedules
    async openScheduleEditor(value) {
        // We will either have DATAPOINT|ID, or SCHEDULE|ID
        let parts = value.split("|");

        if(parts[0] === 'DATAPOINT'){
            // Get the active schedule profile
            let datapoint = await DatapointManager.getDatapointById(parts[1]);

            if (datapoint && datapoint.isScheduleAvailable()) {
                this.setState({
                    ...this.state,
                    scheduleEditorOpen: true,
                    scheduleEditorDatapoint: datapoint,
                    scheduleEditorSchedule: null
                });
                return;
            }

        }else{

            // Try open a schedule
            let schedule = await ScheduleManager.getScheduleById(parts[1]);

            if (schedule) {
                this.setState({
                    ...this.state,
                    scheduleEditorOpen: true,
                    scheduleEditorDatapoint: null,
                    scheduleEditorSchedule: schedule
                });
                return;
            }

        }


        this.props.addSnack("Could not find schedule", "error")

    }

    closeScheduleEditor() {
        this.setState({...this.state, scheduleEditorOpen: false, scheduleEditorDatapoint: null, scheduleEditorSchedule: null});
        this.scheduleEditorEditedTimeslots = [];
    }

    async saveScheduleEditor() {
        if(this.state.scheduleEditorDatapoint) {
            await ScheduleManager.saveProfileTimeslots(
                this.state.scheduleEditorDatapoint.schedule.activeProfile,
                this.scheduleEditorEditedTimeslots
            );
        }else{
            await ScheduleManager.saveProfileTimeslots(
                this.state.scheduleEditorSchedule.activeProfile,
                this.scheduleEditorEditedTimeslots
            );
        }
        DatapointChangeManager.save(this.saveSuccessCb, this.saveFailureCb);
        this.setState({...this.state, scheduleEditorOpen: false, scheduleEditorDatapoint: null, scheduleEditorSchedule: null});
        this.scheduleEditorEditedTimeslots = []
    }

    getEditorDialog() {

        let schedule;
        let title;
        let extraProps = {};

        if(this.state.scheduleEditorDatapoint) {
            schedule = this.state.scheduleEditorDatapoint.schedule;
            title = this.state.scheduleEditorDatapoint.name;
            extraProps = {
                singleDatapointId: this.state.scheduleEditorDatapoint.id,
                singleDatapointMode: true
            }
        }else{
            schedule = this.state.scheduleEditorSchedule;
            title = schedule.name;
        }

        let profile = schedule.activeProfile;
        let datapoints = schedule.datapoints.items.map(d => DatapointManager.hydrateDatapointWithHelpers(d))

        return (<WeekEditorDialogFull
            timeslots={profile.timeslots}
            updateScheduleTimeslots={(x) => this.scheduleEditorEditedTimeslots = x}
            setpoints={datapoints}
            onClose={() => this.closeScheduleEditor()}
            onSave={() => this.saveScheduleEditor()}
            title={title}
            open={true}
            {...extraProps}
            fullscreen
        />)
    }


    render() {
        const classes = this.props.classes;
        let mode = this.props.mode;
        let fullscreen = this.props.fullscreen;

        let iframeStyle = (this.state.hasInjectedData) ? {display: "block"} : {display: "none"};

        if (mode === "edit") {
            return (
                <div>
                    <GEditor
                        setLoadingComplete={this.setLoadingComplete.bind(this)}
                        editor={this.editor}
                        selectedView={this.props.selectedView}
                        views={this.props.views}
                        loader={this.state.isLoadingIframe}
                        history={this.props.history}
                        addSnack={this.props.addSnack}
                        setIframeRef={this.setIframeRef.bind(this)}
                        reloadViews={this.props.reloadViews}
                    />
                </div>
            )
        } else { // IS BEING VIEWED
            return (
                <div>
                    {!fullscreen &&
                    <>
                        <Container>
                            <ViewToolbar views={this.props.views} selectedView={this.props.selectedView}
                                         isLoading={this.state.isLoadingIframe}>
                                {this.state.userIsAdmin &&
                                    <Tooltip title="Edit View">
                                        <IconButton component={RouterLink}
                                                    to={"/views/" + this.props.selectedView.id + "/edit"}>
                                            <EditIcon/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip title="View Manager">
                                    <IconButton component={RouterLink} to={"/views/manager"}>
                                        <ViewListIcon/>
                                    </IconButton>
                                </Tooltip>
                            </ViewToolbar>
                        </Container>
                    </>
                    }

                    {this.state.displayNoConfigError && <Typography class={classes.paddedText}>
                        Click the edit icon above to setup this view.
                    </Typography>}

                    {!this.state.displayNoConfigError &&
                    <iframe ref={this.viewIframeRef} className={classes.iframeContainer} style={iframeStyle}/>
                    }

                    {this.state.scheduleEditorOpen && this.getEditorDialog()}
                </div>
            )
        }

    }

}

export default withStyles(styles)(ViewGrapesHandler);
