export default function(editor, opt = {}) {
    const c = opt;
    const bm = editor.BlockManager;
    const pfx = c.blockRef;

    bm.add(c.blockRef, {
        label: c.label,
        category: c.labelCategory,
        attributes: {class: c.labelIcon},
        content: `
        <div class="${pfx}" data-gjs-type="${c.blockRef}" scheduledbg="#379e18" manualbg="#eba434">
            <div data-gjs-selectable="false" data-gjs-highlightable="false" class="${pfx}-Manual ${pfx}-Item">Manual</div>
            <div data-gjs-selectable="false" data-gjs-highlightable="false" class="${pfx}-Scheduled ${pfx}-Item">Scheduled</div>
        </div>
        <style>
            .${pfx} {
              display: flex;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 18px;
            }
            
            .${pfx}-Item {
              background-color: #f1f1f1;
              cursor: pointer;
              padding: 15px; 
              -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                 -khtml-user-select: none; /* Konqueror HTML */
                   -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently
                                              supported by Chrome and Opera */
            }
            
            .${pfx}-Manual {
               border-top-left-radius: 10px;
               border-bottom-left-radius: 10px;
            }
            
            .${pfx}-Scheduled {
               border-left: 1px solid #e0e0e0;
               border-top-right-radius: 10px;
               border-bottom-right-radius: 10px;
            }
        </style>
      `
    });

}
