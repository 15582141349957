export default function(editor, opt = {}) {
    const c = opt;
    const bm = editor.BlockManager;
    const pfx = c.blockRef;

    bm.add(c.blockRef, {
        label: c.label,
        category: c.labelCategory,
        attributes: {class:'fa fa-signal'},
        content: `
            <div class="${pfx}" data-gjs-type="${c.blockRef}"  data-gjs-name="${c.label}" valuetype="read">
                ***Configure***
            </div>
            <style>
            .${pfx} {
              text-align: center;
              font-size: 18px;
              font-family: Arial, Helvetica, sans-serif;
              display: inline-block;
            }
          </style>
        `
    });

}
