export default function (editor, opt = {}) {
    const c = opt;
    let bm = editor.BlockManager;
    let stylePrefix = `${c.blockRef}-`;
    let clsContainerRow = `${stylePrefix}container-row`;
    let clsContainer = `${stylePrefix}container`;

    const rowAttr = {
        class: clsContainerRow,
        'data-gjs-droppable': `.${clsContainer}`,
        //'data-gjs-resizable': resizerBtm,
        'data-gjs-name': 'Diagram Row',
    };

    const containerAttr = {
        class: clsContainer,
        'data-gjs-draggable': `.${clsContainerRow}`,
        //'data-gjs-resizable': resizerRight,
        'data-gjs-name': 'Diagram Container',
    };

    const styles = `
    .${clsContainer} {
        width: min-content;
        display: inline-block;
        position: relative;
    }
    `;


    // Make row and column classes private
    const privateCls = [`.${clsContainerRow}`, `.${clsContainer}`];
    editor.on('selector:add', selector =>
        privateCls.indexOf(selector.getFullName()) >= 0 && selector.set('private', 1))

    const attrsToString = attrs => {
        const result = [];

        for (let key in attrs) {
            let value = attrs[key];
            const toParse = value instanceof Array || value instanceof Object;
            value = toParse ? JSON.stringify(value) : value;
            result.push(`${key}=${toParse ? `'${value}'` : `"${value}"`}`);
        }

        return result.length ? ` ${result.join(' ')}` : '';
    }

    const attrsRow = attrsToString(rowAttr);
    const attrsCell = attrsToString(containerAttr);

    bm.add(c.blockRef, {
        label: c.label,
        category: c.category,
        attributes: {class: c.labelIcon},
        content: `
        <div ${attrsRow}>
            <div ${attrsCell}>
                <img data-gjs-type="image" />
            </div>
        </div>
        <style>${styles}</style>
        `
    });

}