export default function(editor, opt = {}) {
    const c = opt;
    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    let viewList = c.viewList;

    // Add manual screens (graphs, schedules)
    viewList.push({"value": "SCHEDULES", "name": "All Schedules"});
    viewList.push({"value": "GRAPHS", "name": "All Graphs"});




    // Setup the type
    domc.addType(c.blockRef, {

        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                // dmode: 'absolute',
                droppable: false,
                traits: [{
                    label: 'Target Page',
                    name: 'targetPage',
                    type: 'select',
                    changeProp: 0,
                    options: viewList
                },
                {
                    label: 'Display Text',
                    name: 'displayText',
                    type: 'text',
                    changeProp: 0
                }
                ],
                disabledClsName: c.cls.buttonDisabledCls,
                defaultDisplayText: c.defaultDisplayText,
                script: function() {

                    // Set the text
                    this.innerHTML = this.getAttribute("displaytext") || '{[ defaultDisplayText ]}';

                    let getTarget = () => {
                        return this.getAttribute('targetpage');
                    };


                    // Send request back to React
                    let handleButtonPress = (event) => {
                        let data = {
                            messageType: 'CHANGE_PAGE',
                            // eslint-disable-next-line no-undef
                            data: serialize.serialize({
                                    value: getTarget()
                            })
                        };
                        window.parent.postMessage(data, "*");
                    };


                    // Bind for form input changes
                    this.addEventListener("click", handleButtonPress, false);

                }
            },
        }, {
            isComponent(el) {
                if(el.getAttribute &&
                    el.getAttribute('data-gjs-type') === c.blockRef) {
                    return {
                        type: c.blockRef
                    };
                }
            },
        }),


        view: defaultView.extend({
            init() {
                this.listenTo(this.model, 'change', (e)=> {
                    this.el.innerHTML = this.el.getAttribute('displaytext') || c.defaultDisplayText;
                });
                },
            onRender() {
                // Ensure HTML properties are present for the consumer JavaScript
                this.el.innerHTML = this.el.getAttribute('displayText') || c.defaultDisplayText;

                // Allow this to be dragged onto a diagram
                let dmode = '';

                // If we are inside a Diagram Container, use absolute positioning
                let parent = this.model.parent();
                if(parent && parent.hasOwnProperty('attributes')){
                    if(parent.attributes.name === "Diagram Container"){
                        dmode = 'absolute';
                    }
                }

                this.model.set('dmode', dmode);
                // TODO: Reset attributes of component if coming from absolute -> ''.
            },
        }),
    });
}
