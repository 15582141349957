import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'react-moment';
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import DatapointEditorField from "../DatapointEditorField";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(3)
    },
    card: {
        margin: theme.spacing(1),
        position: "relative",
    },
    cardUpdated: {
        backgroundColor: "#3f51b5", // TODO: Pull from theme somehow
    },
    deleteButton: {
        position: "absolute",
        top: theme.spacing(-1),
        right: theme.spacing(-1),
    },
    resize: {
        fontSize: 50
    },
    timeDivider: {
        marginBottom: theme.spacing(1),
        transition: "background-color 0.5s linear"
    },
    paddedText: {
        marginTop: "20px",
        padding: "20px",
        textAlign: "center"
    },

});


class TimeslotEditor extends React.Component {

    getFormattedTime(time) {
        return (
            <Moment format="HH:mm">
                {time}
            </Moment>
        )
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.updateUpdated(false);
        }, 2000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updated !== this.props.updated && this.props.updated === true) {
            // Set timeout to fade our effect
            setTimeout(() => {
                this.props.updateUpdated(false);
            }, 2000);
        }
    }

    composeTimeslotEntry(entry) {

        // Hide this entry from the user if in SingleDatapointMode
        if (this.props.singleDatapointMode && entry.datapoint.id !== this.props.singleDatapointId) {
            return;
        }

        // Otherwise return the entry
        return (<Grid key={entry.datapoint.name} item>
                <div style={{display: "flex"}}>
                    <Tooltip title={"Override " + entry.datapoint.name}>
                        <Checkbox
                            checked={entry.isSet}
                            onChange={(e) => this.props.updateDatapointSet(e.target.checked, entry)}
                            value={true}
                            disabled={entry.isForceSet}
                            color="primary"
                        />
                    </Tooltip>
                    <DatapointEditorField
                        datapoint={entry.datapoint}
                        value={entry.value}
                        onChange={(value) => this.props.updateDatapointValue(value, entry)}
                        disabled={!entry.isSet}
                    />
                </div>
            </Grid>
        )

    }

    render() {
        const classes = this.props.classes;

        let dividerClass = classes.timeDivider;
        if (this.props.updated) {
            dividerClass = clsx(classes.cardUpdated, classes.timeDivider)
        }

        let timeslotEntries = this.props.entries.map(entry => this.composeTimeslotEntry(entry));

        return (
            <Box>
                <div>
                    <Card className={classes.card}>
                        <CardContent>
                            {!this.props.startTimeLocked &&
                            <IconButton className={classes.deleteButton} aria-label="delete"
                                        onClick={(this.props.deleteTimeslot.bind(this))}>
                                <DeleteIcon/>
                            </IconButton>
                            }
                            <Typography className={classes.title} variant="h5" component='div' color="textSecondary" gutterBottom>
                                <Box display="flex" alignContent="flex-end">
                                    <Box>
                                        {!this.props.startTimeLocked &&
                                        <>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    ampm={false}
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    label="Time"
                                                    value={this.props.startTime}
                                                    onChange={(time) => this.props.updateStartTime(time)}
                                                    onClose={() => this.props.onTimePickerClose()}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                            &nbsp;&nbsp;
                                        </>
                                        }
                                        {this.props.startTimeLocked && this.getFormattedTime(this.props.startTime)}
                                    </Box>
                                    <Box alignSelf="flex-end">
                                        {/*&nbsp;until {this.props.endTime}*/}
                                    </Box>

                                </Box>
                            </Typography>
                            <Divider className={dividerClass}/>
                            <Typography variant="body2" component="div">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container justify="flex-start" spacing={2} alignItems="flex-start"
                                              direction="row">
                                            {timeslotEntries}
                                            {timeslotEntries.length === 0 &&
                                            <p className={classes.paddedText}>There are no setpoints attached to this
                                                schedule.</p>}
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Box>
        )
    }

}

export default withStyles(styles)(TimeslotEditor);