import React from "react";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    IconButton, MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
    withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DatapointFacetManager from "./Facets/DatapointFacetManager";
import clsx from 'clsx';
import {Link as RouterLink} from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import ScheduleIcon from "@material-ui/icons/Schedule";
import WeekEditorDialogFull from "./Schedule/WeekEditorDialogFull";
import DatapointManager from "@/DatapointManager";
import ScheduleManager from "@/ScheduleManager";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TenantManager from "@/TenantManager";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    card: {
        maxWidth: 345,
        minWidth: 290
    },
});


class Datapoint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            editMode: false,
            scheduleEditorOpen: false,
            scheduleEditorProfile: null,
            userIsAdmin: false
        }
        this.scheduleEditorEditedTimeslots = [];
    }

    async componentDidMount() {
        this.setState({...this.state, userIsAdmin: await TenantManager.isTenantAdmin()});
    }

    handleExpandClick() {
        this.setState({...this.state, expanded: !this.state.expanded});
    }

    changeEditMode(state) {
        this.setState({...this.state, editMode: state});
    }

    onSaveDatapointButton() {
        if (this.state.editMode) {
            this.props.onDatapointSave();
            this.changeEditMode(false);
        } else {
            this.props.onDatapointEdit();
            this.changeEditMode(true);
        }
    }

    onFacetChange(key, value) {
        this.props.onFacetChange(key, value);
    }

    onFacetDelete(key) {
        this.props.onFacetDelete(key);
    }

    createField(name, label, value) {
        return (
            <TextField
                label={label}
                type="text"
                name={name}
                value={value}
                fullWidth
                // className={classes.textFieldEnum}
                onChange={(e) => this.props.onTopPropChange(name, e.target.value)}
            />
        )
    }

    createSelect(name, label, value, options){
        return (
            <TextField
            select
            value={value}
            onChange={(e) => this.props.onTopPropChange(name, e.target.value)}
            label={label}
            // className={classes.textField}
            // disabled={disableEditor}
        >
            {options.map((v) => <MenuItem key={v.key} value={v.key}>{v.display}</MenuItem>)}
        </TextField>
        )
    }

    // Schedules
    openScheduleEditor(schedule, profile) {
        this.setState({
            ...this.state,
            scheduleEditorOpen: true,
            scheduleEditorSchedule: schedule,
            scheduleEditorProfile: profile
        });
    }

    closeScheduleEditor() {
        this.setState({
            ...this.state,
            scheduleEditorOpen: false,
            scheduleEditorSchedule: null,
            scheduleEditorProfile: null
        });
        this.scheduleEditorEditedTimeslots = [];
    }

    async saveScheduleEditor() {
        await ScheduleManager.saveProfileTimeslots(this.state.scheduleEditorProfile, this.scheduleEditorEditedTimeslots);
        this.setState({
            ...this.state,
            scheduleEditorOpen: false,
            scheduleEditorSchedule: null,
            scheduleEditorProfile: null
        });
        this.scheduleEditorEditedTimeslots = []
    }

    getEditorDialog() {
        let profile = this.state.scheduleEditorProfile;
        let datapoints = this.state.scheduleEditorSchedule.datapoints.items.map(d => DatapointManager.hydrateDatapointWithHelpers(d))

        return (<WeekEditorDialogFull
            timeslots={profile.timeslots}
            updateScheduleTimeslots={(x) => this.scheduleEditorEditedTimeslots = x}
            setpoints={datapoints}
            onClose={() => this.closeScheduleEditor()}
            onSave={() => this.saveScheduleEditor()}
            title={this.state.scheduleEditorSchedule.name + " > " + profile.name}
            open={true}
            singleDatapointId={this.props.datapoint.id}
            singleDatapointMode
            fullscreen
        />)
    }


    render() {
        const classes = this.props.classes;
        const d = this.props.datapoint;

        let typeOptions = [
            {'key': 'numeric', 'display': 'NUMERIC'},
            {'key': 'enum', 'display': 'ENUM'},
            {'key': 'boolean', 'display': 'BOOLEAN'},
            // {'key': 'string', 'display': 'STRING'},
        ];

        let modeOptions = [
            {'key': 'none', 'display': 'None'},
            {'key': 'r', 'display': 'Read'},
            {'key': 'w', 'display': 'Write'},
            {'key': 'rw', 'display': 'Read/Write'},
        ];

        let titleDisplay = this.state.editMode ? this.createField("name", "Name", d.name) : d.name;
        let descriptionDisplay = this.state.editMode ? this.createField("description", "Description", d.description) : d.description;
        let legacyRegisterDisplay = this.state.editMode ? this.createField("legacyRegisterId", "Register",  d.legacyRegisterId) : d.legacyRegisterId;
        let dataTypeDisplay = this.state.editMode ? this.createSelect("type", "Type", d.type, typeOptions) : d.type.toUpperCase();
        let rwModeDisplay = this.state.editMode ? this.createSelect("rwMode", "Mode", d.getRwMode(), modeOptions) : modeOptions.find(o => o.key === d.getRwMode()).display;

        return (
            <>
                <Card className={classes.card}>
                    <CardHeader
                        action={
                            <>
                                {d.isScheduleAvailable() &&
                                <IconButton aria-label="settings" onClick={() => this.openScheduleEditor(d.schedule, d.schedule.activeProfile)}>
                                    <Tooltip title="Modify Active Schedule">
                                        <ScheduleIcon/>
                                    </Tooltip>
                                </IconButton>
                                }
                                {this.state.userIsAdmin &&
                                    <IconButton aria-label="settings" onClick={() => this.onSaveDatapointButton()}>
                                        {!this.state.editMode && <EditIcon/>}
                                        {this.state.editMode && <SaveIcon/>}
                                    </IconButton>
                                }
                            </>
                        }
                        title={titleDisplay}
                        subheader={descriptionDisplay || "No description"}
                    />
                    <Divider/>
                    <CardContent>

                        <Table className={classes.table}>
                            <TableBody>

                                {/* Type */}
                                <TableRow>
                                    <TableCell component="th" scope="row">Datatype</TableCell>
                                    <TableCell>{dataTypeDisplay}</TableCell>
                                </TableRow>

                                {/*  */}
                                <TableRow>
                                    <TableCell component="th" scope="row">Mode</TableCell>
                                    <TableCell>{rwModeDisplay}</TableCell>
                                </TableRow>

                                {/* Legacy Register ID */}
                                <TableRow>
                                    <TableCell component="th" scope="row">Register ID</TableCell>
                                    <TableCell>{legacyRegisterDisplay}</TableCell>
                                </TableRow>

                                {/* Read Value */}
                                {d.isReadpoint &&
                                <TableRow>
                                    <TableCell component="th" scope="row">Read Value</TableCell>
                                    <TableCell>{d.getReadDisplayValue()}</TableCell>
                                </TableRow>
                                }

                                {/* Set Value */}
                                {d.isSetpoint &&
                                <TableRow>
                                    <TableCell component="th" scope="row">Set Value</TableCell>
                                    <TableCell>{d.getSetDisplayValue()}</TableCell>
                                </TableRow>
                                }

                                {/* Schedule */}
                                {d.isSetpoint &&
                                <TableRow>
                                    <TableCell component="th" scope="row">Schedule</TableCell>
                                    <TableCell>
                                        {d.schedule && <RouterLink to={"/schedules/" + d.schedule.id}>{d.schedule.name} ({d.isScheduled() && "Scheduled"}{!d.isScheduled() && "Manual"})</RouterLink>}
                                        {!d.schedule && "None"}
                                    </TableCell>
                                </TableRow>
                                }

                            </TableBody>
                        </Table>


                    </CardContent>
                    <CardActions disableSpacing>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: this.state.expanded,
                            })}
                            onClick={this.handleExpandClick.bind(this)}
                            aria-expanded={this.state.expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon/>
                        </IconButton>
                    </CardActions>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <Divider/>
                        <CardContent>

                            <Typography variant="button">
                                Facets
                            </Typography>
                            <DatapointFacetManager
                                facets={d.facets}
                                onFacetChange={this.onFacetChange.bind(this)}
                                onFacetDelete={this.onFacetDelete.bind(this)}
                                edit={this.state.editMode}
                            />
                        </CardContent>
                    </Collapse>
                </Card>

                {this.state.scheduleEditorOpen && this.getEditorDialog()}
            </>
        )
    }

}

export default withStyles(styles)(Datapoint);
