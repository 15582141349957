import React from "react";
import {
    AppBar,
    Button,
    Checkbox,
    Container,
    Dialog,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Slide,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Toolbar,
    Typography,
    withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import WeekEditor from "./Schedule/WeekEditor";
import uuidv4 from "uuid/v4";
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../graphql/mutations";
import TenantManager from "@/TenantManager";
import Grid from "@material-ui/core/Grid";
import DatapointManager from "@/DatapointManager";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
    },
    appBarTitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    stepTitle: {
        marginBottom: theme.spacing(2),
        flex: 1,
    },
    paddedText: {
        padding: "20px",
        textAlign: "center"
    },
    stepPaper: {
        padding: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1),
    },
    chip: {
        margin: theme.spacing(1),
    },
    stepContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }

});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class ScheduleEditor extends React.Component {

    constructor(props) {
        super(props)
        this.state = this.getCleanState();
        this.timeslots = []
    }

    getCleanState() {
        return {
            steps: [
                {title: "Name schedule", isValid: false},
                {title: "Select setpoints", isValid: false},
                {title: "Name default profile", isValid: false},
                {title: "Setup the profile", isValid: false},
            ],
            activeStep: 0,
            scheduleName: "",
            profileName: "",
            selectedSetpoints: [],
            selectedTab: 0
        }
    }

    getStepContent(stepIndex, classes) {
        switch (stepIndex) {
            case 0:
                return (
                    <div>
                        <TextField
                            id="standard-full-width"
                            label="Schedule Name"
                            // style={{ margin: 8 }}
                            value={this.state.scheduleName}
                            onChange={this.setScheduleName.bind(this)}
                            placeholder="Pool Schedule"
                            // helperText="Full width!"
                            fullWidth
                            // margin="normal"
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                        />
                    </div>
                );
            case 1:
                return (
                    <div>
                        <List className={classes.root}>
                            {this.props.availableSetpoints.map(value => {
                                const labelId = `checkbox-list-label-${value.id}`;

                                return (
                                    <ListItem key={value.id} role={undefined} dense button
                                              onClick={() => this.handleSetpointSelectionToggle(value)}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={this.state.selectedSetpoints.indexOf(value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{'aria-labelledby': labelId}}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={value.name}/>
                                    </ListItem>
                                );
                            })}
                            {this.props.availableSetpoints.length === 0 &&
                            <p className={classes.paddedText}>There are no setpoints available. Detach some from other
                                schedules.</p>}
                        </List>


                    </div>
                );
            case 2:
                return (
                    <div>
                        <TextField
                            id="standard-full-width"
                            label="Profile Name"
                            style={{margin: 8}}
                            value={this.state.profileName}
                            onChange={this.setProfileName.bind(this)}
                            placeholder="Default"
                            // helperText="Full width!"
                            fullWidth
                            margin="normal"
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                        />

                    </div>
                );
            case 3:
                return (
                    <div>Doop</div>
                );
            default:
                return 'Unknown stepIndex';
        }
    }

    handleSetpointSelectionToggle(value) {
        // See if value is in this.state.selectedSetpoints, remove if so
        let selected = this.state.selectedSetpoints;
        let position = selected.indexOf(value);
        if (position > -1) {
            selected.splice(position, 1);
        } else { // Or add to this.state.selectedSetpoints
            selected.push(value)
        }

        let steps = this.state.steps;
        steps[this.state.activeStep].isValid = (selected.length > 0) ? true : false
        this.setState({...this.state, selectedSetpoints: selected, steps: steps});
    }

    setScheduleName(e) {
        // Do Validation
        let value = e.target.value;
        let isValid = /^[a-zA-Z]{1}[a-zA-Z0-9 ]+$/.test(value);

        let steps = this.state.steps;
        steps[this.state.activeStep].isValid = isValid

        this.setState({...this.state, scheduleName: e.target.value, steps: steps})
    }

    setProfileName(e) {
        // Do Validation
        let value = e.target.value;
        let isValid = /^[a-zA-Z]{1}[a-zA-Z0-9 ]+$/.test(value);

        let steps = this.state.steps;
        steps[this.state.activeStep].isValid = isValid

        this.setState({...this.state, profileName: e.target.value, steps: steps})
    }

    onNextStep() {
        this.setState({...this.state, activeStep: this.state.activeStep + 1})
    }

    onPrevStep() {
        this.setState({...this.state, activeStep: this.state.activeStep - 1})
    }

    onClose() {
        // Reset the state
        this.setState({
            ...this.state,
            ...this.getCleanState()
        });
        // Close handler
        this.props.onClose();
    }

    async onSaveSchedule() {
        // TODO: In a single GQL
        // Create profile with timeslots
        // Create schedule linked to profile
        try {
            // create ids
            const profileId = uuidv4();
            const scheduleId = uuidv4();


            // Create the profile
            const profileDetails = {
                tenantId: TenantManager.getTenantId(),
                id: profileId, // TODO create in Appsync
                name: this.state.profileName.trim(),
                type: 'sevenday',
                timeslots: this.timeslots,
                scheduleProfileScheduleId: scheduleId
            };


            // Create the schedule
            const scheduleDetails = {
                tenantId: TenantManager.getTenantId(),
                id: scheduleId, // TODO create in Appsync
                name: this.state.scheduleName.trim(),
                scheduleActiveProfileId: profileId,
            };

            // Ensure these work before we try and bind datapoints
            await API.graphql(graphqlOperation(mutations.createScheduleProfile, {input: profileDetails}));
            await API.graphql(graphqlOperation(mutations.createSchedule, {input: scheduleDetails}));

            // Datapoint binding
            let dpPromises = this.state.selectedSetpoints.map(dp =>
               API.graphql(graphqlOperation(mutations.updateDatapoint, {
                    input: {
                        id: dp.id, tenantId: dp.tenantId, datapointScheduleId: scheduleId
                    }
                }))
            );

            // Attach the datapoints, wait for all to complete
            await Promise.all(dpPromises);

            // this.changeAddDialogSelectorState(false);
            this.props.reloadSchedules();
            DatapointManager.getAllDatapoints(true);
            this.props.addSnack("Schedule successfully created.", "success");
            this.onClose();
        } catch (e) {
            this.props.addSnack("Unable to add new schedule. Contact support.", "error");
            console.error(e);
        }


    }

    render() {
        const classes = this.props.classes;

        return (
            <Dialog fullScreen open={this.props.open} onClose={this.onClose.bind(this)}
                    TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.onClose.bind(this)} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.appBarTitle}>
                            New Schedule
                        </Typography>
                        <Button color="inherit" disabled={this.state.activeStep < 3}
                                onClick={this.onSaveSchedule.bind(this)}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container>
                    <Stepper activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.steps.map(step => (
                            <Step key={step.title}>
                                <StepLabel>{step.title}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {this.state.activeStep < 3 &&
                    <>
                        <Container maxWidth={'sm'} className={classes.stepContainer}>
                            <Paper className={classes.stepPaper}>
                                <Typography variant="h5" className={classes.stepTitle}>
                                    {this.state.steps[this.state.activeStep].title}
                                </Typography>
                                {this.getStepContent(this.state.activeStep, classes)}
                            </Paper>
                        </Container>

                        <Grid
                            justify="space-between" // Add it here :)
                            container
                            spacing={24}
                        >
                            <Grid item>
                                &nbsp;
                            </Grid>
                            <Grid item>
                                <Button disabled={this.state.activeStep === 0} onClick={this.onPrevStep.bind(this)}
                                        className={classes.button}>
                                    Back
                                </Button>
                                <Button disabled={!this.state.steps[this.state.activeStep].isValid} variant="contained"
                                        color="primary" onClick={this.onNextStep.bind(this)}>
                                    {this.state.activeStep === this.state.steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </Grid>

                        </Grid>


                    </>
                    }

                    {this.state.activeStep === 3 &&
                    <WeekEditor
                        setpoints={this.state.selectedSetpoints}
                        updateScheduleTimeslots={(value) => this.timeslots = value}
                        timeslots={[]}
                    />
                    }

                </Container>


            </Dialog>
        )
    }


}

export default withStyles(styles)(ScheduleEditor);
