import React, {CSSProperties, RefObject} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton, InputLabel,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField, Theme,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PageviewIcon from "@material-ui/icons/Pageview";
import {Link as RouterLink} from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../graphql/mutations";
import TenantManager from "../TenantManager";
import uuidv4 from "uuid/v4"
import ViewToolbar from "./ViewToolbar";
import Container from "@material-ui/core/Container";
import {ViewType} from "../DataTypes";
import {copyObject} from "../AWSHelpers/s3";

const styles = (theme : Theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        paddingTop: "20px",
        marginBottom: "20px",
    },
    button: {
        margin: theme.spacing(0.5),
    },
    fab: {
        position: "fixed" as "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paddedText: {
        padding: "20px",
        textAlign: "center" as "center"
    }

});

interface IProps {
    classes?: any
    views: Array<ViewType>
    selectedView: ViewType
    reloadViews: () => null
    addSnack: (msg: string, type: string) => null
    history: any // TODO: Make react router history
}

interface IState {
    addDialogOpen: boolean
    addDialogCreateDisabled: boolean
    addDialogHelperText: string
    deleteDialogOpen: boolean
    deleteDialogSelectedName: string
    deleteDialogSelectedId: string
    copyDialogOpen: boolean
    copyDialogSelectedName: string
    copyDialogSelectedId: string
    copyDialogSelectedTenantId: string
    copyDialogCreateDisabled: boolean
    copyDialogHelperText: string
    // copyDialogGoToNewView: boolean TODO: Fix the routing issues with view loading before enabling this
    userIsAdmin: boolean
    tenants: Array<any>
}

class ViewManager extends React.Component<IProps, IState> {

    addDialogNameRef: RefObject<any>;
    copyDialogNameRef: RefObject<any>;

    constructor(props: IProps) {
        super(props);
        this.state = {
            addDialogOpen: false,
            addDialogCreateDisabled: true,
            addDialogHelperText: "",
            deleteDialogOpen: false,
            deleteDialogSelectedName: "",
            deleteDialogSelectedId: "",
            copyDialogOpen: false,
            copyDialogSelectedName: "",
            copyDialogSelectedId: "",
            copyDialogSelectedTenantId: TenantManager.getTenantId(),
            copyDialogCreateDisabled: true,
            copyDialogHelperText: '',
            // copyDialogGoToNewView: false, TODO
            userIsAdmin: false,
            tenants: []
        };

        TenantManager.listTenants().then(data => {
            this.setState({...this.state, tenants: data});
        });

        this.addDialogNameRef = React.createRef();
        this.copyDialogNameRef = React.createRef();
    }

    async componentDidMount() {
        this.setState({...this.state, userIsAdmin: await TenantManager.isTenantAdmin()});
    }

    render() {
        const classes = this.props.classes;
        const rows = this.props.views;

        return (
            <Container>
                <ViewToolbar views={this.props.views} selectedView={this.props.selectedView} manager/>
                <Typography variant="h3" className={classes.title}>
                    Views
                </Typography>
                <Paper className={classes.root}>
                    {rows.length > 0 &&
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell scope="row">
                                        <RouterLink to={"/views/" + row.id}>{row.name}</RouterLink>
                                    </TableCell>
                                    <TableCell align="right">

                                        <IconButton aria-label="View" className={classes.button} component={RouterLink}
                                                    to={"/views/" + row.id}>
                                            <PageviewIcon/>
                                        </IconButton>

                                        {this.state.userIsAdmin &&
                                            <>
                                                <label title="Edit View">
                                                    <IconButton aria-label="Edit" className={classes.button} component={RouterLink}
                                                                to={"/views/" + row.id + "/edit"}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                </label>

                                                <label title="Copy View">
                                                    <IconButton aria-label="Copy" className={classes.button}
                                                                onClick={() => this.showCopyDialog(row.id, row.name)}>
                                                        <FileCopyIcon/>
                                                    </IconButton>
                                                </label>

                                                <label title="Delete View">
                                                    <IconButton aria-label="Delete" className={classes.button}
                                                        onClick={() => this.showDeleteConfirmation(row.id, row.name)} >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </label>
                                            </>
                                        }

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    }
                </Paper>

                {rows.length === 0 &&
                <Typography className={classes.paddedText}>
                    There are currently no views. Add one using the + button below.
                </Typography>
                }

                {this.state.userIsAdmin &&
                    <Tooltip title="Add View">
                        <Fab aria-label="Add" className={classes.fab} color="primary"
                             onClick={() => this.changeAddDialogSelectorState(true)}>
                            <AddIcon/>
                        </Fab>
                    </Tooltip>
                }

                {/*ADD Dialog*/}
                <Dialog open={this.state.addDialogOpen} onClose={() => this.changeAddDialogSelectorState(false)}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add View</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="View name"
                            type="text"
                            error={this.state.addDialogHelperText.length > 0}
                            required={true}
                            onChange={this.validateViewAddName.bind(this)}
                            inputRef={this.addDialogNameRef}
                            fullWidth
                        />
                        <FormHelperText id="component-error-text">{this.state.addDialogHelperText}</FormHelperText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.changeAddDialogSelectorState(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.addDialogDoViewCreate.bind(this)} color="primary"
                                disabled={this.state.addDialogCreateDisabled}>
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*DELETE Dialog*/}
                <Dialog open={this.state.deleteDialogOpen} onClose={() => this.changeDeleteDialogSelectorState(false)}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete View</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete {this.state.deleteDialogSelectedName}?
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.doDelete.bind(this)} color="primary">
                            Delete
                        </Button>
                        <Button onClick={() => this.changeDeleteDialogSelectorState(false)} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*COPY Dialog*/}
                <Dialog open={this.state.copyDialogOpen} onClose={() => this.changeCopyDialogSelectorState(false)}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Copy View</DialogTitle>
                    <DialogContent>
                        <InputLabel id="demo-simple-select-label">Target Tenant</InputLabel>
                        <Select
                            value={this.state.copyDialogSelectedTenantId}
                            onChange={this.handleCopyTenantChange.bind(this)}
                        >
                            {this.state.tenants.map(row => (
                                <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                            ))}
                        </Select>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Target View Name"
                            type="text"
                            error={this.state.copyDialogHelperText.length > 0}
                            required={true}
                            onChange={this.validateViewCopyName.bind(this)}
                            inputRef={this.copyDialogNameRef}
                            fullWidth
                        />
                        <FormHelperText id="component-error-text">{this.state.copyDialogHelperText}</FormHelperText>

                        {/*<FormControlLabel*/}
                        {/*    control={*/}
                        {/*        <Checkbox checked={this.state.copyDialogGoToNewView} onChange={this.handleCopyGoToViewChange.bind(this)} value={true} />*/}
                        {/*    }*/}
                        {/*    label="Go to new view after copy"*/}
                        {/*/>*/}

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.changeCopyDialogSelectorState(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.copyDialogDoViewCopy.bind(this)} color="primary"
                                disabled={this.state.copyDialogCreateDisabled}>
                            Copy
                        </Button>
                    </DialogActions>
                </Dialog>

            </Container>
        )
    }

    // ADD Dialog
    changeAddDialogSelectorState(open: boolean) {
        this.setState({...this.state, addDialogOpen: open});
    }

    async addDialogDoViewCreate() {

        // Create
        const viewDetails = {
            tenantId: TenantManager.getTenantId(),
            id: uuidv4(), // TODO create in Appsync
            name: this.addDialogNameRef.current.value.trim(),
        };

        try {
            await API.graphql(graphqlOperation(mutations.createView, {input: viewDetails}));
            this.changeAddDialogSelectorState(false);
            this.props.reloadViews();
        } catch (e) {
            this.props.addSnack("Unable to add new view. Contact support.", "error");
            console.error(e);
        }
    }

    validateViewAddName() {
        let value = this.addDialogNameRef.current.value;

        // Do Checks
        let lengthCheck = (value.length > 2);
        let existingMatch = this.props.views.find((e) => e.name.toUpperCase() === value.toUpperCase());

        // Do logic
        if (lengthCheck && !existingMatch) {
            // Validation good
            this.setState({...this.state, addDialogCreateDisabled: false, addDialogHelperText: ""});
        } else {
            let message = this.state.addDialogHelperText;
            if (existingMatch) message = "View name must be unique."
            // Validation bad
            this.setState({...this.state, addDialogCreateDisabled: true, addDialogHelperText: message});
        }

    }


    // DELETE Dialog
    changeDeleteDialogSelectorState(open: boolean) {
        this.setState({...this.state, deleteDialogOpen: open});
    }

    async doDelete() {
        const viewDetails = {
            tenantId: TenantManager.getTenantId(),
            id: this.state.deleteDialogSelectedId
        };

        try {
            await API.graphql(graphqlOperation(mutations.deleteView, {input: viewDetails}));
            this.changeDeleteDialogSelectorState(false);
            this.props.reloadViews();
        } catch (e) {
            this.props.addSnack("Unable to add delete view. Contact support.", "error");
            console.error(e);
        }

        this.setState({...this.state, deleteDialogSelectedName: "", deleteDialogSelectedId: ""})
    }

    showDeleteConfirmation(id: string, name: string) {
        this.setState({
            ...this.state,
            deleteDialogSelectedName: name,
            deleteDialogSelectedId: id,
            deleteDialogOpen: true
        });
    }

    // COPY Dialog
    changeCopyDialogSelectorState(open: boolean) {
        this.setState({...this.state, copyDialogOpen: open});
    }

    handleCopyTenantChange(event: any){
        this.setState({
            copyDialogSelectedTenantId: event.target.value
        })
    }

    // TODO: Fix routing issues
    // handleCopyGoToViewChange(event: any){
    //     this.setState({
    //         copyDialogGoToNewView:  event.target.checked
    //     })
    // }

    showCopyDialog(id: string, name: string){
        this.setState({
            ...this.state,
            copyDialogSelectedName: name,
            copyDialogSelectedId: id,
            copyDialogOpen: true
        });
    }

    async copyDialogDoViewCopy() {

        // Make a new one in the DB, as we would with the FAB
        let newId = uuidv4();

        // Request a copy from old to new
        let s3Configuration;
        try {
            let sourceKey = "tenant/" + TenantManager.getTenantId() + "/view/" + this.state.copyDialogSelectedId + "/configuration.json";
            let destinationKey = "tenant/" + this.state.copyDialogSelectedTenantId + "/view/" + newId + "/configuration.json";
            s3Configuration = await copyObject(sourceKey, destinationKey);
        } catch (e) {
            this.props.addSnack("Failed to copy existing configuration", "error");
            return;
        }

        // Make in GQL
        try {
            const viewDetails = {
                tenantId: this.state.copyDialogSelectedTenantId,
                id: newId, // TODO create in Appsync
                name: this.copyDialogNameRef.current.value.trim(),
                configuration: s3Configuration
            };
            await API.graphql(graphqlOperation(mutations.createView, {input: viewDetails}));
        } catch (e) {
            this.props.addSnack("Failed to create view", "error");
            return;
        }

        // TODO: Redirect the user to the next thing
        this.props.addSnack("View copied successfully", "success");
        // if(this.state.copyDialogSelectedTenantId === TenantManager.getTenantId()) {
        this.props.reloadViews();
        this.changeCopyDialogSelectorState(false);
        // }

        if(this.state.copyDialogSelectedTenantId === TenantManager.getTenantId()) {
            this.props.addSnack("View copied successfully", "success");
        }else{
            this.props.addSnack("View copied successfully, switch tenants to edit the new view", "success");
        }

        // if(this.state.copyDialogGoToNewView){
        //     // Load the view if requested, we may need to change tenants
        //     this.props.history.push(`/view/${newId}/edit`) //?tenantId=${this.state.copyDialogSelectedTenantId}
        // }
    }

    validateViewCopyName() {
        let value = this.copyDialogNameRef.current.value;

        // Do Checks
        let lengthCheck = (value.length > 2);   // TODO: This doesn't check other tenant targets, but it will be fine for now
        let existingMatch = (this.state.copyDialogSelectedTenantId === TenantManager.getTenantId()) &&
                                    this.props.views.find((e) => e.name.toUpperCase() === value.toUpperCase());

        // Do logic
        if (lengthCheck && !existingMatch) {
            // Validation good
            this.setState({...this.state, copyDialogCreateDisabled: false, copyDialogHelperText: ""});
        } else {
            let message = this.state.copyDialogHelperText;
            if (existingMatch) message = "View name must be unique."
            // Validation bad
            this.setState({...this.state, copyDialogCreateDisabled: true, copyDialogHelperText: message});
        }

    }
}

export default withStyles(styles)(ViewManager);
