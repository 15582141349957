export default function(editor, opt = {}) {
    const c = opt;
    const bm = editor.BlockManager;
    const pfx = c.blockRef;

    bm.add(c.blockRef, {
        label: c.label,
        category: c.labelCategory,
        attributes: {class: c.blockIcons},
        content: `
            <button class="${pfx} fas fa-caret-square-up" data-gjs-type="${c.blockRef}" data-gjs-name="${c.label}" buttonaction="incr">
            </button>
            <style>
            .${pfx} {
              color: black;
              font-size: 3em;
              background: none;
              border: none;
              cursor: pointer;
            }
            .${pfx}:active {
                opacity: 0.8;
            }
          </style>
        `
    });

}
