/* tslint:disable */
//  This file was automatically generated and should not be edited.

export type CreateViewInput = {
  tenantId: string,
  id: string,
  name: string,
  configuration?: S3ObjectInput | null,
  viewTenantId?: string | null,
};

export type S3ObjectInput = {
  bucket: string,
  key: string,
  region: string,
};

export type UpdateViewInput = {
  tenantId: string,
  id: string,
  name?: string | null,
  configuration?: S3ObjectInput | null,
  viewTenantId?: string | null,
};

export type DeleteViewInput = {
  tenantId: string,
  id: string,
};

export type CreateTenantInput = {
  id?: string | null,
  name: string,
  slug: string,
  legacySiteId?: string | null,
  customPwaIcons?: boolean | null,
};

export type UpdateTenantInput = {
  id: string,
  name?: string | null,
  slug?: string | null,
  legacySiteId?: string | null,
  customPwaIcons?: boolean | null,
};

export type DeleteTenantInput = {
  id?: string | null,
};

export type CreateDatapointInput = {
  tenantId: string,
  id: string,
  name: string,
  description?: string | null,
  type: DatapointType,
  legacyRegisterId?: string | null,
  isSetpoint: boolean,
  isReadpoint: boolean,
  readValue?: string | null,
  setValue?: string | null,
  setMode?: DatapointSetMode | null,
  readTimestamp?: string | null,
  facets?: DatapointFacetsInput | null,
  datapointTenantId?: string | null,
  datapointScheduleId?: string | null,
};

export enum DatapointType {
  numeric = "numeric",
  boolean = "boolean",
  string = "string",
  enum = "enum",
}


export enum DatapointSetMode {
  manual = "manual",
  scheduled = "scheduled",
}


export type DatapointFacetsInput = {
  units?: string | null,
  divisor?: number | null,
  precision?: number | null,
  scale?: number | null,
  enumMapping?: Array< DatapointEnumMapInput > | null,
  trueText?: string | null,
  falseText?: string | null,
  min?: number | null,
  max?: number | null,
  step?: number | null,
};

export type DatapointEnumMapInput = {
  value: string,
  display: string,
};

export enum ScheduleProfileMode {
  sevenday = "sevenday",
}


export type UpdateDatapointInput = {
  tenantId: string,
  id: string,
  name?: string | null,
  description?: string | null,
  type?: DatapointType | null,
  legacyRegisterId?: string | null,
  isSetpoint?: boolean | null,
  isReadpoint?: boolean | null,
  readValue?: string | null,
  setValue?: string | null,
  setMode?: DatapointSetMode | null,
  readTimestamp?: string | null,
  facets?: DatapointFacetsInput | null,
  datapointTenantId?: string | null,
  datapointScheduleId?: string | null,
};

export type DeleteDatapointInput = {
  tenantId: string,
  id: string,
};

export type CreateScheduleInput = {
  tenantId: string,
  id: string,
  name: string,
  description?: string | null,
  scheduleActiveProfileId: string,
  scheduleTenantId?: string | null,
};

export type UpdateScheduleInput = {
  tenantId: string,
  id: string,
  name?: string | null,
  description?: string | null,
  scheduleActiveProfileId?: string | null,
  scheduleTenantId?: string | null,
};

export type DeleteScheduleInput = {
  tenantId: string,
  id: string,
};

export type CreateDeploymentInput = {
  tenantId: string,
  id: string,
  type: DeploymentType,
  timeRequested: string,
  deploymentTenantId?: string | null,
};

export enum DeploymentType {
  setpoints = "setpoints",
}


export type UpdateDeploymentInput = {
  tenantId: string,
  id: string,
  type?: DeploymentType | null,
  timeRequested?: string | null,
  deploymentTenantId?: string | null,
};

export type DeleteDeploymentInput = {
  tenantId: string,
  id: string,
};

export type CreateScheduleProfileInput = {
  tenantId: string,
  id: string,
  name: string,
  type: ScheduleProfileMode,
  timeslots: Array< ScheduleTimeslotInput >,
  scheduleProfileScheduleId?: string | null,
  scheduleProfileTenantId?: string | null,
};

export type ScheduleTimeslotInput = {
  key: string,
  entries: Array< ScheduleEntryInput >,
};

export type ScheduleEntryInput = {
  datapointId: string,
  value: string,
};

export type UpdateScheduleProfileInput = {
  tenantId: string,
  id: string,
  name?: string | null,
  type?: ScheduleProfileMode | null,
  timeslots?: Array< ScheduleTimeslotInput > | null,
  scheduleProfileScheduleId?: string | null,
  scheduleProfileTenantId?: string | null,
};

export type DeleteScheduleProfileInput = {
  tenantId: string,
  id: string,
};

export type CreateChartInput = {
  tenantId: string,
  id: string,
  title: string,
  view: ChartViewType,
  metrics: Array< ChartMetricInput >,
  yAxis?: ChartYAxisInput | null,
  chartTenantId?: string | null,
};

export enum ChartViewType {
  timeSeries = "timeSeries",
}


export type ChartMetricInput = {
  datapointId: string,
  statistic: ChartMetricStatisticValues,
  period: number,
  yAxis?: ChartYAxisMetricValues | null,
  color?: string | null,
  label?: string | null,
  displayType?: ChartMetricDisplayTypes | null,
};

export enum ChartMetricStatisticValues {
  minimum = "minimum",
  maximum = "maximum",
  average = "average",
  summation = "summation",
}


export enum ChartYAxisMetricValues {
  left = "left",
  right = "right",
}


export enum ChartMetricDisplayTypes {
  line = "line",
  step = "step",
  binary = "binary",
}


export type ChartYAxisInput = {
  left?: ChartYAxisConfigurationInput | null,
  right?: ChartYAxisConfigurationInput | null,
};

export type ChartYAxisConfigurationInput = {
  min?: number | null,
  max?: number | null,
  label?: string | null,
  binary?: ChartYAxisBinaryConfigurationInput | null,
};

export type ChartYAxisBinaryConfigurationInput = {
  enabled?: boolean | null,
  labelOn?: string | null,
  labelOff?: string | null,
};

export type UpdateChartInput = {
  tenantId: string,
  id: string,
  title?: string | null,
  view?: ChartViewType | null,
  metrics?: Array< ChartMetricInput > | null,
  yAxis?: ChartYAxisInput | null,
  chartTenantId?: string | null,
};

export type DeleteChartInput = {
  tenantId: string,
  id: string,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelViewFilterInput = {
  tenantId?: ModelIDFilterInput | null,
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  and?: Array< ModelViewFilterInput | null > | null,
  or?: Array< ModelViewFilterInput | null > | null,
  not?: ModelViewFilterInput | null,
};

export type ModelIDFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelStringFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTenantFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  slug?: ModelStringFilterInput | null,
  legacySiteId?: ModelStringFilterInput | null,
  customPwaIcons?: ModelBooleanFilterInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
};

export type ModelBooleanFilterInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelDatapointFilterInput = {
  tenantId?: ModelIDFilterInput | null,
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  description?: ModelStringFilterInput | null,
  type?: ModelDatapointTypeFilterInput | null,
  legacyRegisterId?: ModelStringFilterInput | null,
  isSetpoint?: ModelBooleanFilterInput | null,
  isReadpoint?: ModelBooleanFilterInput | null,
  readValue?: ModelStringFilterInput | null,
  setValue?: ModelStringFilterInput | null,
  setMode?: ModelDatapointSetModeFilterInput | null,
  readTimestamp?: ModelStringFilterInput | null,
  and?: Array< ModelDatapointFilterInput | null > | null,
  or?: Array< ModelDatapointFilterInput | null > | null,
  not?: ModelDatapointFilterInput | null,
};

export type ModelDatapointTypeFilterInput = {
  eq?: DatapointType | null,
  ne?: DatapointType | null,
};

export type ModelDatapointSetModeFilterInput = {
  eq?: DatapointSetMode | null,
  ne?: DatapointSetMode | null,
};

export type ModelScheduleFilterInput = {
  tenantId?: ModelIDFilterInput | null,
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  description?: ModelStringFilterInput | null,
  and?: Array< ModelScheduleFilterInput | null > | null,
  or?: Array< ModelScheduleFilterInput | null > | null,
  not?: ModelScheduleFilterInput | null,
};

export type ModelDeploymentFilterInput = {
  tenantId?: ModelIDFilterInput | null,
  id?: ModelIDFilterInput | null,
  type?: ModelDeploymentTypeFilterInput | null,
  timeRequested?: ModelStringFilterInput | null,
  and?: Array< ModelDeploymentFilterInput | null > | null,
  or?: Array< ModelDeploymentFilterInput | null > | null,
  not?: ModelDeploymentFilterInput | null,
};

export type ModelDeploymentTypeFilterInput = {
  eq?: DeploymentType | null,
  ne?: DeploymentType | null,
};

export type ModelScheduleProfileFilterInput = {
  tenantId?: ModelIDFilterInput | null,
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  type?: ModelScheduleProfileModeFilterInput | null,
  and?: Array< ModelScheduleProfileFilterInput | null > | null,
  or?: Array< ModelScheduleProfileFilterInput | null > | null,
  not?: ModelScheduleProfileFilterInput | null,
};

export type ModelScheduleProfileModeFilterInput = {
  eq?: ScheduleProfileMode | null,
  ne?: ScheduleProfileMode | null,
};

export type ModelChartFilterInput = {
  tenantId?: ModelIDFilterInput | null,
  id?: ModelIDFilterInput | null,
  title?: ModelStringFilterInput | null,
  view?: ModelChartViewTypeFilterInput | null,
  and?: Array< ModelChartFilterInput | null > | null,
  or?: Array< ModelChartFilterInput | null > | null,
  not?: ModelChartFilterInput | null,
};

export type ModelChartViewTypeFilterInput = {
  eq?: ChartViewType | null,
  ne?: ChartViewType | null,
};

export type CreateViewMutationVariables = {
  input: CreateViewInput,
};

export type CreateViewMutation = {
  createView:  {
    __typename: "View",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    configuration:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
  } | null,
};

export type UpdateViewMutationVariables = {
  input: UpdateViewInput,
};

export type UpdateViewMutation = {
  updateView:  {
    __typename: "View",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    configuration:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
  } | null,
};

export type DeleteViewMutationVariables = {
  input: DeleteViewInput,
};

export type DeleteViewMutation = {
  deleteView:  {
    __typename: "View",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    configuration:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
  } | null,
};

export type CreateTenantMutationVariables = {
  input: CreateTenantInput,
};

export type CreateTenantMutation = {
  createTenant:  {
    __typename: "Tenant",
    id: string,
    name: string,
    slug: string,
    legacySiteId: string | null,
    customPwaIcons: boolean | null,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input: UpdateTenantInput,
};

export type UpdateTenantMutation = {
  updateTenant:  {
    __typename: "Tenant",
    id: string,
    name: string,
    slug: string,
    legacySiteId: string | null,
    customPwaIcons: boolean | null,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input: DeleteTenantInput,
};

export type DeleteTenantMutation = {
  deleteTenant:  {
    __typename: "Tenant",
    id: string,
    name: string,
    slug: string,
    legacySiteId: string | null,
    customPwaIcons: boolean | null,
  } | null,
};

export type CreateDatapointMutationVariables = {
  input: CreateDatapointInput,
};

export type CreateDatapointMutation = {
  createDatapoint:  {
    __typename: "Datapoint",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    description: string | null,
    type: DatapointType,
    legacyRegisterId: string | null,
    isSetpoint: boolean,
    isReadpoint: boolean,
    readValue: string | null,
    setValue: string | null,
    setMode: DatapointSetMode | null,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    readTimestamp: string | null,
    facets:  {
      __typename: "DatapointFacets",
      units: string | null,
      divisor: number | null,
      precision: number | null,
      scale: number | null,
      enumMapping:  Array< {
        __typename: "DatapointEnumMap",
        value: string,
        display: string,
      } > | null,
      trueText: string | null,
      falseText: string | null,
      min: number | null,
      max: number | null,
      step: number | null,
    } | null,
  } | null,
};

export type UpdateDatapointMutationVariables = {
  input: UpdateDatapointInput,
};

export type UpdateDatapointMutation = {
  updateDatapoint:  {
    __typename: "Datapoint",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    description: string | null,
    type: DatapointType,
    legacyRegisterId: string | null,
    isSetpoint: boolean,
    isReadpoint: boolean,
    readValue: string | null,
    setValue: string | null,
    setMode: DatapointSetMode | null,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    readTimestamp: string | null,
    facets:  {
      __typename: "DatapointFacets",
      units: string | null,
      divisor: number | null,
      precision: number | null,
      scale: number | null,
      enumMapping:  Array< {
        __typename: "DatapointEnumMap",
        value: string,
        display: string,
      } > | null,
      trueText: string | null,
      falseText: string | null,
      min: number | null,
      max: number | null,
      step: number | null,
    } | null,
  } | null,
};

export type DeleteDatapointMutationVariables = {
  input: DeleteDatapointInput,
};

export type DeleteDatapointMutation = {
  deleteDatapoint:  {
    __typename: "Datapoint",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    description: string | null,
    type: DatapointType,
    legacyRegisterId: string | null,
    isSetpoint: boolean,
    isReadpoint: boolean,
    readValue: string | null,
    setValue: string | null,
    setMode: DatapointSetMode | null,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    readTimestamp: string | null,
    facets:  {
      __typename: "DatapointFacets",
      units: string | null,
      divisor: number | null,
      precision: number | null,
      scale: number | null,
      enumMapping:  Array< {
        __typename: "DatapointEnumMap",
        value: string,
        display: string,
      } > | null,
      trueText: string | null,
      falseText: string | null,
      min: number | null,
      max: number | null,
      step: number | null,
    } | null,
  } | null,
};

export type CreateScheduleMutationVariables = {
  input: CreateScheduleInput,
};

export type CreateScheduleMutation = {
  createSchedule:  {
    __typename: "Schedule",
    tenantId: string,
    id: string,
    name: string,
    description: string | null,
    datapoints:  {
      __typename: "ModelDatapointConnection",
      items:  Array< {
        __typename: "Datapoint",
        tenantId: string,
        id: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
        name: string,
        description: string | null,
        type: DatapointType,
        legacyRegisterId: string | null,
        isSetpoint: boolean,
        isReadpoint: boolean,
        readValue: string | null,
        setValue: string | null,
        setMode: DatapointSetMode | null,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        readTimestamp: string | null,
        facets:  {
          __typename: "DatapointFacets",
          units: string | null,
          divisor: number | null,
          precision: number | null,
          scale: number | null,
          enumMapping:  Array< {
            __typename: "DatapointEnumMap",
            value: string,
            display: string,
          } > | null,
          trueText: string | null,
          falseText: string | null,
          min: number | null,
          max: number | null,
          step: number | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    profiles:  {
      __typename: "ModelScheduleProfileConnection",
      items:  Array< {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    activeProfile:  {
      __typename: "ScheduleProfile",
      tenantId: string,
      id: string,
      name: string,
      type: ScheduleProfileMode,
      schedule:  {
        __typename: "Schedule",
        tenantId: string,
        id: string,
        name: string,
        description: string | null,
        datapoints:  {
          __typename: "ModelDatapointConnection",
          items:  Array< {
            __typename: "Datapoint",
            tenantId: string,
            id: string,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
            name: string,
            description: string | null,
            type: DatapointType,
            legacyRegisterId: string | null,
            isSetpoint: boolean,
            isReadpoint: boolean,
            readValue: string | null,
            setValue: string | null,
            setMode: DatapointSetMode | null,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            readTimestamp: string | null,
            facets:  {
              __typename: "DatapointFacets",
              units: string | null,
              divisor: number | null,
              precision: number | null,
              scale: number | null,
              trueText: string | null,
              falseText: string | null,
              min: number | null,
              max: number | null,
              step: number | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        profiles:  {
          __typename: "ModelScheduleProfileConnection",
          items:  Array< {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        activeProfile:  {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null,
      timeslots:  Array< {
        __typename: "ScheduleTimeslot",
        key: string,
        entries:  Array< {
          __typename: "ScheduleEntry",
          datapointId: string,
          value: string,
        } >,
      } >,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type UpdateScheduleMutationVariables = {
  input: UpdateScheduleInput,
};

export type UpdateScheduleMutation = {
  updateSchedule:  {
    __typename: "Schedule",
    tenantId: string,
    id: string,
    name: string,
    description: string | null,
    datapoints:  {
      __typename: "ModelDatapointConnection",
      items:  Array< {
        __typename: "Datapoint",
        tenantId: string,
        id: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
        name: string,
        description: string | null,
        type: DatapointType,
        legacyRegisterId: string | null,
        isSetpoint: boolean,
        isReadpoint: boolean,
        readValue: string | null,
        setValue: string | null,
        setMode: DatapointSetMode | null,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        readTimestamp: string | null,
        facets:  {
          __typename: "DatapointFacets",
          units: string | null,
          divisor: number | null,
          precision: number | null,
          scale: number | null,
          enumMapping:  Array< {
            __typename: "DatapointEnumMap",
            value: string,
            display: string,
          } > | null,
          trueText: string | null,
          falseText: string | null,
          min: number | null,
          max: number | null,
          step: number | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    profiles:  {
      __typename: "ModelScheduleProfileConnection",
      items:  Array< {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    activeProfile:  {
      __typename: "ScheduleProfile",
      tenantId: string,
      id: string,
      name: string,
      type: ScheduleProfileMode,
      schedule:  {
        __typename: "Schedule",
        tenantId: string,
        id: string,
        name: string,
        description: string | null,
        datapoints:  {
          __typename: "ModelDatapointConnection",
          items:  Array< {
            __typename: "Datapoint",
            tenantId: string,
            id: string,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
            name: string,
            description: string | null,
            type: DatapointType,
            legacyRegisterId: string | null,
            isSetpoint: boolean,
            isReadpoint: boolean,
            readValue: string | null,
            setValue: string | null,
            setMode: DatapointSetMode | null,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            readTimestamp: string | null,
            facets:  {
              __typename: "DatapointFacets",
              units: string | null,
              divisor: number | null,
              precision: number | null,
              scale: number | null,
              trueText: string | null,
              falseText: string | null,
              min: number | null,
              max: number | null,
              step: number | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        profiles:  {
          __typename: "ModelScheduleProfileConnection",
          items:  Array< {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        activeProfile:  {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null,
      timeslots:  Array< {
        __typename: "ScheduleTimeslot",
        key: string,
        entries:  Array< {
          __typename: "ScheduleEntry",
          datapointId: string,
          value: string,
        } >,
      } >,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type DeleteScheduleMutationVariables = {
  input: DeleteScheduleInput,
};

export type DeleteScheduleMutation = {
  deleteSchedule:  {
    __typename: "Schedule",
    tenantId: string,
    id: string,
    name: string,
    description: string | null,
    datapoints:  {
      __typename: "ModelDatapointConnection",
      items:  Array< {
        __typename: "Datapoint",
        tenantId: string,
        id: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
        name: string,
        description: string | null,
        type: DatapointType,
        legacyRegisterId: string | null,
        isSetpoint: boolean,
        isReadpoint: boolean,
        readValue: string | null,
        setValue: string | null,
        setMode: DatapointSetMode | null,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        readTimestamp: string | null,
        facets:  {
          __typename: "DatapointFacets",
          units: string | null,
          divisor: number | null,
          precision: number | null,
          scale: number | null,
          enumMapping:  Array< {
            __typename: "DatapointEnumMap",
            value: string,
            display: string,
          } > | null,
          trueText: string | null,
          falseText: string | null,
          min: number | null,
          max: number | null,
          step: number | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    profiles:  {
      __typename: "ModelScheduleProfileConnection",
      items:  Array< {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    activeProfile:  {
      __typename: "ScheduleProfile",
      tenantId: string,
      id: string,
      name: string,
      type: ScheduleProfileMode,
      schedule:  {
        __typename: "Schedule",
        tenantId: string,
        id: string,
        name: string,
        description: string | null,
        datapoints:  {
          __typename: "ModelDatapointConnection",
          items:  Array< {
            __typename: "Datapoint",
            tenantId: string,
            id: string,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
            name: string,
            description: string | null,
            type: DatapointType,
            legacyRegisterId: string | null,
            isSetpoint: boolean,
            isReadpoint: boolean,
            readValue: string | null,
            setValue: string | null,
            setMode: DatapointSetMode | null,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            readTimestamp: string | null,
            facets:  {
              __typename: "DatapointFacets",
              units: string | null,
              divisor: number | null,
              precision: number | null,
              scale: number | null,
              trueText: string | null,
              falseText: string | null,
              min: number | null,
              max: number | null,
              step: number | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        profiles:  {
          __typename: "ModelScheduleProfileConnection",
          items:  Array< {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        activeProfile:  {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null,
      timeslots:  Array< {
        __typename: "ScheduleTimeslot",
        key: string,
        entries:  Array< {
          __typename: "ScheduleEntry",
          datapointId: string,
          value: string,
        } >,
      } >,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type CreateDeploymentMutationVariables = {
  input: CreateDeploymentInput,
};

export type CreateDeploymentMutation = {
  createDeployment:  {
    __typename: "Deployment",
    tenantId: string,
    id: string,
    type: DeploymentType,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    timeRequested: string,
  } | null,
};

export type UpdateDeploymentMutationVariables = {
  input: UpdateDeploymentInput,
};

export type UpdateDeploymentMutation = {
  updateDeployment:  {
    __typename: "Deployment",
    tenantId: string,
    id: string,
    type: DeploymentType,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    timeRequested: string,
  } | null,
};

export type DeleteDeploymentMutationVariables = {
  input: DeleteDeploymentInput,
};

export type DeleteDeploymentMutation = {
  deleteDeployment:  {
    __typename: "Deployment",
    tenantId: string,
    id: string,
    type: DeploymentType,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    timeRequested: string,
  } | null,
};

export type CreateScheduleProfileMutationVariables = {
  input: CreateScheduleProfileInput,
};

export type CreateScheduleProfileMutation = {
  createScheduleProfile:  {
    __typename: "ScheduleProfile",
    tenantId: string,
    id: string,
    name: string,
    type: ScheduleProfileMode,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    timeslots:  Array< {
      __typename: "ScheduleTimeslot",
      key: string,
      entries:  Array< {
        __typename: "ScheduleEntry",
        datapointId: string,
        value: string,
      } >,
    } >,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type UpdateScheduleProfileMutationVariables = {
  input: UpdateScheduleProfileInput,
};

export type UpdateScheduleProfileMutation = {
  updateScheduleProfile:  {
    __typename: "ScheduleProfile",
    tenantId: string,
    id: string,
    name: string,
    type: ScheduleProfileMode,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    timeslots:  Array< {
      __typename: "ScheduleTimeslot",
      key: string,
      entries:  Array< {
        __typename: "ScheduleEntry",
        datapointId: string,
        value: string,
      } >,
    } >,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type DeleteScheduleProfileMutationVariables = {
  input: DeleteScheduleProfileInput,
};

export type DeleteScheduleProfileMutation = {
  deleteScheduleProfile:  {
    __typename: "ScheduleProfile",
    tenantId: string,
    id: string,
    name: string,
    type: ScheduleProfileMode,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    timeslots:  Array< {
      __typename: "ScheduleTimeslot",
      key: string,
      entries:  Array< {
        __typename: "ScheduleEntry",
        datapointId: string,
        value: string,
      } >,
    } >,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type CreateChartMutationVariables = {
  input: CreateChartInput,
};

export type CreateChartMutation = {
  createChart:  {
    __typename: "Chart",
    tenantId: string,
    id: string,
    title: string,
    view: ChartViewType,
    metrics:  Array< {
      __typename: "ChartMetric",
      datapointId: string,
      statistic: ChartMetricStatisticValues,
      period: number,
      yAxis: ChartYAxisMetricValues | null,
      color: string | null,
      label: string | null,
      displayType: ChartMetricDisplayTypes | null,
    } >,
    yAxis:  {
      __typename: "ChartYAxis",
      left:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
      right:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
    } | null,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type UpdateChartMutationVariables = {
  input: UpdateChartInput,
};

export type UpdateChartMutation = {
  updateChart:  {
    __typename: "Chart",
    tenantId: string,
    id: string,
    title: string,
    view: ChartViewType,
    metrics:  Array< {
      __typename: "ChartMetric",
      datapointId: string,
      statistic: ChartMetricStatisticValues,
      period: number,
      yAxis: ChartYAxisMetricValues | null,
      color: string | null,
      label: string | null,
      displayType: ChartMetricDisplayTypes | null,
    } >,
    yAxis:  {
      __typename: "ChartYAxis",
      left:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
      right:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
    } | null,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type DeleteChartMutationVariables = {
  input: DeleteChartInput,
};

export type DeleteChartMutation = {
  deleteChart:  {
    __typename: "Chart",
    tenantId: string,
    id: string,
    title: string,
    view: ChartViewType,
    metrics:  Array< {
      __typename: "ChartMetric",
      datapointId: string,
      statistic: ChartMetricStatisticValues,
      period: number,
      yAxis: ChartYAxisMetricValues | null,
      color: string | null,
      label: string | null,
      displayType: ChartMetricDisplayTypes | null,
    } >,
    yAxis:  {
      __typename: "ChartYAxis",
      left:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
      right:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
    } | null,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type GetViewQueryVariables = {
  tenantId: string,
  id: string,
};

export type GetViewQuery = {
  getView:  {
    __typename: "View",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    configuration:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
  } | null,
};

export type ListViewsQueryVariables = {
  tenantId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelViewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListViewsQuery = {
  listViews:  {
    __typename: "ModelViewConnection",
    items:  Array< {
      __typename: "View",
      tenantId: string,
      id: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
      name: string,
      configuration:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id: string,
};

export type GetTenantQuery = {
  getTenant:  {
    __typename: "Tenant",
    id: string,
    name: string,
    slug: string,
    legacySiteId: string | null,
    customPwaIcons: boolean | null,
  } | null,
};

export type ListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsQuery = {
  listTenants:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetDatapointQueryVariables = {
  tenantId: string,
  id: string,
};

export type GetDatapointQuery = {
  getDatapoint:  {
    __typename: "Datapoint",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    description: string | null,
    type: DatapointType,
    legacyRegisterId: string | null,
    isSetpoint: boolean,
    isReadpoint: boolean,
    readValue: string | null,
    setValue: string | null,
    setMode: DatapointSetMode | null,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    readTimestamp: string | null,
    facets:  {
      __typename: "DatapointFacets",
      units: string | null,
      divisor: number | null,
      precision: number | null,
      scale: number | null,
      enumMapping:  Array< {
        __typename: "DatapointEnumMap",
        value: string,
        display: string,
      } > | null,
      trueText: string | null,
      falseText: string | null,
      min: number | null,
      max: number | null,
      step: number | null,
    } | null,
  } | null,
};

export type ListDatapointsQueryVariables = {
  tenantId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelDatapointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDatapointsQuery = {
  listDatapoints:  {
    __typename: "ModelDatapointConnection",
    items:  Array< {
      __typename: "Datapoint",
      tenantId: string,
      id: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
      name: string,
      description: string | null,
      type: DatapointType,
      legacyRegisterId: string | null,
      isSetpoint: boolean,
      isReadpoint: boolean,
      readValue: string | null,
      setValue: string | null,
      setMode: DatapointSetMode | null,
      schedule:  {
        __typename: "Schedule",
        tenantId: string,
        id: string,
        name: string,
        description: string | null,
        datapoints:  {
          __typename: "ModelDatapointConnection",
          items:  Array< {
            __typename: "Datapoint",
            tenantId: string,
            id: string,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
            name: string,
            description: string | null,
            type: DatapointType,
            legacyRegisterId: string | null,
            isSetpoint: boolean,
            isReadpoint: boolean,
            readValue: string | null,
            setValue: string | null,
            setMode: DatapointSetMode | null,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            readTimestamp: string | null,
            facets:  {
              __typename: "DatapointFacets",
              units: string | null,
              divisor: number | null,
              precision: number | null,
              scale: number | null,
              trueText: string | null,
              falseText: string | null,
              min: number | null,
              max: number | null,
              step: number | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        profiles:  {
          __typename: "ModelScheduleProfileConnection",
          items:  Array< {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        activeProfile:  {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null,
      readTimestamp: string | null,
      facets:  {
        __typename: "DatapointFacets",
        units: string | null,
        divisor: number | null,
        precision: number | null,
        scale: number | null,
        enumMapping:  Array< {
          __typename: "DatapointEnumMap",
          value: string,
          display: string,
        } > | null,
        trueText: string | null,
        falseText: string | null,
        min: number | null,
        max: number | null,
        step: number | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetScheduleQueryVariables = {
  tenantId: string,
  id: string,
};

export type GetScheduleQuery = {
  getSchedule:  {
    __typename: "Schedule",
    tenantId: string,
    id: string,
    name: string,
    description: string | null,
    datapoints:  {
      __typename: "ModelDatapointConnection",
      items:  Array< {
        __typename: "Datapoint",
        tenantId: string,
        id: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
        name: string,
        description: string | null,
        type: DatapointType,
        legacyRegisterId: string | null,
        isSetpoint: boolean,
        isReadpoint: boolean,
        readValue: string | null,
        setValue: string | null,
        setMode: DatapointSetMode | null,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        readTimestamp: string | null,
        facets:  {
          __typename: "DatapointFacets",
          units: string | null,
          divisor: number | null,
          precision: number | null,
          scale: number | null,
          enumMapping:  Array< {
            __typename: "DatapointEnumMap",
            value: string,
            display: string,
          } > | null,
          trueText: string | null,
          falseText: string | null,
          min: number | null,
          max: number | null,
          step: number | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    profiles:  {
      __typename: "ModelScheduleProfileConnection",
      items:  Array< {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    activeProfile:  {
      __typename: "ScheduleProfile",
      tenantId: string,
      id: string,
      name: string,
      type: ScheduleProfileMode,
      schedule:  {
        __typename: "Schedule",
        tenantId: string,
        id: string,
        name: string,
        description: string | null,
        datapoints:  {
          __typename: "ModelDatapointConnection",
          items:  Array< {
            __typename: "Datapoint",
            tenantId: string,
            id: string,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
            name: string,
            description: string | null,
            type: DatapointType,
            legacyRegisterId: string | null,
            isSetpoint: boolean,
            isReadpoint: boolean,
            readValue: string | null,
            setValue: string | null,
            setMode: DatapointSetMode | null,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            readTimestamp: string | null,
            facets:  {
              __typename: "DatapointFacets",
              units: string | null,
              divisor: number | null,
              precision: number | null,
              scale: number | null,
              trueText: string | null,
              falseText: string | null,
              min: number | null,
              max: number | null,
              step: number | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        profiles:  {
          __typename: "ModelScheduleProfileConnection",
          items:  Array< {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        activeProfile:  {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null,
      timeslots:  Array< {
        __typename: "ScheduleTimeslot",
        key: string,
        entries:  Array< {
          __typename: "ScheduleEntry",
          datapointId: string,
          value: string,
        } >,
      } >,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type ListSchedulesQueryVariables = {
  tenantId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSchedulesQuery = {
  listSchedules:  {
    __typename: "ModelScheduleConnection",
    items:  Array< {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetDeploymentQueryVariables = {
  tenantId: string,
  id: string,
};

export type GetDeploymentQuery = {
  getDeployment:  {
    __typename: "Deployment",
    tenantId: string,
    id: string,
    type: DeploymentType,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    timeRequested: string,
  } | null,
};

export type ListDeploymentsQueryVariables = {
  tenantId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelDeploymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDeploymentsQuery = {
  listDeployments:  {
    __typename: "ModelDeploymentConnection",
    items:  Array< {
      __typename: "Deployment",
      tenantId: string,
      id: string,
      type: DeploymentType,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
      timeRequested: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetScheduleProfileQueryVariables = {
  tenantId: string,
  id: string,
};

export type GetScheduleProfileQuery = {
  getScheduleProfile:  {
    __typename: "ScheduleProfile",
    tenantId: string,
    id: string,
    name: string,
    type: ScheduleProfileMode,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    timeslots:  Array< {
      __typename: "ScheduleTimeslot",
      key: string,
      entries:  Array< {
        __typename: "ScheduleEntry",
        datapointId: string,
        value: string,
      } >,
    } >,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type ListScheduleProfilesQueryVariables = {
  tenantId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelScheduleProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListScheduleProfilesQuery = {
  listScheduleProfiles:  {
    __typename: "ModelScheduleProfileConnection",
    items:  Array< {
      __typename: "ScheduleProfile",
      tenantId: string,
      id: string,
      name: string,
      type: ScheduleProfileMode,
      schedule:  {
        __typename: "Schedule",
        tenantId: string,
        id: string,
        name: string,
        description: string | null,
        datapoints:  {
          __typename: "ModelDatapointConnection",
          items:  Array< {
            __typename: "Datapoint",
            tenantId: string,
            id: string,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
            name: string,
            description: string | null,
            type: DatapointType,
            legacyRegisterId: string | null,
            isSetpoint: boolean,
            isReadpoint: boolean,
            readValue: string | null,
            setValue: string | null,
            setMode: DatapointSetMode | null,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            readTimestamp: string | null,
            facets:  {
              __typename: "DatapointFacets",
              units: string | null,
              divisor: number | null,
              precision: number | null,
              scale: number | null,
              trueText: string | null,
              falseText: string | null,
              min: number | null,
              max: number | null,
              step: number | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        profiles:  {
          __typename: "ModelScheduleProfileConnection",
          items:  Array< {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        activeProfile:  {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null,
      timeslots:  Array< {
        __typename: "ScheduleTimeslot",
        key: string,
        entries:  Array< {
          __typename: "ScheduleEntry",
          datapointId: string,
          value: string,
        } >,
      } >,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetChartQueryVariables = {
  tenantId: string,
  id: string,
};

export type GetChartQuery = {
  getChart:  {
    __typename: "Chart",
    tenantId: string,
    id: string,
    title: string,
    view: ChartViewType,
    metrics:  Array< {
      __typename: "ChartMetric",
      datapointId: string,
      statistic: ChartMetricStatisticValues,
      period: number,
      yAxis: ChartYAxisMetricValues | null,
      color: string | null,
      label: string | null,
      displayType: ChartMetricDisplayTypes | null,
    } >,
    yAxis:  {
      __typename: "ChartYAxis",
      left:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
      right:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
    } | null,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type ListChartsQueryVariables = {
  tenantId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelChartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListChartsQuery = {
  listCharts:  {
    __typename: "ModelChartConnection",
    items:  Array< {
      __typename: "Chart",
      tenantId: string,
      id: string,
      title: string,
      view: ChartViewType,
      metrics:  Array< {
        __typename: "ChartMetric",
        datapointId: string,
        statistic: ChartMetricStatisticValues,
        period: number,
        yAxis: ChartYAxisMetricValues | null,
        color: string | null,
        label: string | null,
        displayType: ChartMetricDisplayTypes | null,
      } >,
      yAxis:  {
        __typename: "ChartYAxis",
        left:  {
          __typename: "ChartYAxisConfiguration",
          min: number | null,
          max: number | null,
          label: string | null,
          binary:  {
            __typename: "ChartYAxisBinaryConfiguration",
            enabled: boolean | null,
            labelOn: string | null,
            labelOff: string | null,
          } | null,
        } | null,
        right:  {
          __typename: "ChartYAxisConfiguration",
          min: number | null,
          max: number | null,
          label: string | null,
          binary:  {
            __typename: "ChartYAxisBinaryConfiguration",
            enabled: boolean | null,
            labelOn: string | null,
            labelOff: string | null,
          } | null,
        } | null,
      } | null,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OnCreateViewSubscription = {
  onCreateView:  {
    __typename: "View",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    configuration:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
  } | null,
};

export type OnUpdateViewSubscription = {
  onUpdateView:  {
    __typename: "View",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    configuration:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
  } | null,
};

export type OnDeleteViewSubscription = {
  onDeleteView:  {
    __typename: "View",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    configuration:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
  } | null,
};

export type OnCreateTenantSubscription = {
  onCreateTenant:  {
    __typename: "Tenant",
    id: string,
    name: string,
    slug: string,
    legacySiteId: string | null,
    customPwaIcons: boolean | null,
  } | null,
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant:  {
    __typename: "Tenant",
    id: string,
    name: string,
    slug: string,
    legacySiteId: string | null,
    customPwaIcons: boolean | null,
  } | null,
};

export type OnDeleteTenantSubscription = {
  onDeleteTenant:  {
    __typename: "Tenant",
    id: string,
    name: string,
    slug: string,
    legacySiteId: string | null,
    customPwaIcons: boolean | null,
  } | null,
};

export type OnCreateDatapointSubscription = {
  onCreateDatapoint:  {
    __typename: "Datapoint",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    description: string | null,
    type: DatapointType,
    legacyRegisterId: string | null,
    isSetpoint: boolean,
    isReadpoint: boolean,
    readValue: string | null,
    setValue: string | null,
    setMode: DatapointSetMode | null,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    readTimestamp: string | null,
    facets:  {
      __typename: "DatapointFacets",
      units: string | null,
      divisor: number | null,
      precision: number | null,
      scale: number | null,
      enumMapping:  Array< {
        __typename: "DatapointEnumMap",
        value: string,
        display: string,
      } > | null,
      trueText: string | null,
      falseText: string | null,
      min: number | null,
      max: number | null,
      step: number | null,
    } | null,
  } | null,
};

export type OnUpdateDatapointSubscription = {
  onUpdateDatapoint:  {
    __typename: "Datapoint",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    description: string | null,
    type: DatapointType,
    legacyRegisterId: string | null,
    isSetpoint: boolean,
    isReadpoint: boolean,
    readValue: string | null,
    setValue: string | null,
    setMode: DatapointSetMode | null,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    readTimestamp: string | null,
    facets:  {
      __typename: "DatapointFacets",
      units: string | null,
      divisor: number | null,
      precision: number | null,
      scale: number | null,
      enumMapping:  Array< {
        __typename: "DatapointEnumMap",
        value: string,
        display: string,
      } > | null,
      trueText: string | null,
      falseText: string | null,
      min: number | null,
      max: number | null,
      step: number | null,
    } | null,
  } | null,
};

export type OnDeleteDatapointSubscription = {
  onDeleteDatapoint:  {
    __typename: "Datapoint",
    tenantId: string,
    id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    name: string,
    description: string | null,
    type: DatapointType,
    legacyRegisterId: string | null,
    isSetpoint: boolean,
    isReadpoint: boolean,
    readValue: string | null,
    setValue: string | null,
    setMode: DatapointSetMode | null,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    readTimestamp: string | null,
    facets:  {
      __typename: "DatapointFacets",
      units: string | null,
      divisor: number | null,
      precision: number | null,
      scale: number | null,
      enumMapping:  Array< {
        __typename: "DatapointEnumMap",
        value: string,
        display: string,
      } > | null,
      trueText: string | null,
      falseText: string | null,
      min: number | null,
      max: number | null,
      step: number | null,
    } | null,
  } | null,
};

export type OnCreateScheduleSubscription = {
  onCreateSchedule:  {
    __typename: "Schedule",
    tenantId: string,
    id: string,
    name: string,
    description: string | null,
    datapoints:  {
      __typename: "ModelDatapointConnection",
      items:  Array< {
        __typename: "Datapoint",
        tenantId: string,
        id: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
        name: string,
        description: string | null,
        type: DatapointType,
        legacyRegisterId: string | null,
        isSetpoint: boolean,
        isReadpoint: boolean,
        readValue: string | null,
        setValue: string | null,
        setMode: DatapointSetMode | null,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        readTimestamp: string | null,
        facets:  {
          __typename: "DatapointFacets",
          units: string | null,
          divisor: number | null,
          precision: number | null,
          scale: number | null,
          enumMapping:  Array< {
            __typename: "DatapointEnumMap",
            value: string,
            display: string,
          } > | null,
          trueText: string | null,
          falseText: string | null,
          min: number | null,
          max: number | null,
          step: number | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    profiles:  {
      __typename: "ModelScheduleProfileConnection",
      items:  Array< {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    activeProfile:  {
      __typename: "ScheduleProfile",
      tenantId: string,
      id: string,
      name: string,
      type: ScheduleProfileMode,
      schedule:  {
        __typename: "Schedule",
        tenantId: string,
        id: string,
        name: string,
        description: string | null,
        datapoints:  {
          __typename: "ModelDatapointConnection",
          items:  Array< {
            __typename: "Datapoint",
            tenantId: string,
            id: string,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
            name: string,
            description: string | null,
            type: DatapointType,
            legacyRegisterId: string | null,
            isSetpoint: boolean,
            isReadpoint: boolean,
            readValue: string | null,
            setValue: string | null,
            setMode: DatapointSetMode | null,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            readTimestamp: string | null,
            facets:  {
              __typename: "DatapointFacets",
              units: string | null,
              divisor: number | null,
              precision: number | null,
              scale: number | null,
              trueText: string | null,
              falseText: string | null,
              min: number | null,
              max: number | null,
              step: number | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        profiles:  {
          __typename: "ModelScheduleProfileConnection",
          items:  Array< {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        activeProfile:  {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null,
      timeslots:  Array< {
        __typename: "ScheduleTimeslot",
        key: string,
        entries:  Array< {
          __typename: "ScheduleEntry",
          datapointId: string,
          value: string,
        } >,
      } >,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type OnUpdateScheduleSubscription = {
  onUpdateSchedule:  {
    __typename: "Schedule",
    tenantId: string,
    id: string,
    name: string,
    description: string | null,
    datapoints:  {
      __typename: "ModelDatapointConnection",
      items:  Array< {
        __typename: "Datapoint",
        tenantId: string,
        id: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
        name: string,
        description: string | null,
        type: DatapointType,
        legacyRegisterId: string | null,
        isSetpoint: boolean,
        isReadpoint: boolean,
        readValue: string | null,
        setValue: string | null,
        setMode: DatapointSetMode | null,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        readTimestamp: string | null,
        facets:  {
          __typename: "DatapointFacets",
          units: string | null,
          divisor: number | null,
          precision: number | null,
          scale: number | null,
          enumMapping:  Array< {
            __typename: "DatapointEnumMap",
            value: string,
            display: string,
          } > | null,
          trueText: string | null,
          falseText: string | null,
          min: number | null,
          max: number | null,
          step: number | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    profiles:  {
      __typename: "ModelScheduleProfileConnection",
      items:  Array< {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    activeProfile:  {
      __typename: "ScheduleProfile",
      tenantId: string,
      id: string,
      name: string,
      type: ScheduleProfileMode,
      schedule:  {
        __typename: "Schedule",
        tenantId: string,
        id: string,
        name: string,
        description: string | null,
        datapoints:  {
          __typename: "ModelDatapointConnection",
          items:  Array< {
            __typename: "Datapoint",
            tenantId: string,
            id: string,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
            name: string,
            description: string | null,
            type: DatapointType,
            legacyRegisterId: string | null,
            isSetpoint: boolean,
            isReadpoint: boolean,
            readValue: string | null,
            setValue: string | null,
            setMode: DatapointSetMode | null,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            readTimestamp: string | null,
            facets:  {
              __typename: "DatapointFacets",
              units: string | null,
              divisor: number | null,
              precision: number | null,
              scale: number | null,
              trueText: string | null,
              falseText: string | null,
              min: number | null,
              max: number | null,
              step: number | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        profiles:  {
          __typename: "ModelScheduleProfileConnection",
          items:  Array< {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        activeProfile:  {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null,
      timeslots:  Array< {
        __typename: "ScheduleTimeslot",
        key: string,
        entries:  Array< {
          __typename: "ScheduleEntry",
          datapointId: string,
          value: string,
        } >,
      } >,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type OnDeleteScheduleSubscription = {
  onDeleteSchedule:  {
    __typename: "Schedule",
    tenantId: string,
    id: string,
    name: string,
    description: string | null,
    datapoints:  {
      __typename: "ModelDatapointConnection",
      items:  Array< {
        __typename: "Datapoint",
        tenantId: string,
        id: string,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
        name: string,
        description: string | null,
        type: DatapointType,
        legacyRegisterId: string | null,
        isSetpoint: boolean,
        isReadpoint: boolean,
        readValue: string | null,
        setValue: string | null,
        setMode: DatapointSetMode | null,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        readTimestamp: string | null,
        facets:  {
          __typename: "DatapointFacets",
          units: string | null,
          divisor: number | null,
          precision: number | null,
          scale: number | null,
          enumMapping:  Array< {
            __typename: "DatapointEnumMap",
            value: string,
            display: string,
          } > | null,
          trueText: string | null,
          falseText: string | null,
          min: number | null,
          max: number | null,
          step: number | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    profiles:  {
      __typename: "ModelScheduleProfileConnection",
      items:  Array< {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    activeProfile:  {
      __typename: "ScheduleProfile",
      tenantId: string,
      id: string,
      name: string,
      type: ScheduleProfileMode,
      schedule:  {
        __typename: "Schedule",
        tenantId: string,
        id: string,
        name: string,
        description: string | null,
        datapoints:  {
          __typename: "ModelDatapointConnection",
          items:  Array< {
            __typename: "Datapoint",
            tenantId: string,
            id: string,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
            name: string,
            description: string | null,
            type: DatapointType,
            legacyRegisterId: string | null,
            isSetpoint: boolean,
            isReadpoint: boolean,
            readValue: string | null,
            setValue: string | null,
            setMode: DatapointSetMode | null,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            readTimestamp: string | null,
            facets:  {
              __typename: "DatapointFacets",
              units: string | null,
              divisor: number | null,
              precision: number | null,
              scale: number | null,
              trueText: string | null,
              falseText: string | null,
              min: number | null,
              max: number | null,
              step: number | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        profiles:  {
          __typename: "ModelScheduleProfileConnection",
          items:  Array< {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null > | null,
          nextToken: string | null,
        } | null,
        activeProfile:  {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      } | null,
      timeslots:  Array< {
        __typename: "ScheduleTimeslot",
        key: string,
        entries:  Array< {
          __typename: "ScheduleEntry",
          datapointId: string,
          value: string,
        } >,
      } >,
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type OnCreateDeploymentSubscription = {
  onCreateDeployment:  {
    __typename: "Deployment",
    tenantId: string,
    id: string,
    type: DeploymentType,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    timeRequested: string,
  } | null,
};

export type OnUpdateDeploymentSubscription = {
  onUpdateDeployment:  {
    __typename: "Deployment",
    tenantId: string,
    id: string,
    type: DeploymentType,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    timeRequested: string,
  } | null,
};

export type OnDeleteDeploymentSubscription = {
  onDeleteDeployment:  {
    __typename: "Deployment",
    tenantId: string,
    id: string,
    type: DeploymentType,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
    timeRequested: string,
  } | null,
};

export type OnCreateScheduleProfileSubscription = {
  onCreateScheduleProfile:  {
    __typename: "ScheduleProfile",
    tenantId: string,
    id: string,
    name: string,
    type: ScheduleProfileMode,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    timeslots:  Array< {
      __typename: "ScheduleTimeslot",
      key: string,
      entries:  Array< {
        __typename: "ScheduleEntry",
        datapointId: string,
        value: string,
      } >,
    } >,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type OnUpdateScheduleProfileSubscription = {
  onUpdateScheduleProfile:  {
    __typename: "ScheduleProfile",
    tenantId: string,
    id: string,
    name: string,
    type: ScheduleProfileMode,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    timeslots:  Array< {
      __typename: "ScheduleTimeslot",
      key: string,
      entries:  Array< {
        __typename: "ScheduleEntry",
        datapointId: string,
        value: string,
      } >,
    } >,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type OnDeleteScheduleProfileSubscription = {
  onDeleteScheduleProfile:  {
    __typename: "ScheduleProfile",
    tenantId: string,
    id: string,
    name: string,
    type: ScheduleProfileMode,
    schedule:  {
      __typename: "Schedule",
      tenantId: string,
      id: string,
      name: string,
      description: string | null,
      datapoints:  {
        __typename: "ModelDatapointConnection",
        items:  Array< {
          __typename: "Datapoint",
          tenantId: string,
          id: string,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
          name: string,
          description: string | null,
          type: DatapointType,
          legacyRegisterId: string | null,
          isSetpoint: boolean,
          isReadpoint: boolean,
          readValue: string | null,
          setValue: string | null,
          setMode: DatapointSetMode | null,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          readTimestamp: string | null,
          facets:  {
            __typename: "DatapointFacets",
            units: string | null,
            divisor: number | null,
            precision: number | null,
            scale: number | null,
            enumMapping:  Array< {
              __typename: "DatapointEnumMap",
              value: string,
              display: string,
            } > | null,
            trueText: string | null,
            falseText: string | null,
            min: number | null,
            max: number | null,
            step: number | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      profiles:  {
        __typename: "ModelScheduleProfileConnection",
        items:  Array< {
          __typename: "ScheduleProfile",
          tenantId: string,
          id: string,
          name: string,
          type: ScheduleProfileMode,
          schedule:  {
            __typename: "Schedule",
            tenantId: string,
            id: string,
            name: string,
            description: string | null,
            datapoints:  {
              __typename: "ModelDatapointConnection",
              nextToken: string | null,
            } | null,
            profiles:  {
              __typename: "ModelScheduleProfileConnection",
              nextToken: string | null,
            } | null,
            activeProfile:  {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            },
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          } | null,
          timeslots:  Array< {
            __typename: "ScheduleTimeslot",
            key: string,
            entries:  Array< {
              __typename: "ScheduleEntry",
              datapointId: string,
              value: string,
            } >,
          } >,
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      activeProfile:  {
        __typename: "ScheduleProfile",
        tenantId: string,
        id: string,
        name: string,
        type: ScheduleProfileMode,
        schedule:  {
          __typename: "Schedule",
          tenantId: string,
          id: string,
          name: string,
          description: string | null,
          datapoints:  {
            __typename: "ModelDatapointConnection",
            items:  Array< {
              __typename: "Datapoint",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
              type: DatapointType,
              legacyRegisterId: string | null,
              isSetpoint: boolean,
              isReadpoint: boolean,
              readValue: string | null,
              setValue: string | null,
              setMode: DatapointSetMode | null,
              readTimestamp: string | null,
            } | null > | null,
            nextToken: string | null,
          } | null,
          profiles:  {
            __typename: "ModelScheduleProfileConnection",
            items:  Array< {
              __typename: "ScheduleProfile",
              tenantId: string,
              id: string,
              name: string,
              type: ScheduleProfileMode,
            } | null > | null,
            nextToken: string | null,
          } | null,
          activeProfile:  {
            __typename: "ScheduleProfile",
            tenantId: string,
            id: string,
            name: string,
            type: ScheduleProfileMode,
            schedule:  {
              __typename: "Schedule",
              tenantId: string,
              id: string,
              name: string,
              description: string | null,
            } | null,
            timeslots:  Array< {
              __typename: "ScheduleTimeslot",
              key: string,
            } >,
            tenant:  {
              __typename: "Tenant",
              id: string,
              name: string,
              slug: string,
              legacySiteId: string | null,
              customPwaIcons: boolean | null,
            } | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            slug: string,
            legacySiteId: string | null,
            customPwaIcons: boolean | null,
          } | null,
        } | null,
        timeslots:  Array< {
          __typename: "ScheduleTimeslot",
          key: string,
          entries:  Array< {
            __typename: "ScheduleEntry",
            datapointId: string,
            value: string,
          } >,
        } >,
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          slug: string,
          legacySiteId: string | null,
          customPwaIcons: boolean | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        slug: string,
        legacySiteId: string | null,
        customPwaIcons: boolean | null,
      } | null,
    } | null,
    timeslots:  Array< {
      __typename: "ScheduleTimeslot",
      key: string,
      entries:  Array< {
        __typename: "ScheduleEntry",
        datapointId: string,
        value: string,
      } >,
    } >,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type OnCreateChartSubscription = {
  onCreateChart:  {
    __typename: "Chart",
    tenantId: string,
    id: string,
    title: string,
    view: ChartViewType,
    metrics:  Array< {
      __typename: "ChartMetric",
      datapointId: string,
      statistic: ChartMetricStatisticValues,
      period: number,
      yAxis: ChartYAxisMetricValues | null,
      color: string | null,
      label: string | null,
      displayType: ChartMetricDisplayTypes | null,
    } >,
    yAxis:  {
      __typename: "ChartYAxis",
      left:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
      right:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
    } | null,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type OnUpdateChartSubscription = {
  onUpdateChart:  {
    __typename: "Chart",
    tenantId: string,
    id: string,
    title: string,
    view: ChartViewType,
    metrics:  Array< {
      __typename: "ChartMetric",
      datapointId: string,
      statistic: ChartMetricStatisticValues,
      period: number,
      yAxis: ChartYAxisMetricValues | null,
      color: string | null,
      label: string | null,
      displayType: ChartMetricDisplayTypes | null,
    } >,
    yAxis:  {
      __typename: "ChartYAxis",
      left:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
      right:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
    } | null,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};

export type OnDeleteChartSubscription = {
  onDeleteChart:  {
    __typename: "Chart",
    tenantId: string,
    id: string,
    title: string,
    view: ChartViewType,
    metrics:  Array< {
      __typename: "ChartMetric",
      datapointId: string,
      statistic: ChartMetricStatisticValues,
      period: number,
      yAxis: ChartYAxisMetricValues | null,
      color: string | null,
      label: string | null,
      displayType: ChartMetricDisplayTypes | null,
    } >,
    yAxis:  {
      __typename: "ChartYAxis",
      left:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
      right:  {
        __typename: "ChartYAxisConfiguration",
        min: number | null,
        max: number | null,
        label: string | null,
        binary:  {
          __typename: "ChartYAxisBinaryConfiguration",
          enabled: boolean | null,
          labelOn: string | null,
          labelOff: string | null,
        } | null,
      } | null,
    } | null,
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      slug: string,
      legacySiteId: string | null,
      customPwaIcons: boolean | null,
    } | null,
  } | null,
};
