import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import ListItemText from "@material-ui/core/ListItemText";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import WebIcon from '@material-ui/icons/Web';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import CategoryIcon from '@material-ui/icons/Category';
import ListSubheader from '@material-ui/core/ListSubheader';
import {Link as RouterLink} from 'react-router-dom';


import React from "react";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        padding: "20px",
    },
    menuHeading: {
        padding: "5px",
        fontWeight: "bold",
    }
});


class MenuDrawer extends React.Component {

    render() {
        const classes = this.props.classes;

        const itemAttr = {
            onClick: () => this.props.drawerChangeState(false),
            button: true,
            component: RouterLink,
        }

        const tenantList = this.props.tenants.map(x => (
            <ListItem button key={x.slug} onClick={() => {
                this.props.setTenant(x);
                this.props.drawerChangeState(false)
            }}>
                <ListItemIcon><PersonIcon/></ListItemIcon>
                <ListItemText primary={x.name}/>
            </ListItem>
        ));

        return (
            <div className={classes.root}>
                <SwipeableDrawer
                    open={this.props.drawerOpen}
                    onOpen={() => this.props.drawerChangeState(true)}
                    onClose={() => this.props.drawerChangeState(false)}
                >
                    <Typography variant="h4" className={classes.title}>
                        Electair Connect
                    </Typography>
                    <Divider/>
                    <List>
                        <ListItem {...itemAttr} key="Dashboard" to="/dashboard">
                            <ListItemIcon><DashboardIcon/></ListItemIcon>
                            <ListItemText primary="Dashboard"/>
                        </ListItem>
                        <ListItem {...itemAttr} key="Views" to="/views">
                            <ListItemIcon><WebIcon/></ListItemIcon>
                            <ListItemText primary="Views"/>
                        </ListItem>
                        <ListItem {...itemAttr} key="Graphs" to="/graphs">
                            <ListItemIcon><ShowChartIcon/></ListItemIcon>
                            <ListItemText primary="Graphs"/>
                        </ListItem>
                        <ListItem {...itemAttr} key="Schedules" to="/schedules">
                            <ListItemIcon><AvTimerIcon/></ListItemIcon>
                            <ListItemText primary="Schedules"/>
                        </ListItem>
                        <ListItem {...itemAttr} key="Datapoints" to="/datapoints">
                            <ListItemIcon><CategoryIcon/></ListItemIcon>
                            <ListItemText primary="Datapoints"/>
                        </ListItem>
                    </List>

                    {tenantList.length > 1 &&
                    <List>
                        <Divider/>
                        <ListSubheader>
                            Tenants
                        </ListSubheader>
                        <Divider/>
                        {tenantList}
                    </List>
                    }
                </SwipeableDrawer>
            </div>
        );
    }

}

export default withStyles(styles)(MenuDrawer);