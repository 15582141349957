import React from "react";
import {Box, FormHelperText, MenuItem, Switch, TextField, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 130,
    },
    booleanFix: {
        marginTop: "10px",
    }
});


class DatapointEditorField extends React.Component {

    composeNumericField(d, value, onChange) {
        let inputProps = d.getNumericEditorInputProps();

        // Apply inputAttrs if they are available
        return <TextField
            type="number"
            helperText={d.name}
            onChange={onChange}
            value={value}
            inputProps={inputProps}
            readOnly={this.props.disabled}
            variant="outlined"
        />
    }

    composeEnumField(d, value, onChange) {
        let values = d.getEnumMap();
        if (Object.keys(values).length === 0) return this.composeStringField(d, value, onChange);
        return (<TextField
                select
                value={value}
                onChange={onChange}
                helperText={d.name}
                className={this.props.classes.textField}
                readOnly={this.props.disabled}
                variant="outlined"
            >
                {values.map((v) => <MenuItem value={v.value}>{v.display}</MenuItem>)}
            </TextField>
        )
    }

    composeStringField(d, value, onChange) {
        return <TextField
            label={d.name}
            onChange={onChange}
            value={value}
            readOnly={this.props.disabled}
            variant="outlined"
        />
    }

    composeBooleanField(d, value, onChange) {

        if (!d.facets) d.facets = {};
        let trueLabel = d.facets.trueText || "On";
        let falseLabel = d.facets.falseText || "Off";

        if (value === true || value === "true" || value === 1) {
            value = true;
        } else {
            value = false;
        }

        return (<Box className={this.props.classes.booleanFix} component='div'>
            {falseLabel}
            <Switch
                size="small"
                checked={value}
                onChange={onChange}
                value={value}
                readOnly={this.props.disabled}
            />
            {trueLabel}
            <FormHelperText>{d.name}</FormHelperText>
        </Box>)
    }

    render() {
        switch (this.props.datapoint.type) {
            case "numeric":
                return this.composeNumericField(this.props.datapoint, this.props.value, (e) => {
                    this.props.onChange(e.target.value)
                });
            case "enum":
                return this.composeEnumField(this.props.datapoint, this.props.value, (e) => {
                    this.props.onChange(e.target.value)
                });
            case "string":
                return this.composeStringField(this.props.datapoint, this.props.value, (e) => {
                    this.props.onChange(e.target.value)
                });
            case "boolean":
                return this.composeBooleanField(this.props.datapoint, this.props.value, (e) => {
                    this.props.onChange(e.target.checked)
                });
            default:
                throw new Error("Unknown datapoint type")
        }
    }

}

export default withStyles(styles)(DatapointEditorField);